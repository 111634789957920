
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';
//Libraries
import { defineComponent, PropType } from 'vue';
import Fuse from 'fuse.js';
import { isNullish, isMundaneObject } from '../../utilities';

//Types
import { JsonObject } from '@/types/JsonSpec';
import { Service } from '@/types/Services';

export default defineComponent({
  name: 'SearchList',
  components: {
    LoadingSpinner
  },
  props: {
    containerHeight: {
      type: Number,
      default: 300
    },

    hoverResults: {
      type: Boolean,
      default: false
    },

    placeholder: {
      type: String,
      default: 'type your search term'
    },

    searchData: {
      type: Array as PropType<(JsonObject | string)[]>,
      default: () => []
    },

    defaultData: {
      type: Array as PropType<(JsonObject | string)[]>,
      default: () => []
    },

    defaultDataLabel: {
      type: String,
      default: ''
    },

    searchKeys: {
      type: Array as PropType<string[]>,
      default: () => ['name', 'id']
    },

    fuseSearchOptions: {
      type: Object,
      default: () => ({
        threshold: 0.2,
        tokenize: true,
        maxPatternLength: 64
      })
    },

    showInitialData: {
      type: Boolean,
      default: true
    },

    showIcon: {
      type: Boolean,
      default: true
    },

    searchType: {
      type: String,
      default: ''
    },

    defaultDataHover: {
      type: Boolean,
      default: false
    },

    isProgramEligible: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchResults: [] as any[],
      searchTerm: '',
      selectedResult: false,
      showDefaultData: this.showInitialData,
      onDefaultData: false,
      showItems: true
    };
  },

  computed: {
    isLyftUser(): boolean {
      return this.$store.getters['user/isLyftUser'];
    },

    searchDataLocal(): JsonObject[] {
      return this.searchData.map((data) => {
        return isMundaneObject(data)
          ? data
          : {
              id: 0,
              name: data
            };
      });
    },

    searchDefaultData(): JsonObject[] {
      return this.defaultData.map((data) => {
        return isMundaneObject(data)
          ? data
          : {
              id: 0,
              name: data
            };
      });
    },

    serviceSearchResults(): Service[] {
      return this.searchResults.filter((service) => service.note !== 'Lyft Driver Rewards program service');
    },

    packageSearchResults(): Service[] {
      return this.searchResults.filter((service) => service.note === 'Lyft Driver Rewards program service');
    },

    fuse(): Fuse<JsonObject, Fuse.FuseOptions<any>> {
      let options: Fuse.FuseOptions<any> = {
        ...this.fuseSearchOptions,
        keys: this.searchKeys,
        minMatchCharLength: 2,
        threshold: 0.3
      };

      return new Fuse(this.searchDataLocal, options);
    },

    showData(): boolean {
      return this.searchTerm.length >= 2;
    }
  },

  watch: {
    defaultDataHover(newValue) {
      if (newValue && this.onDefaultData) {
        this.onDefaultData = true;
      } else {
        this.onDefaultData = false;
      }
    }
  },

  methods: {
    handleActiveState() {
      if (!this.onDefaultData && !this.defaultDataHover) {
        this.showDefaultData = false;
      }
      this.showItems = !this.selectedResult;
      if (this.searchTerm === '') {
        this.$emit('is-active', false);
        this.$emit('clear-selected-service');
        this.selectedResult = false;
      }
    },

    performSearch(): any {
      //TODO Debounce this, probably use underscore
      if (this.searchTerm.length >= 2) {
        this.showDefaultData = false;
        this.showItems = !this.selectedResult;
        this.$emit('is-active', true);
        this.searchResults = this.fuse.search(this.searchTerm);
      } else {
        this.showDefaultData = true;
        this.selectedResult = false;
        this.$emit('is-active', false);
        this.$emit('clear-selected-service');
      }
    },

    highlightTerm(item: JsonObject): string {
      let term = !isNullish(item.name) ? item.name.toString() : item.title;
      const searchTerms = this.searchTerm
        .split(/\s+/)
        .map((item) => item.replace(/\W/, ''))
        .filter((item) => !!item);

      const toReplace = new RegExp(searchTerms.join('|'), 'ig');
      const result = searchTerms.length > 0 ? term.replace(toReplace, `<strong>$&</strong>`) : term;
      return result;
    },

    selectTopResult() {
      if ((this.$refs.serviceSearchResults as HTMLElement[])[0])
        (this.$refs.serviceSearchResults as HTMLElement[])[0].click();
    },

    selectItem(item: JsonObject) {
      if (item.id === 0) {
        item = item.name;
      }

      this.$emit('item-selected', item);
      this.$emit('is-active', false);
      this.blurSearchMenu();
      this.searchTerm = '';
    },

    selectDefaultData(event: Event, item: JsonObject) {
      if (item.id === 0) {
        item = item.name;
        this.$emit('item-selected', item);
      }

      //TODO: There is a better way.
      if (this.searchType === 'engine') {
        item = item.name;
        this.$emit('item-selected', item);
      } else if (this.searchType === 'trim') {
        this.$emit('trim-selected', item);
      } else {
        this.$emit('item-selected', item);
      }

      this.blurSearchMenu();
      this.showDefaultData = false;
    },

    formatSearchResult(item: JsonObject) {
      return item.categoryName ? `${item.categoryName}` : '';
    },

    handleDefaultData() {
      if (this.searchTerm.length < 2 && !this.showDefaultData) {
        this.showDefaultData = true;
      }
    },

    blurSearchMenu(): void {
      this.$nextTick(() => {
        if (this.$refs['SearchListInput'] !== undefined) {
          (this.$refs.SearchListInput as HTMLElement).focus();
          (this.$refs.SearchListInput as HTMLElement).blur();
        }
      }).catch((() => null));
    }
  }
});
