
import { defineComponent } from 'vue';
import { monetize, ordinalize, capitalize } from '@/utilities';
import ConfirmationDetailsBox from './ConfirmationDetailsBox.vue';
import ConfirmationDetailRow from './ConfirmationDetailRow.vue';
import BaseAddressInputs from '@/components/Shared/BaseAddressInputs.vue';
import LocationHours from '@/components/Onramp/Location/Hours.vue';
import AlertPane from '@/components/Shared/AlertPane.vue';

//Types
import { OfferFull } from '@/types/Offer';
import { OwnedVehicle } from '@/types/resources/OwnedVehicles';
import { ServiceRequest, AppointmentSlot, ServiceAddress } from '@/types/ServiceRequest';
import { User } from '@/types/User';

export default defineComponent({
  name: 'ConfirmationStep',

  components: {
    ConfirmationDetailsBox,
    ConfirmationDetailRow,
    BaseAddressInputs,
    LocationHours,
    AlertPane,
  },

  data() {
    return {
      loading: false,
      alert: {
        type: '',
        message: ''
      }
    };
  },

  computed: {
    appointmentSlots(): AppointmentSlot[] | null {
      return this.$store.getters['onramp/getAppointmentSlot'];
    },

    isMobileMechanic(): boolean {
      return this.offer.location.doesHouseCalls;
    },

    offer(): OfferFull {
      return this.$store.getters['onramp/getOffer'] || {};
    },

    serviceRequest(): ServiceRequest {
      return this.$store.getters['onramp/getServiceRequest'];
    },

    user(): User {
      return this.$store.getters['user/getUserProfile'];
    },

    serviceNames(): string[] {
      return this.offer.services.map(({ name }) => name);
    },

    selectedVehicle(): OwnedVehicle {
      return this.$store.getters['onramp/getOwnedVehicle'];
    },

    vehicleInfo(): string {
      const { year, make, model } = this.selectedVehicle;
      return `${year} ${make} ${model}`;
    },

    serviceAddress(): ServiceAddress {
      return this.$store.getters['onramp/getServiceAddress'];
    },

    hasSubscription(): boolean {
      return this.$store.getters['user/hasActiveSubscription'];
    },

    serviceAddressValid(): boolean {
      if (!this.isMobileMechanic) return true;

      const zipcode = this.serviceAddress.addressZipcode || '';
      const line1 = (this.serviceAddress.addressLine1 || '').trim();
      const city = (this.serviceAddress.addressCity || '').trim();
      return !!(zipcode.match(/[0-9]{5}/g) && line1 && city);
    },
  },

  methods: {
    monetize,

    ordinalize,

    capitalize,

    showOfferDetailModal(): void {
      this.$store.dispatch('ui/showDialog', {
        title: `Offer Details`,
        contentComponent: {
          name: 'OfferDetails',
          props: {
            offer: this.offer,
            showHeaders: false,
            hideServices: true,
          }
        },
        cancelText: 'Close',
        hideSubmitButton: true,
      });
    },

    updateServiceAddress(address: ServiceAddress) {
      this.$store.commit('onramp/setServiceAddress', address);
    },

    acceptOffer(): void {
      this.loading = true;

      const appointmentTime = (this.appointmentSlots || []).map(({ key }) => key).toString();
      const address = this.isMobileMechanic ? this.serviceAddress : null;
      const { serviceRequest, offer } = this;

      const payload = {
        offer,
        cardId: null,
        address,
        appointmentTime,
        serviceRequest
      };

      // const nextRoute = `/service-request/${this.$route.params.id}/offer/${this.$route.params.offerId}/confirmation`;

      this.$store
        .dispatch('onramp/acceptOffer', payload)
        .then((response: any) => {
          this.$store.commit('onramp/setOffer', response.acceptedOffer);
          this.$store.dispatch('ui/pushView', {
            name: 'AppointmentConfirmed'
          })
        })
        .catch((error: any) => {
          this.alert.message = error;
          this.alert.type = 'danger';
          this.loading = false;
        });
    },

    goBack(): void {
      this.$store.dispatch('ui/popView');
    },

    returnToEstimates(): void {
      this.$store.dispatch('ui/pushView', {
        name: 'OfferSelector',
        params: {
          serviceRequestUid: this.serviceRequest.openbayId
        }
      });
    },
  }
});
