
import { defineComponent, PropType } from 'vue';
import configMixin from '@/mixins/configMixin';

// Components
import DetailsLineItem from './DetailsLineItem.vue';

// Types
import { OfferFull } from '@/types/Offer';
import { Service } from '@/types/Services';
import { monetize, percentize } from '@/utilities';
import { Subscription } from '@/types/microservices/Subscriptions';
import { WidgetType } from '@/types/PartnerConfig';

export default defineComponent({
  name: 'OfferDetails',

  mixins: [configMixin],

  components: {
    DetailsLineItem
  },

  props: {
    offer: {
      required: true,
      type: Object as PropType<OfferFull>
    },

    services: {
      required: true,
      type: Array as PropType<Service[]>
    },

    showForInvoice: {
      type: Boolean,
      default: false
    },

    showHeaders: {
      type: Boolean,
      default: true
    },

    hideServices: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    detailsTitle(): string {
      return this.showForInvoice ? 'Itemized Invoice' : 'Itemized Estimate';
    },

    discountsApplied(): number {
      return this.offer.totalDiscountCents || 0;
    },

    subscriptions(): Subscription[] {
      return this.$store.getters['onramp/getSubscriptions'];
    },

    hasSubscription(): boolean {
      if (
        this.showForInvoice &&
        this.offer.totalDiscountsApplied &&
        Object.keys(this.offer.totalDiscountsApplied).includes('openbayPlusDiscount')
      )
        return true;

      if (this.widgetType === WidgetType.Admin)
        return this.subscriptions.length > 0;

      return this.$store.getters['user/hasActiveSubscription'];
    },

    isLyftUser(): boolean {
      return this.$store.getters['user/isLyftUser'];
    },

    hasLyftDiscount(): boolean {
      return this.offer.voLyftDiscount > 0;
    },

    priceTotal(): string {
      if (!this.hasSubscription && !this.hasLyftDiscount)
        return monetize(this.offer.totalBeforeTaxWithPlatformDiscounts.cents, 'cents');

      return monetize(this.offer.totalAfterRewards, 'dollars');
    },

    subTotal(): number {
      return parseFloat(this.offer.totalBeforeTaxWithPlatformDiscounts.cents);
    },

    subTotalLabel(): string {
      return this.discountsApplied && this.hasSubscription ? 'List Price' : 'Subtotal';
    },

    taxes(): string {
      return this.offer.taxAlreadyCalculated ? monetize(this.totalTax, 'cents') : 'Calculated when booking';
    },

    totalTax(): number {
      return typeof this.offer.totalTaxPrice === 'number'
        ? this.offer.totalTaxPrice
        : parseFloat(this.offer.totalTaxPrice.cents);
    },

    displayRewards(): boolean {
      return this.offer.accepted === true ? this.offer.rewardsCents > 0 : this.offer.maxRewards > 0;
    },

    rewards(): string {
      return this.offer.accepted === true
        ? monetize(this.offer.rewardsCents, 'cents')
        : monetize(this.offer.maxRewards, 'dollars');
    }
  },
  methods: {
    monetize: monetize,
    percentize: percentize
  }
});
