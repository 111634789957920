import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f2f540c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-dialog" }
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = { class: "modal-title" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    role: "dialog",
    class: _normalizeClass(_ctx.modalClasses)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("header", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.dialog.title), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.dialog.contentComponent)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.dialog.contentComponent.name), _normalizeProps(_mergeProps({ key: 0 }, _ctx.dialog.contentComponent.props)), null, 16))
          : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.dialog.contentText), 1))
      ]),
      _createElementVNode("footer", _hoisted_6, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-tertiary",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }, _toDisplayString(_ctx.cancelText), 1),
        (!_ctx.dialog.hideSubmitButton)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: "btn btn-primary",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.ok && _ctx.ok(...args)))
            }, _toDisplayString(_ctx.submitText), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}