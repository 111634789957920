import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b041f388"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row popular-services" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "answer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", null, [
    _withDirectives(_createElementVNode("h2", null, "Popular Services", 512), [
      [_vShow, _ctx.showTitle]
    ]),
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedServices, (interview, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: `service-${index}`,
          class: "col-md-6"
        }, [
          _createElementVNode("button", {
            class: "btn",
            onClick: ($event: any) => (_ctx.selectServiceCategory(interview))
          }, [
            (interview.icon !== '')
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.asset(require(`@/assets/icons/ic-${interview.icon}.svg`)),
                  height: "32",
                  width: "32"
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true),
            _createElementVNode("span", _hoisted_4, _toDisplayString(interview.answer), 1)
          ], 8, _hoisted_2)
        ]))
      }), 128))
    ])
  ]))
}