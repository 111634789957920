import ActionCableVue from 'actioncable-vue';
import Vue, { createApp } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import App from './App.vue';
import { WIDGET_ID } from './constants';
import { setup } from './init/setup';
import store from './store/store';

await setup();

const actionCableVueOptions = {
  debug: true,
  debugLevel: 'error',
  connectionUrl: process.env?.VUE_APP_CABLE_CLIENT_URL || '/openbay-mini/cable',
  connectImmediately: true
};

const app = createApp(App).use(store).use(ActionCableVue, actionCableVueOptions).component('icon', FontAwesomeIcon);

app.mount(`#${WIDGET_ID}`);
