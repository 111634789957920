
import { defineComponent } from 'vue';
import LocationSearch from '@/components/Appointment/LocationSearch.vue';
import { Area } from '@/types/resources/ZipcodeLocations';
import configMixin from '@/mixins/configMixin';
import { WidgetStrings } from '@/types/PartnerConfig';

export default defineComponent({
  name: 'FindRepairFacility',

  components: {
    LocationSearch
  },

  mixins: [
    configMixin,
  ],

  computed: {
    baseUrl(): string {
      return this.$store.getters['config/getBaseUrl'];
    },

    frontendVoUrl(): string {
      // FIXME: Do we have a better way of handling this at runtime?
      if (this.baseUrl.includes('services-sandbox.openbay.com')) return 'https://app-sandbox.openbay.com';
      if (this.baseUrl.includes('services-staging.openbay.com')) return 'https://app-staging.openbay.com';
      if (this.baseUrl.includes('services.openbay.com')) return 'https://app.openbay.com';
      return 'http://127.0.0.1:8080';
    },

    strings(): WidgetStrings['findRepairFacility']{
      const { strings } = this.externalConfig;
      return strings.findRepairFacility
    },

    partner(): string {
      return this.externalConfig.partner || '';
    },
  },


  methods: {
    getAutoRepairUrl(area: Area): string {
      return `${this.frontendVoUrl}/auto-repair/${area.state}/${area.city}?partner=${this.partner}`;
    },

    onAreaSelected(area: Area): void {
      window.open(this.getAutoRepairUrl(area), '_blank')
    },
  }
});
