import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "offer pt-3 pb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OfferPricing = _resolveComponent("OfferPricing")!
  const _component_LocationPartial = _resolveComponent("LocationPartial")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_LocationPartial, {
      location: _ctx.offer.location,
      "distance-from": _ctx.offer.distance,
      onLocationClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showQuickView()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_OfferPricing, {
          offer: _ctx.offer,
          class: "pb-3"
        }, null, 8, ["offer"])
      ]),
      _: 1
    }, 8, ["location", "distance-from"]),
    _createElementVNode("div", {
      class: _normalizeClass(['actions', !_ctx.hasSubscription ? 'static' : ''])
    }, [
      _createElementVNode("button", {
        class: "btn btn-tertiary mr-2",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showQuickView()))
      }, "View details"),
      (!_ctx.isAdminFlow)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn btn-primary",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.chooseTime()))
          }, "Book appointment"))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}