
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OfferPlaceholder',

  props: {
    amount: {
      type: Number,
      required: true,
      default: 3
    }
  }
});
