
import { defineComponent, PropType } from 'vue';
import { monetize } from '@/utilities';
import { OfferFull } from '@/types/Offer';
import { Subscription } from '@/types/microservices/Subscriptions';
import configMixin from '@/mixins/configMixin';
import { WidgetType } from '@/types/PartnerConfig';

export default defineComponent({
  name: 'OfferPricing',

  mixins: [configMixin],

  props: {
    offer: {
      type: Object as PropType<OfferFull>,
      required: true
    }
  },

  computed: {
    hasLyftDiscount(): boolean {
      return this.offer.voLyftDiscount > 0;
    },

    subscriptions(): Subscription[] {
      return this.$store.getters['onramp/getSubscriptions'];
    },

    hasSubscription(): boolean {
      if (this.widgetType === WidgetType.Admin)
        return this.subscriptions.length > 0;
      
      return this.$store.getters['user/hasActiveSubscription'];
    },

    isLyftUser(): boolean {
      return this.$store.getters['user/isLyftUser'];
    },

    listPrice(): string {
      return monetize(this.offer.totalBeforeTaxWithPlatformDiscounts.cents, 'cents');
    },

    discountsApplied(): number {
      return this.offer.totalDiscountCents || 0;
    },

    discountsAppliedFormatted(): string {
      const discounts = this.discountsApplied;
      return monetize(discounts, 'cents');
    },

    priceTotal(): string {
      return monetize(this.offer.totalAfterRewards, 'dollars');
    }
  }
});
