
import { defineComponent } from 'vue';
import userService from '@/services/userService';

import { messageFromError } from '@/services/helpers';
import { BetaContact, BetaContactProduct } from '@/types/resources/BetaContact';
import { User } from '@/types/User';
import { Subscription } from '@/types/microservices/Subscriptions';
import assetMixin from '@/mixins/assetMixin';

export default defineComponent({
  name: 'NoOffersAvailable',

  mixins: [assetMixin],

  props: {
    zipcode: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      email: '',
      fullName: ''
    };
  },

  computed: {
    userProfile(): User {
      return this.$store.getters['user/getUserProfile'];
    },

    isOpenbayPlusUser(): boolean {
      return this.$store.getters['user/getSubscriptions'].length > 0;
    },

    displayName(): string {
      return this.$store.getters['user/getDisplayName'];
    },

    storeEmail(): string {
      return this.$store.getters['user/getEmail'];
    },

    firstName(): string {
      if (this.displayName === '')
        // Yeah, first names can be multi-word, and making assumptions like this
        // isn't good, but we store it as first_name/last_name in the database
        // so it'll have to work for now. Better than how it was before, where
        // the entire name was stored in first_name.
        return this.fullName.trim().split(/\s+/g)[0] || '';

      return this.userProfile.firstName;
    },

    lastName(): string {
      if (this.displayName === '') {
        // Last name will consist of all but the first word given. Better than
        // how it was before, where the entire name was stored in first_name.
        const words = this.fullName.trim().split(/\s+/g);
        return words.length > 1 ? words.slice(1).join(' ') : '';
      }

      return this.userProfile.lastName;
    },

    activeSubscription(): Subscription | undefined {
      return this.$store.getters['user/getSubscriptions'][0];
    },

    activeSubscriptionProgramPlanId(): number | null {
      return this.activeSubscription?.programPlanId ?? null;
    }
  },

  methods: {
    createBetaContact(): void {
      const email = this.storeEmail;

      let product: BetaContactProduct = 'Openbay OTM';
      let programPlanId = null;
      if (this.isOpenbayPlusUser) {
        product = 'Openbay Plus';
        programPlanId = this.activeSubscriptionProgramPlanId;
      }

      const betaContact: BetaContact = {
        zipcode: this.zipcode,
        email: email,
        firstName: this.firstName,
        lastName: this.lastName,
        product,
        programPlanId
      };

      userService
        .updateBetaContacts(betaContact)
        .then(() => {
          const message = `We'll notify you at ${email} when shops in your area join Openbay.`;

          this.$store.dispatch('alerts/addAlert', {
            message,
            type: 'success',
            timedDismiss: true
          });

          this.$store.dispatch('ui/pushView', {
            name: 'ChooseLocation'
          });
        })
        .catch((error) => {
          const errorMessage = messageFromError(error);
          const message =
            errorMessage === 'Email has already been taken' ? `You've already signed up to be notified.` : errorMessage;

          this.$store.dispatch('alerts/addAlert', {
            message,
            type: 'danger',
            timedDismiss: true
          });
        });
    },

    updateName(name: string): void {
      this.fullName = name;
    },

    updateEmail(email: string): void {
      this.email = email;
    }
  }
});
