
import { defineComponent } from 'vue';
import moment, { Moment } from 'moment';

//components
import LocationPartial from '@/components/Onramp/Location/Partial.vue';
import LocationHours from '@/components/Onramp/Location/Hours.vue';
import Spinner from '@/components/Shared/LoadingSpinner.vue';
import SingleTimeSelector from '@/components/Onramp/Appointment/SingleTimeSelector.vue';
import MultiTimeSelector from '@/components/Onramp/Appointment/MultiTimeSelector.vue';

// Services
import serviceRequestService from '@/services/serviceRequestService';

//Types
import { LocationFull } from '@/types/ServiceProvider';
import { isNullish } from '@/utilities';
import { OfferFull } from '@/types/Offer';
import offerService from '@/services/offerService';
import { AppointmentSlot } from '@/types/ServiceRequest';
import appointmentService from '@/services/appointmentService';
import { View } from '@/types/store/View';

import 'swiper/css'

export default defineComponent({
  name: 'AppointmentSelector',

  components: {
    LocationPartial,
    LocationHours,
    Spinner,
    SingleTimeSelector,
    MultiTimeSelector,
  },

  data() {
    return {
      error: '',
      loading: true,
      days: [] as Moment[],
      locationHours: {},
      slots: null as AppointmentSlot[] | null,
      swiperOption: {
        slidesPerView: 4,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    };
  },

  computed: {
    isAppointmentOnlyFlow(): boolean {
      return false;
    },

    currentView(): View {
      return this.$store.getters['ui/getCurrentView'];
    },

    location(): Partial<LocationFull> {
      return this.offer.location;
    },

    offer(): OfferFull {
      return this.$store.getters['onramp/getOffer'];
    },

    serviceRequestId(): number {
      return this.offer.serviceRequestId;
    },

    totalRepairTimeHours(): string {
      return !isNullish(this.offer) ? this.offer.totalRepairTimeHours : '';
    },
  },

  mounted(): void {
    this.clearChoices();
    if (this.isAppointmentOnlyFlow) {
      this.createScheduleForAppointment();
    } else {
      this.fetchOfferWithTaxes();
    }
  },

  methods: {
    clearChoices(): void {
      this.$emit('time-set', []);
    },

    createScheduleForSR(): void {
      serviceRequestService
        .fetchAppointmentSlots(this.serviceRequestId, this.location.id!)
        .then(this.slotsLoaded)
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    createScheduleForAppointment(): void {
      appointmentService
        .fetchAppointmentSlots(this.location.id!)
        .then(this.slotsLoaded)
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    slotsLoaded(response: AppointmentSlot[]) {
      this.slots = response;
      const days = [...new Set(response.map((item: AppointmentSlot) => item.day))];
      days.forEach((day: any) => {
        let daySlot = moment(day, 'YYYY-MM-DD');
        this.days.push(daySlot);
      });
    },

    fetchOfferWithTaxes(): void {
      offerService
        .updateOfferTaxes(this.currentView.params.offerId)
        .then((offer) => {
          this.$store.commit('onramp/setOffer', offer);
          this.createScheduleForSR();
        })
        .catch((error) => {
          this.error = error;
          this.loading = false;
        });
    },

    timeSet(slots: AppointmentSlot[]): void {
      this.$store.commit('onramp/setAppointmentSlot', slots);
    },

    appointmentChosen(): void {
      this.$store.dispatch('ui/pushView', {
        name: 'Confirmation'
      })
    },
  }
});
