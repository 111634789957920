
import { defineComponent } from 'vue';

// Services
import vehicleService from '@/services/vehicleService';
import userService from '@/services/userService';

// Components
import SearchList from '@/components/Shared/SearchList.vue';

//Types
import { AcesResponse, VehicleRequest } from '@/types/Vehicle';
import { messageFromError } from '@/services/helpers';
import { View } from '@/types/store/View';
import { JsonObject } from '@/types/JsonSpec';

type Step = {
  type: 'year' | 'make' | 'model' | 'subModel' | 'trim';
  title: string;
  placeholder: string;
  index: number;
};

const STEPS = [
  {
    type: 'year',
    title: 'Select vehicle year',
    placeholder: `Enter your vehicle's model year`,
    index: 0
  },
  {
    type: 'make',
    title: 'Select make',
    placeholder: 'Enter vehicle make',
    index: 1
  },
  {
    type: 'model',
    title: 'Select model',
    placeholder: 'Enter vehicle model',
    index: 2
  },
  {
    type: 'subModel',
    title: 'Select sub model',
    placeholder: 'Enter vehicle sub model',
    index: 3
  },
  {
    type: 'trim',
    title: 'Select trim or style',
    placeholder: 'Enter vehicle trim',
    index: 4
  },
  {
    type: 'mileage',
    title: 'Enter your vehicle mileage',
    placeholder: 'Enter the approximate vehicle mileage',
    index: 5
  }
] as Step[];

export default defineComponent({
  name: 'AddOwnedVehicle',

  components: {
    SearchList,
  },

  props: {
    zipcode: {
      type: String,
      required: false,
      default: ''
    },
  },

  data() {
    return {
      error: '',
      loading: false,
      vehicle: {
        year: { id: 0, name: '' },
        make: { id: 0, name: '' },
        model: { id: 0, name: '' },
        subModel: { id: 0, name: '' },
        trim: '',
        mileage: 0
      },
      vehicleRequest: {
        vehicleId: 0,
        zipcode: '',
        mileage: 0,
        notes: '',
        vin: '',
        licensePlate: ''
      } as VehicleRequest,
      searchData: [''] as any[],
      shouldRequestMileage: false,
      currentStep: STEPS[0]
    };
  },

  computed: {
    vehicleQuestion(): string {
      if (this.currentStep.type == 'year') {
        return 'What year is your vehicle?';
      } else if (this.currentStep.type == 'make') {
        return 'What make is your vehicle?';
      } else if (this.currentStep.type == 'model') {
        return `What model is your ${this.vehicle.year.name} ${this.vehicle.make.name} ?`;
      } else {
        return `What ${this.currentStep.type} is your ${this.vehicle.year.name} ${this.vehicle.make.name} ${this.vehicle.model.name}?`;
      }
    },

    currentView(): View {
      return this.$store.getters['ui/getCurrentView'];
    },
  },

  watch: {
    currentView(next): void {
      this.dispatchNextStep(next.params.type);
    }
  },

  mounted(): void {
    this.dispatchNextStep(this.currentView.params.type);
    this.vehicleRequest.zipcode = this.zipcode;
  },

  methods: {
    handleStepError(error: string): void {
      this.error = error;
      this.loading = false;
      this.$store.dispatch('ui/popView');
    },

    itemSelected(item: AcesResponse): void {
      const nextStep = STEPS[this.currentStep.index + 1];
      this.updateVehicle(item);
      this.$store.dispatch('ui/pushView', {
        name: 'VehicleSelector',
        params: {
          type: nextStep.type
        },
      });
    },

    trimSelected(trim: JsonObject): void {
      this.updateVehicle(trim.name);
      this.vehicleRequest.vehicleId = parseInt(trim.id);
      this.submitVehicle();
    },

    updateVehicle(item: AcesResponse): void {
      (this.vehicle[this.currentStep.type] as AcesResponse) = item;
      this.$emit('vehicle-updated', this.vehicle);
      this.$emit('vehicle-request-updated', this.vehicleRequest);
    },

    initSubModelStep(): void {
      this.loading = true;
      this.vehicle.subModel = { id: 0, name: '' };

      vehicleService
        .fetchSubModels(this.vehicle.year.id, this.vehicle.make.id, this.vehicle.model.id)
        .then((subModels: any[]) => {
          this.currentStep = STEPS[3];
          subModels.length === 1 ? this.handleSingleSearchResult(subModels, false) : (this.searchData = subModels);
        })
        .catch((error: any) => this.handleStepError(error))
        .finally(() => (this.loading = false));
    },

    initModelStep(): void {
      this.loading = true;
      this.vehicle.trim = '';
      this.vehicle.subModel = { id: 0, name: '' };
      this.vehicle.model = { id: 0, name: '' };

      vehicleService
        .fetchModels(this.vehicle.year.id, this.vehicle.make.id)
        .then((models: any[]) => {
          this.searchData = models;
          this.currentStep = STEPS[2];
        })
        .catch((error: any) => this.handleStepError(error))
        .finally(() => (this.loading = false));
    },

    initTrimStep(): void {
      this.loading = true;
      this.vehicle.trim = '';
      vehicleService
        .fetchTrims(this.vehicle.year.id, this.vehicle.make.id, this.vehicle.model.id, this.vehicle.subModel.id)
        .then((trims: any[]) => {
          if (trims.length === 1) {
            this.handleSingleSearchResult(trims, true);
          } else {
            this.searchData = trims;
            this.currentStep = STEPS[4];
          }
        })
        .catch((error: any) => this.handleStepError(error))
        .finally(() => (this.loading = false));
    },

    initMakeStep(): void {
      this.loading = true;
      this.vehicle.make = { id: 0, name: '' };
      this.vehicle.model = { id: 0, name: '' };
      this.vehicle.subModel = { id: 0, name: '' };
      this.vehicle.trim = '';

      vehicleService
        .fetchMakes(this.vehicle.year.id)
        .then((makes: any[]) => {
          this.searchData = makes;
          this.currentStep = STEPS[1];
        })
        .catch((error: any) => this.handleStepError(error))
        .finally(() => (this.loading = false));
    },

    initYearsStep(): void {
      this.loading = true;
      this.resetVehicleCreation();

      vehicleService
        .fetchYears()
        .then((years: any[]) => {
          this.searchData = years;
          this.currentStep = STEPS[0];
        })
        .catch((error: any) => (this.error = error))
        .finally(() => (this.loading = false));
    },

    mileageUpdated(mileage: number) {
      this.vehicleRequest.mileage = mileage;
    },

    submitVehicle(): void {
      this.setVehicleToCreate();
    },

    handleSingleSearchResult(results: any[], forTrim: boolean): void {
      forTrim === true ? this.trimSelected(results[0]) : this.itemSelected(results[0]);

      this.searchData = [];
    },

    setVehicleToCreate(): void {
      const vehicleToCreate = {
        year: this.vehicle.year.name,
        make: this.vehicle.make.name,
        model: this.vehicle.model.name,
        subModel: this.vehicle.subModel.name,
        edmundsStyleId: this.vehicleRequest.vehicleId,
        mileage: this.vehicleRequest.mileage
      };
      this.$store.commit(`onramp/setVehicleToCreate`, vehicleToCreate);
      this.$emit('vehicle-added', vehicleToCreate);
    },

    createOwnedVehicle(): Promise<void> {
      return userService
        .addOwnedVehicle(this.vehicleRequest)
        .then((vehicle) => {
          this.$emit('vehicle-updated', vehicle);
          this.$store.commit('user/addOwnedVehicle', vehicle);
          this.$store.commit(`onramp/clearVehicleToCreate`);
          this.$emit('vehicle-added', vehicle);
        })
        .catch((error) => {
          this.$emit('vehicle-creation-failed');
          const message = messageFromError(error);
          return this.$store
            .dispatch('alerts/addAlert', {
              message,
              type: 'danger',
              timedDismiss: true
            })
            .then(() => this.resetVehicleCreation());
        });
    },

    resetVehicleCreation(): void {
      this.$emit('vehicle-updated', {
        year: '',
        make: '',
        model: '',
        engine: '',
        trim: '',
        mileage: 0
      });
    },

    dispatchNextStep(type: Step['type']): void {
      switch (type) {
        case 'trim':
          this.initTrimStep();
          break;
        case 'subModel':
          this.initSubModelStep();
          break;

        case 'model':
          this.initModelStep();
          break;

        case 'make':
          this.initMakeStep();
          break;
        default:
          this.initYearsStep();
          break;
      }
    },
  }
});
