
import { defineComponent } from 'vue';
import emailInput from '@/components/Shared/EmailInput.vue';
import nameInput from '@/components/Shared/RequiredInput.vue';
import userService from '../../services/userService';
import { Alert } from '@/types/store/Alert';
import { BetaContact, BetaContactProduct } from '@/types/resources/BetaContact';
import assetMixin from '@/mixins/assetMixin';
import configMixin from '@/mixins/configMixin';
import { WidgetType } from '@/types/PartnerConfig';

export default defineComponent({
  name: 'ZeroCoverage',

  mixins: [assetMixin, configMixin],

  components: {
    emailInput,
    nameInput,
  },

  data() {
    return {
      email: '',
      firstName: '',
      lastName: '',
      loading: false,
      canSubmit: false,
      success: false,
      emailValid: false
    };
  },

  computed: {
    isLoggedIn(): boolean {
      return this.$store.getters['user/isLoggedIn'];
    },

    openbayBranding(): string {
      return 'Openbay';
    },

    cancelText(): string {
      return 'Cancel';
    },

    isAdminFlow(): boolean {
      return this.widgetType === WidgetType.Admin;
    },

    copy(): string {
      return this.isAdminFlow 
        ? `Sorry, no shops are currently eligible for quoting this location.`
        : `Openbay isn't available in your location yet. Share your info to get notified when nearby
          shops join Openbay.`;
    }
  },

  methods: {
    updateEmail(newEmail: string): void {
      this.email = newEmail;
    },

    updateEmailValidity(isValid: boolean): void {
      this.emailValid = isValid;
    },

    cancel(): void {
      this.$store.dispatch('ui/popView');
    },

    updateFirstName(name: string): void {
      this.firstName = name;
    },

    updateLastName(name: string): void {
      this.lastName = name;
    },

    sendToBetaContacts(): void {
      this.loading = true;

      let product: BetaContactProduct = 'Openbay OTM';
      let programPlanId = null;

      const betaContact: BetaContact = {
        zipcode: this.$store.getters['onramp/getZipcode'],
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        product,
        programPlanId
      };

      userService
        .updateBetaContacts(betaContact)
        .then(() => {
          const alertMessage = `We will notify you at ${this.email} when nearby shops join Openbay.`;
          this.showAlert(alertMessage, 'success');
        })
        .catch((error) => {
          const alertMessage =
            error === 'Email has already been taken'
              ? 'You’ve already requested Openbay service coverage notifications.'
              : 'Hmm, something went wrong. Sorry about that.';

          this.showAlert(alertMessage, 'warning');
        })
        .finally(() => {
          this.loading = false;
          this.$store.dispatch('ui/popView');
        });
    },

    showAlert(message: string, type: Alert['type']): void {
      const alert: Alert = { message, type, timedDismiss: true };
      this.$store.dispatch('alerts/addAlert', alert);
    }
  }
});
