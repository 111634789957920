
import { defineComponent } from 'vue';

import { Area } from '@/types/resources/ZipcodeLocations';
import areaService from '@/services/areaService';
import {
  getGeoPermissionsAsPolitelyAsPossible,
  getGeoPermissionsWithoutAsking,
  getGeoPosition,
  getZipcodeFromGeoPosition
} from '@/concerns/geolocation';

const SEARCH_DEBOUNCE_MS = 300;

let inputBlurTimeout = 0;
let debounceSearchTimeout = 0;

export default defineComponent({
  name: 'LocationSearch',
  props: {
    hideSubText: {
      type: Boolean,
      default: false
    },

    zipcodesHighlightEntireLocation: {
      type: Boolean,
      default: false
    },

    initialSearchTerm: {
      type: String,
      default: ''
    },

    submitText: {
      type: String,
      default: 'Go'
    },

    hasSubmitButton: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false,
      locationDisplay: '',
      searchTerm: '',
      searchResults: [] as Area[],
      error: '',
      focused: false,
      currentLocationDenied: true,
      tryingCurrentLocation: false,
      selectFirstWhenFinishedLoading: false
    };
  },

  computed: {
    isZipcodeSearch(): boolean {
      return /^\d+$/.test(this.searchTerm);
    },

    suggestUseCurrentLocation(): boolean {
      return (
        !this.currentLocationDenied && (this.focused || this.tryingCurrentLocation) && this.searchResults.length === 0
      );
    },

    canGetExistingGeoPermissions(): boolean {
      return !!navigator.permissions;
    }
  },

  watch: {
    searchTerm(newVal: string, oldVal: string): void {
      if (newVal === oldVal) return;

      if (newVal.length !== 5 && this.searchResults.length > 0) {
        this.searchResults = [];
      }
    },

    loading(newVal: string): void {
      if (newVal && this.selectFirstWhenFinishedLoading) {
        this.selectFirst();
      }
    }
  },

  mounted(): void {
    getGeoPermissionsWithoutAsking().then((permission) => {
      if (permission === 'granted') this.currentLocationDenied = false;
    });

    if (this.$props.initialSearchTerm) {
      this.searchTerm = this.$props.initialSearchTerm;
      this.search(this.searchTerm).then(() => {
        if (this.searchResults.length === 1) this.selectFirst();
      });
    }
  },

  methods: {
    search(searchTerm: string): Promise<void> {
      this.loading = true;

      return areaService
        .searchAreas(searchTerm)
        .then((locations) => {
          this.searchResults = locations;
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onInputFocus(): void {
      window.clearTimeout(inputBlurTimeout);

      getGeoPermissionsAsPolitelyAsPossible()
        .then((permission) => {
          if (permission === 'granted') this.currentLocationDenied = false;
        })
        .finally(() => {
          this.focused = true;
        });
    },

    onInputBlur(event: FocusEvent): void {
      // If some other element in this component is stealing focus (e.g., a
      // result was clicked) then let it remain in the `focused = true` state.
      const focusThief = event.relatedTarget as HTMLElement | null;
      if (focusThief && this.$el.contains(focusThief)) return;

      window.clearTimeout(inputBlurTimeout);
      inputBlurTimeout = window.setTimeout(() => {
        this.focused = false;
      }, 300);
    },

    selectArea(area: Area): void {
      this.searchTerm = area.zipcode;
      this.loading = false;
      this.searchResults = [];
      this.searchTerm = '';
      this.focused = false;
      this.$emit('select-location', area);
    },

    debounceSearch(): void {
      this.loading = true;

      window.clearTimeout(debounceSearchTimeout);
      debounceSearchTimeout = window.setTimeout(() => {
        this.search(this.searchTerm).then(() => {
          this.loading = false;
          if (this.selectFirstWhenFinishedLoading) this.selectFirst();
        });
      }, SEARCH_DEBOUNCE_MS);
    },

    formatResult(location: Area): string {
      let stringToHighlight =
        this.isZipcodeSearch && !this.zipcodesHighlightEntireLocation
          ? location.zipcode
          : `${location.city}, ${location.state} ${location.zipcode}`;

      const searchTerms = this.searchTerm
        .split(/\s+/)
        .map((item: string) => item.replace(/\W/, ''))
        .filter((item: string) => !!item);

      const toReplace = new RegExp(searchTerms.join('|'), 'ig');
      const result =
        searchTerms.length > 0 ? stringToHighlight.replace(toReplace, `<strong>$&</strong>`) : stringToHighlight;

      return result;
    },

    selectFirst(): void {
      if (this.loading) {
        this.selectFirstWhenFinishedLoading = true;
        return;
      }

      this.selectFirstWhenFinishedLoading = false;
      const firstResult = this.searchResults[0];
      if (!firstResult) {
        this.$emit('no-result', this.searchTerm);
      } else {
        this.selectArea(firstResult);
        this.searchTerm = '';
      }
    },

    getAreaFromZipcode(zipcode: string): Promise<Area | undefined> {
      return areaService.searchAreas(zipcode).then(([area]) => area);
    },

    getAreaFromCurrentLocation(): Promise<Area> {
      return getGeoPosition()
        .then((position) => getZipcodeFromGeoPosition(position))
        .then((zipcode) => this.getAreaFromZipcode(zipcode))
        .then((area) => {
          if (!area) throw new Error(`No area found for zipcode at current location`);

          return area;
        });
    },

    useCurrentLocation(): void {
      this.tryingCurrentLocation = true;
      this.loading = true;

      this.getAreaFromCurrentLocation()
        .then((area) => {
          this.selectArea(area);
        })
        .catch((error) => {
          console.error(error);
          this.currentLocationDenied = true;
        })
        .finally(() => {
          this.tryingCurrentLocation = false;
          this.loading = false;
        });
    }
  }
});
