import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-220882be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card sidebar-card location-pane" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "btn btn-block text-left" }
const _hoisted_4 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("button", _hoisted_3, [
        _createVNode(_component_icon, {
          icon: ['fal', 'map-marker-alt'],
          size: "lg"
        }),
        _createTextVNode(" Location ")
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", null, _toDisplayString(_ctx.locationDisplay), 1)
    ])
  ]))
}