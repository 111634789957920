
import { defineComponent, PropType } from 'vue';
import { OwnedVehicle } from '@/types/resources/OwnedVehicles';

import { isNullish } from '@/utilities';
import { RouteMetaConfig } from '@/routes/types';

export default defineComponent({
  name: 'VehiclePane',

  props: {
    currentStep: {
      type: String as PropType<RouteMetaConfig['onrampStep']>,
      required: true
    },

    storeNamespace: {
      type: String,
      required: false,
      default: 'onramp'
    }
  },

  computed: {
    displayOwnedVehicle(): boolean {
      return !isNullish(this.selectedVehicle);
    },

    vehicleToCreate(): Partial<OwnedVehicle> {
      return this.$store.getters[`${this.storeNamespace}/getVehicleToCreate`];
    },
    
    selectedVehicle(): OwnedVehicle | null {
      return this.$store.getters[`${this.storeNamespace}/getOwnedVehicle`];
    }
  }
});
