import { AxiosError } from 'axios';

import { httpClient } from '@/api';
import { AppointmentSlotsDocument } from '@/types/resources/AppointmentProposals';

import { handleError, handleSuccess } from './helpers';

export default {
  fetchAppointmentSlots(locationId: number) {
    return httpClient
      .get<AppointmentSlotsDocument>(`v3/standalone_appointments/${locationId}/appointment_proposals`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  }
};
