import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "form-label-group form-group" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["id", "autocomplete", "name", "placeholder", "type", "required"]
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.floatingLabels)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.placeholder), 1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      id: _ctx.fieldId,
      ref: "required-input",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      autocomplete: _ctx.autocomplete,
      name: _ctx.name,
      class: "form-control",
      placeholder: _ctx.placeholder,
      type: _ctx.type,
      required: _ctx.required,
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.validate && _ctx.validate(...args)))
    }, null, 40, _hoisted_3), [
      [_vModelDynamic, _ctx.value]
    ]),
    (_ctx.floatingLabels)
      ? (_openBlock(), _createElementBlock("label", {
          key: 1,
          for: _ctx.fieldId
        }, _toDisplayString(_ctx.placeholder), 9, _hoisted_4))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("p", { class: "error-style" }, _toDisplayString(_ctx.error), 513), [
      [_vShow, _ctx.showNameError]
    ])
  ]))
}