import { AxiosError, AxiosResponse } from 'axios';

import { httpClient } from '@/api';
import { handleError, handleSuccess } from '@/services/helpers';
import { GenericMessage } from '@/types/API';
import { EdmundsStyle, EdmundsStyleDocument } from '@/types/EdmundsStyle';
import { GenericDocument } from '@/types/JsonSpec';
import { AcesBaseVehicleResource } from '@/types/resources/AcesBaseVehicle';
import { AcesMakesResource } from '@/types/resources/AcesMake';
import { AcesModelsResource } from '@/types/resources/AcesModel';
import { AcesStylesResource } from '@/types/resources/AcesStyle';
import { AcesSubModelsResource } from '@/types/resources/AcesSubModel';
import { AcesYearsResource } from '@/types/resources/AcesYear';
import { ModelYearDocument } from '@/types/resources/ModelYear';
import { OwnedVehicle, OwnedVehicleDocument } from '@/types/resources/OwnedVehicles';
import { VehicleMaintenanceSchedule, VehicleYmmet } from '@/types/Vehicle';
import { camelKeys } from '@/utilities';

const MAKE_SLUGS = {
  'AM General': 'am-general',
  Acura: 'acura',
  'Alfa Romeo': 'alfa-romeo',
  'Aston Martin': 'aston-martin',
  Audi: 'audi',
  BMW: 'bmw',
  Bentley: 'bentley',
  Bugatti: 'bugatti',
  Buick: 'buick',
  Cadillac: 'cadillac',
  Chevrolet: 'chevrolet',
  Chrysler: 'chrysler',
  Daewoo: 'daewoo',
  Dodge: 'dodge',
  Eagle: 'eagle',
  FIAT: 'fiat',
  Ferrari: 'ferrari',
  Fisker: 'fisker',
  Ford: 'ford',
  Freightliner: 'freightliner',
  GMC: 'gmc',
  Genesiis: 'genesis',
  Geo: 'geo',
  HUMMER: 'hummer',
  Honda: 'honda',
  Hyundai: 'hyundai',
  Infiniti: 'infiniti',
  Isuzu: 'isuzu',
  Jaguar: 'jaguar',
  Jeep: 'jeep',
  Karma: 'karma',
  Kia: 'kia',
  Lamborghini: 'lamborghini',
  'Land Rover': 'land-rover',
  Lexus: 'lexus',
  Lincoln: 'lincoln',
  Lotus: 'lotus',
  MINI: 'mini',
  Maserati: 'maserati',
  Maybach: 'maybach',
  Mazda: 'mazda',
  McLaren: 'mclaren',
  'Mercedes-Benz': 'mercedes-benz',
  Mercury: 'mercury',
  Mitsubishi: 'mitsubishi',
  'Mobility Ventures': 'mobility-ventures',
  Nissan: 'nissan',
  Oldsmobile: 'oldsmobile',
  Panoz: 'panoz',
  Plymouth: 'plymouth',
  Polestar: 'polestar',
  Pontiac: 'pontiac',
  Porsche: 'porsche',
  Ram: 'ram',
  'Rolls-Ryce': 'rolls-royce',
  SRT: 'srt',
  Saab: 'saab',
  Saturn: 'saturn',
  Scion: 'scion',
  Spyker: 'spyker',
  Subaru: 'subaru',
  Suzuki: 'suzuki',
  Tesla: 'tesla',
  Think: 'think',
  Toyota: 'toyota',
  VPG: 'vpg',
  Volkswagen: 'volkswagen',
  Volvo: 'volvo',
  smart: 'smart'
} as Record<string, string>;

export default {
  /**
   * Fetch formatted make name by slug
   *
   * @param slug - The slug to find the make name for
   * @returns The formatted make name as a string
   *
   */

  fetchMakeBySlug(slug: string) {
    const slugs = Object.values(MAKE_SLUGS);
    const makes = Object.keys(MAKE_SLUGS);
    const index = slugs.findIndex((makeSlug) => slug.includes(makeSlug));
    return makes[index];
  },

  fetchYears() {
    return httpClient
      .get<AcesYearsResource>('/v4/vehicles/years')
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error: AxiosError) => {
        throw handleError(error);
      });
  },

  fetchYearsForMakeModel(makeSlug: string, modelSlug: string) {
    return httpClient
      .get<AcesYearsResource>(`/v4/vehicles/years/${makeSlug}/${modelSlug}`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error: AxiosError) => {
        throw handleError(error);
      });
  },

  fetchMakes(year?: string | number) {
    let url = `/v4/vehicles`;

    if (year) {
      url = `${url}/${year}/makes`;
    } else {
      url = `${url}/makes`;
    }

    return httpClient
      .get<AcesMakesResource>(url)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error: AxiosError) => {
        throw handleError(error);
      });
  },

  fetchModels(year: number | string, makeId: number | string) {
    const url = `/v4/vehicles/${year}/${makeId}/models`;

    return httpClient
      .get<AcesModelsResource>(url)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error: AxiosError) => {
        throw handleError(error);
      });
  },

  fetchSubModels(year: number | string, makeId: number | string, modelId: number | string) {
    const url = `/v4/vehicles/${year}/${makeId}/${modelId}/sub_models`;

    return httpClient
      .get<AcesSubModelsResource>(url)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error: AxiosError) => {
        throw handleError(error);
      });
  },

  fetchBaseVehicle(baseVehicleId: number | string) {
    return httpClient
      .get<AcesBaseVehicleResource>(`/v4/vehicles/base_vehicle/${baseVehicleId}`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error: AxiosError) => {
        throw handleError(error);
      });
  },

  fetchBaseVehicleByYMM(year: string, make: string, model: string) {
    return httpClient
      .get<AcesBaseVehicleResource>(`/v4/vehicles/base_vehicle/${year}/${make}/${model}`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error: AxiosError) => {
        throw handleError(error);
      });
  },

  fetchTrims(year: number | string, makeId: number | string, modelId: number | string, subModelId: number | string) {
    return httpClient
      .get<AcesStylesResource>(`/v4/vehicles/${year}/${makeId}/${modelId}/${subModelId}/styles`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error: AxiosError) => {
        throw handleError(error);
      });
  },

  fetchVehicleFromPlate(plate: string, state: string): Promise<any> {
    return httpClient
      .get(`v3/vehicles/from_plate/${plate}/${state}`)
      .then((response: AxiosResponse) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        return handleError(error);
      });
  },

  // Will either return an EdmundsStyle (if one exists for the squished version
  // of the given VIN in our database), or it will fail to return anything (404)
  fetchEdmundsStyleFromVin(vin: string): Promise<EdmundsStyle> {
    return httpClient
      .get<EdmundsStyleDocument>(`v3/vehicles/edmunds_styles/from_vin/${vin}`)
      .then((response) => handleSuccess(response))
      .catch((error) => handleError(error));
  },

  // Will return YMMET information for the given VIN, as much as we can match to
  // corresponding values in our database. If we have a full match for the VIN,
  // it'll return the year, make, model, engine, and trim. If we don't, then it
  // will only return the YMMET *up to* the field where it fails to match with
  // one in our database. For example:
  //
  // If you try to decode a 2013 Mazda 3 VIN, you'll get back full EdmundsStyle
  // info (full Y/M/M/E/T) because we could match the "squished VIN" to an
  // EdmundsStyle in our database that actually came from Edmunds a while back.
  // However, if you try to decode a 2021 Mazda 3 VIN (note that it's 2021, not
  // 2013) we can't match the "squished VIN" because the vehicle came from ACES
  // (we *do* have corresponding EdmundsStyle records for 2021 Mazdas, created
  // from the ACES data; we just can't figure out which one it is locally with
  // the "squished VIN" because the latter data is stale).
  //
  // So what we do in that case is call out to Vinquery's API for the VIN. It'll
  // tell us that it's a "2021 Mazda MAZDA3"... Ah, shit. That model ("MAZDA3")
  // doesn't match what we have in our Edmunds* tables (our model is named "3").
  // Note that this kind of mismatch can potentially happen with makes
  // ("Mercedes-Benz" vs. "Mercedes Benz"), models ("MAZDA3" vs. "3", "F150" vs.
  // "F-150"), engines, and trims.
  //
  // Now, we know we have 2021 Mazda EdmundsStyle records, but we can't match to
  // a specific model/engine/trim. So, we'll just return 2021 and Mazda and let
  // the user figure it out from there. Coming back from the server it'll look
  // like:
  //
  // { "year": 2021, "make": "Mazda", "model": null, "engine": null, "trim": null }
  //
  // The number of returned fields (where it stops in the YMMET lookup) will
  // vary depending on the vehicle.
  //
  fetchYmmetFromVin(vin: string): Promise<VehicleYmmet> {
    return httpClient
      .get<GenericDocument<VehicleYmmet>>(`v3/vehicles/edmunds_styles/ymmet_from_vin/${vin}`)
      .then((response) => handleSuccess(response))
      .then(({ data }) => data)
      .catch((error) => handleError(error));
  },

  // This is similar to `fetchEdmundsStyleFromVin` and `fetchYmmetFromVin` in
  // that it constructs as much of an OwnedVehicle as it can from the given VIN,
  // using the same API endpoints from the aforementioned methods. This may
  // result in a complete OwnedVehicle record if we can get a full EdmundsStyle
  // match out of it, or a partial OwnedVehicle with as much YMMET data as we
  // can match otherwise.
  fetchVehicleFromVin(vin: string): Promise<Partial<OwnedVehicle>> {
    return httpClient
      .get<EdmundsStyleDocument>(`v3/vehicles/edmunds_styles/from_vin/${vin}`)
      .then((response) => handleSuccess(response))
      .catch(() =>
        httpClient
          .get<GenericDocument<VehicleYmmet>>(`v3/vehicles/edmunds_styles/ymmet_from_vin/${vin}`)
          .then((response) => handleSuccess(response))
          .then(({ data }) => data)
      )
      .then((vehicleData) => {
        // Note the type on `vehicleData`: could be either an EdmundsStyle or a
        // VehicleYmmet. The former will have an `id` field (corresponding to an
        // `edmundsStyleId` on the OwnedVehicle) in addition to full YMMET,
        // whereas the latter will only have YMMET fields and may be incomplete.
        const vehicle: Partial<OwnedVehicle> = { vin };
        if ('id' in vehicleData && vehicleData.id) vehicle.edmundsStyleId = vehicleData.id;
        if (vehicleData.year) vehicle.year = vehicleData.year;
        if (vehicleData.make) vehicle.make = vehicleData.make;
        if (vehicleData.model) vehicle.model = vehicleData.model;
        if (vehicleData.engine) vehicle.engine = vehicleData.engine;
        if (vehicleData.trim) vehicle.trim = vehicleData.trim;
        return vehicle;
      })
      .catch((error) => handleError(error));
  },

  fetchModelYear: (vehicleSlug: string) =>
    httpClient
      .get<ModelYearDocument>(`v3/vehicles/edmunds_model_years/${vehicleSlug}`)
      .then((response) => handleSuccess(response))
      .catch((error) => handleError(error)),

  fetchModelYearMaintenanceSchedule: (modelYearId: string | number) =>
    httpClient
      .get<VehicleMaintenanceSchedule>(`v3/vehicles/edmunds_model_years/${modelYearId}/schedule`)
      .then((response) => {
        return camelKeys(response.data);
      })
      .catch((error) => {
        throw handleError(error);
      }),

  fetchYearsWithSchedule: (modelId: string | number) =>
    httpClient
      .get(`v3/vehicles/edmunds_model_years/schedule/years`, { params: { model_id: modelId } })
      .then((response) => handleSuccess(response))
      .catch((error) => handleError(error)),

  fetchAcesYearsWithSchedule: (baseVehicleId: string | number) =>
    httpClient
      .get(`v4/schedules/years`, { params: { base_vehicle_id: baseVehicleId } })
      .then((response) => handleSuccess(response))
      .catch((error) => handleError(error)),

  fetchStyleMaintenanceSchedule: (styleId: string | number) =>
    httpClient
      .get<VehicleMaintenanceSchedule>(`v3/vehicles/edmunds_styles/${styleId}/schedule`)
      .then((response) => {
        return camelKeys(response.data) as VehicleMaintenanceSchedule;
      })
      .catch((error) => {
        throw handleError(error);
      }),

  fetchAcesStyleMaintenanceSchedule: (styleId: string | number) =>
    httpClient
      .get<VehicleMaintenanceSchedule>(`v4/schedules`, {
        params: { style_id: styleId }
      })
      .then((response) => {
        return camelKeys(response.data) as VehicleMaintenanceSchedule;
      })
      .catch((error) => {
        throw handleError(error);
      }),

  updateOwnedVehicleInfo(vehicleId: number, payload: any) {
    return httpClient
      .put<OwnedVehicleDocument>(`v3/owned_vehicles/${vehicleId}`, payload)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        return handleError(error);
      });
  },
  deleteOwnedVehicle(vehicleId: number) {
    return httpClient
      .delete<GenericMessage>(`v3/owned_vehicles/${vehicleId}`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        return handleError(error);
      });
  }
};
