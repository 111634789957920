import * as changeCase from 'change-case';
import {
  isArguments,
  isArray,
  isError,
  isFunction,
  isNaN,
  isNull,
  isNumber,
  isObject,
  isString,
  isUndefined,
  map
} from 'underscore';

export function objectIsEmpty(obj: object): boolean {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function toFileName(toConvert: string): string {
  return toConvert.replace(/\s+/g, '_').toLowerCase();
}

// Magically turns anything into the string you'd expect... e.g.,
// stringify(undefined) => ''
// stringify(null)      => ''
// stringify(NaN)       => ''
// stringify('hi')      => 'hi'
// stringify(0)         => '0'
// stringify(123)       => '123'
// stringify(1.3)       => '1.3'
// stringify(function fooFn(){})  => 'fooFn' // the name of the function
// stringify(function(){})        => ''      // the name of the (anonymous) function
// stringify(new TypeError('yo')) => 'yo'    // the message of the error
// stringify([1, "two", 3, ['a', '4']]) => '1, two, 3, a, 4'       // values are stringified
// stringify({year: 1991, make: "GMC"}) => 'year: 1991, make: GMC' // values are stringified
export function stringify(value: any): string {
  if (typeof value === 'string') return value;
  if (isUndefined(value) || isNull(value) || isNaN(value)) return '';
  if (isString(value) || isNumber(value)) return '' + value;
  if (isFunction(value)) return value.name;
  if (isError(value)) return value.message;
  if (isArray(value) || isArguments(value)) return map(value, (val: any) => stringify(val)).join(', ');
  if (isObject(value)) return map(value, (val: any, key: any) => `${key}: ${stringify(val)}`).join(', ');
  try {
    return value.toString();
  } catch {
    return '' + value;
  }
}

// Capitalizes the start of each word; capitalize("mercedes-benz") => "Mercedes-Benz"
export function capitalize(value: any): string {
  return stringify(value).replace(/\b([a-z])/g, (char) => char.toUpperCase());
}

// Trims the string and adds a period if the given string doesn't already end in
// a period, comma, exclamation mark, question mark, or semicolon.
export function punctuate(text: string): string {
  text = text.trim();
  return !text || text.match(/[.,!?;]$/) ? text : text + '.';
}

// Concatenates a list of strings into a paragraph.
export function toParagraph(strings: string[]): string {
  let paragraph = '';

  for (let sentence of strings) {
    sentence = sentence.trim();
    if (!sentence) continue;

    sentence = capitalizeFirst(sentence);
    sentence = punctuate(sentence);

    if (paragraph.length) paragraph += ' ';
    paragraph += sentence;
  }

  return paragraph;
}

// Smushes all the digits into one big "integer"; digitize("+1 (800) 588-2300, Empiiiire... today!") => "18005882300"
export function digitize(value: any): string {
  return stringify(value).replace(/\D/g, '');
}

export function percentize(value: number): string {
  return `${value * 100}%`;
}

export function monetize(
  value: number | string,
  valueIsIn: 'cents' | 'dollars' = 'cents',
  locale: string = 'en-US',
  additionalOptions?: Intl.NumberFormatOptions
): string {
  if (!isNumber(value) && !isString(value)) return '';
  const valStr = `${value}`.trim(); // make it a good boy string
  const valFloat = parseFloat(valStr.replace(/[^\d.]/g, '')); // parse only digits and decimal (no sign or other chars)
  const valInCents = valueIsIn === 'cents' ? valFloat / 100 : valFloat;
  const options = {
    style: 'currency',
    currency: 'USD',
    ...(additionalOptions || {})
  };
  const formattedValue = new Intl.NumberFormat(locale, options).format(valInCents);
  return formattedValue;
}

export function humanize(text: string): string {
  return text
    .replace(/_/g, ' ') // un-snake_case
    .replace(/([a-z])([A-Z])/g, '$1 $2') // un-camelCase
    .replace(/([a-zA-Z])([0-9])/g, '$1 $2') // split alphaDigits
    .replace(/([0-9])(?!st\b|nd\b|rd\b|th\b)([a-zA-Z])/g, '$1 $2') // split digitAlphas (except ordinals)
    .split(/ +/g) // downcase, but skip words which are 2+ chars long and have no lowercase letters
    .map((word) => (word.match(/^[^a-z]{2,}$/) ? word : word.toLowerCase()))
    .join(' ') // capitalize the first letter and any letter that starts a sentence
    .replace(/^[a-z]|[.?!] [a-z]/g, (firstChar) => firstChar.toUpperCase());
}

export function isMundaneObject(obj: any): obj is { [index: string]: any };
export function isMundaneObject(obj: any): boolean {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

export function isNullish(val: null): true;
export function isNullish(val: undefined): true;
export function isNullish(val: null | undefined): true;
export function isNullish(val: any): val is null | undefined;
export function isNullish(val: any): boolean {
  return typeof val === 'undefined' || val === null;
}

export function mapKeys<T extends any[]>(obj: T, mapper: (key: string) => string, deep: boolean): any[];
export function mapKeys<T extends { [index: string]: any }>(
  obj: T,
  mapper: (key: string) => string,
  deep: boolean
): { [index: string]: any };
export function mapKeys<T extends any>(obj: T, mapper: (key: string) => string, deep: boolean): T;
export function mapKeys(obj: any, mapper: (key: string) => string, deep: boolean): any {
  if (isArray(obj)) return obj.map((item: any) => mapKeys(item, mapper, deep));
  if (!isMundaneObject(obj)) return obj;

  return Object.keys(obj).reduce(
    (memo, key) => {
      const newKey = mapper(key);
      const newVal = deep ? mapKeys(obj[key], mapper, deep) : obj[key];
      return Object.assign({}, memo, { [newKey]: newVal });
    },
    {} as { [index: string]: any }
  );
}

/**
 * Maps over an object (`obj`), changing the case of each key to the specified
 * case (`caseType`), deeply if `deep` is `true`, but only on keys which match
 * the optionally-given regular expression `keyFilter` (matches all by default).
 */
function caseKeys(caseType: 'camel' | 'snake' | 'lower', obj: any[], deep: boolean, keyFilter?: RegExp): any[];
function caseKeys(
  caseType: 'camel' | 'snake' | 'lower',
  obj: { [index: string]: any },
  deep: boolean,
  keyFilter?: RegExp
): { [index: string]: any };
function caseKeys(caseType: 'camel' | 'snake' | 'lower', obj: any, deep: boolean, keyFilter: RegExp = /(?:)/): any {
  const aliases = { camel: 'camelCase', snake: 'snakeCase', lower: 'noCase' } as const;
  const caseKey = changeCase[aliases[caseType]];
  return mapKeys(obj, (key) => (keyFilter.test(key) ? caseKey(key) : key), deep);
}

// Converts objects with non-snake-case keys into objects with snake-case keys.
// Pass `false` as the second parameter to only snake root-level keys. Pass a
// regular expression as the third parameter to only snake keys which match the
// pattern.
export function snakeKeys(
  paramsObj: { [index: string]: any },
  deep: boolean = true,
  keyFilter?: RegExp
): { [index: string]: any } {
  return caseKeys('snake', paramsObj, deep, keyFilter);
}

// Converts objects with non-camel-case keys into objects with camel-case keys.
// Pass `false` as the second parameter to only camel root-level keys. Pass a
// regular expression as the third parameter to only camel keys which match the
// pattern.
export function camelKeys(
  paramsObj: { [index: string]: any },
  deep: boolean = true,
  keyFilter?: RegExp
): { [index: string]: any } {
  return caseKeys('camel', paramsObj, deep, keyFilter);
}

export function capitalizeFirst(str: string): string {
  return str.replace(/^\s*([a-z])/, (char) => char.toUpperCase());
}

const INT_TO_ENGLISH: Record<number, string> = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
  10: 'ten',
  11: 'eleven',
  12: 'twelve',
  13: 'thirteen',
  14: 'fourteen',
  15: 'fifteen',
  16: 'sixteen',
  17: 'seventeen',
  18: 'eighteen',
  19: 'nineteen',
  20: 'twenty',
  30: 'thirty',
  40: 'fourty',
  50: 'fifty',
  60: 'sixty',
  70: 'seventy',
  80: 'eighty',
  90: 'ninety'
};

const INT_TO_ENGLISH_ORDINAL: Record<number, string> = {
  1: 'first',
  2: 'second',
  3: 'third',
  4: 'fourth',
  5: 'fifth',
  6: 'sixth',
  7: 'seventh',
  8: 'eighth',
  9: 'ninth',
  10: 'tenth',
  11: 'eleventh',
  12: 'twelfth',
  13: 'thirteenth',
  14: 'fourteenth',
  15: 'fifteenth',
  16: 'sixteenth',
  17: 'seventeenth',
  18: 'eighteenth',
  19: 'nineteenth',
  20: 'twentieth',
  30: 'thirtieth',
  40: 'fourtieth',
  50: 'fiftieth',
  60: 'sixtieth',
  70: 'seventieth',
  80: 'eightieth',
  90: 'ninetieth'
};

const INT_TO_ENGLISH_MAGNITUDE: Record<number, string> = {
  100: 'hundred',
  1000: 'thousand',
  1000000: 'million',
  1000000000: 'billion',
  1000000000000: 'trillion'
};

// Convert an integer to English. For example:
//
//   intToEnglish(1) => "one"
//   intToEnglish(101) => "one hundred one"
//   intToEnglish(945) => "nine hundred forty-five"
//   intToEnglish(124945) => "one hundred twenty-four thousand nine hundred forty-five"
//   intToEnglish(1100002) => "one million one hundred thousand two"
//
export function intToEnglish(num: number, ordinalize = false): string {
  if (num === 0) return ordinalize ? 'zeroth' : 'zero';

  if (num <= 20) return ordinalize ? INT_TO_ENGLISH_ORDINAL[num] : INT_TO_ENGLISH[num];

  if (num < 100) {
    const tens = 10 * Math.floor(num / 10);
    const ones = num % 10;
    if (ordinalize && ones === 0) return INT_TO_ENGLISH_ORDINAL[tens];

    return `${INT_TO_ENGLISH[tens]}-${intToEnglish(ones, ordinalize)}`;
  }

  if (num < 1000) {
    const hundreds = 100 * Math.floor(num / 100);
    const hundredsEnglish = INT_TO_ENGLISH[hundreds / 100];
    const rest = num % 100;
    if (rest === 0) return `${hundredsEnglish} ${ordinalize ? 'hundredth' : 'hundred'}`;

    return `${hundredsEnglish} hundred ${intToEnglish(rest, ordinalize)}`.trim();
  }

  const magnitudes = [1000, 1000000, 1000000000, 1000000000000, 1000000000000000];
  for (let i = 1; i < magnitudes.length; i++) {
    const under = magnitudes[i];
    if (num < under) {
      const magnitude = magnitudes[i - 1];
      const magnitudeEnglish = INT_TO_ENGLISH_MAGNITUDE[magnitude];
      const multiple = magnitude * Math.floor(num / magnitude);
      const multipleEnglish = intToEnglish(multiple / magnitude);
      const rest = num % multiple;
      if (rest === 0) return `${multipleEnglish} ${magnitudeEnglish}${ordinalize ? 'th' : ''}`;

      return `${multipleEnglish} ${magnitudeEnglish} ${intToEnglish(rest, true)}`.trim();
    }
  }

  return ordinalize ? 'umptieth' : 'umpty';
}

// Convert an integer to ordinalized English. For example:
//
//   ordinalize(1) => "first"
//   ordinalize(101) => "one hundred first"
//   ordinalize(945) => "nine hundred forty-fifth"
//   ordinalize(124945) => "one hundred twenty-four thousand nine hundred forty-fifth"
//   ordinalize(1100002) => "one million one hundred thousand second"
//
export function ordinalize(rawInteger: number): string {
  const integer = Math.floor(rawInteger);
  return intToEnglish(integer, true);
}

// Clamp/bound the first value to be between the next two values. For example:
//
//   bound(0,  5, 10) //=> 5
//   bound(5,  5, 10) //=> 5
//   bound(8,  5, 10) //=> 8
//   bound(10, 5, 10) //=> 10
//   bound(15, 5, 10) //=> 10
//
export function bound(value: number, min: number, max: number): number {
  return min <= max ? Math.max(min, Math.min(value, max)) : Math.max(max, Math.min(value, min));
}

export const formatSortParameter = ({ prop, order }: { prop: string; order: string }): Record<string, string> =>
  prop && order ? { order: `${prop}:${order === 'descending' ? 'desc' : 'asc'}` } : {};
