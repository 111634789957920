
import configMixin from '@/mixins/configMixin';
import { SubscriberOfferLineItem } from '@/types/Offer';
import { WidgetType } from '@/types/PartnerConfig';
import { Subscription } from '@/types/microservices/Subscriptions';
import { monetize } from '@/utilities';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'LineItemPricing',

  mixins: [configMixin],

  props: {
    lineItem: {
      type: Object as PropType<SubscriberOfferLineItem>,
      required: true
    }
  },

  computed: {
    alternateVariant(): string {
      if (this.hasPlusSubscription && this.lineItem.variant !== 'part' && this.lineItem.oemPart !== true)
        return 'Openbay+ Price';

      switch (this.lineItem.variant) {
        case 'part':
          return 'Part';
        case 'rate':
          return 'Lyft Labor Rate';
        case 'flat rate':
          return 'Lyft Driver Price';
        case 'labor only':
          return 'Labor Only';
        case 'retail':
          return 'Retail Price';
        default:
          return '';
      }
    },

    discountedPriceClass(): Record<string, boolean> {
      return {
        'text-lyft': this.hasLyftDiscount,
        'text-primary': this.hasPlusSubscription
      };
    },

    discountsApplied(): number {
      return this.lineItem.totalDiscountCents || 0;
    },

    extendedPrice(): string {
      return monetize(this.lineItem.extendedPriceCents, 'cents');
    },

    hasLyftDiscount(): boolean {
      const discounts = this.lineItem.discountsApplied || {};
      return Object.keys(discounts).some((discountType) => discountType.includes('Lyft'));
    },

    subscriptions(): Subscription[] {
      return this.$store.getters['onramp/getSubscriptions'];
    },

    hasPlusSubscription(): boolean {
      if (this.widgetType === WidgetType.Admin)
        return this.subscriptions.length > 0;
        
      return this.$store.getters['user/hasActiveSubscription'];
    },

    hasSubscriptionDiscount(): boolean {
      return this.discountsApplied > 0 && this.hasPlusSubscription;
    },

    preDiscountExtendedPrice(): string {
      return monetize(this.lineItem.extendedPricePreDiscountCents, 'cents');
    },

    preDiscountUnitPrice(): string {
      return monetize(this.lineItem.referencePriceCents, 'cents');
    },

    showDiscountInfo(): boolean {
      return (this.hasLyftDiscount && this.variant !== this.alternateVariant) || this.hasSubscriptionDiscount;
    },

    unitPriceCents(): string {
      return monetize(this.lineItem.unitPriceCents, 'cents');
    },

    variant(): string {
      switch (this.lineItem.variant) {
        case 'part':
          return 'Part';
        case 'rate':
          return 'Labor Rate';
        case 'flat rate':
          return 'Package Price';
        case 'labor only':
          return 'Labor Only';
        case 'retail':
          return 'Retail Price';
        default:
          return '';
      }
    }
  }
});
