
import { PropType, defineComponent } from 'vue';
import { ServiceSelectionCategory } from '@/types/resources/ServiceSelectionCategories';
import { ServiceSelectionInterview } from '@/types/resources/ServiceSelectionInterview';

//Components
import InlineSvg from 'vue-inline-svg';
import { CartService, Interview } from '@/types/ServiceRequest';
import { FinishedInterviewResponse } from '@/types/FinishedInterviewResponse';
import { ServiceInterviewParams } from '@/types/params/ServiceInterviewParams';

import serviceMixin from '@/mixins/serviceMixin';
import assetMixin from '@/mixins/assetMixin';

export default defineComponent({
  name: 'ServiceInterview',

  mixins: [
    serviceMixin,
    assetMixin,
  ],

  components: {
    InlineSvg
  },

  props: {
    viewParams: {
      type: Object as PropType<ServiceInterviewParams>,
      required: false,
      default: () => null
    }
  },

  data() {
    return {
      currentInterview: {} as ServiceSelectionInterview | ServiceSelectionCategory,
      answers: [] as Interview[]
    };
  },

  computed: {
    sortedAnswers(): ServiceSelectionInterview[] {
      const toSort = [...this.currentInterview.interviews];
      return toSort.sort((a, b) => a.weight - b.weight);
    }
  },

  mounted(): void {
    this.currentInterview = this.viewParams.interview;
    if (this.currentInterview.interviews.length === 0) {
      this.skipInterview(this.currentInterview.serviceId);
    }
  },

  methods: {
    selectAnswer(interview: ServiceSelectionInterview) {
      this.answers.push({
        question: this.currentInterview.question,
        answer: interview.answer.trim()
      });

      if (interview.interviews.length === 0) {
        const finishedInterview = {
          interview: interview,
          answers: this.answers
        };
        this.finishInterview(finishedInterview);
      } else {
        this.currentInterview = interview;
        if (this.$refs.interviewAnswers) {
          (this.$refs.interviewAnswers as any)[0].focus();
          (this.$refs.interviewAnswers as any)[0].blur();
        }
      }
    },

    goToNotesStep(): void {
      this.$store.dispatch('ui/pushView', {
        name: 'ServiceNotes'
      });
    },

    skipInterview(serviceId: number): void {
      let cartService = this.createCartService(serviceId, []);
      this.addCartService(cartService);
      this.$store.dispatch('ui/replaceView', {
        name: 'ServiceNotes'
      });
    },

    addCartService(cartService: CartService) {
      this.$store.commit('onramp/setServiceToAdd', cartService);
      this.$store.commit('onramp/addOrUpdateService', cartService);
    },

    finishInterview(response: FinishedInterviewResponse): void {
      let cartService = this.createCartService(response.interview.serviceId, response.answers);
      this.addCartService(cartService);
      this.goToNotesStep();
    },
  }
});
