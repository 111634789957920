declare global {
  interface Navigator {
    standalone: boolean | undefined; // only in Safari
  }
}

function withTimeout<T>(timeoutMs: number, promise: Promise<T>): Promise<T> {
  const timeoutPromise = new Promise<T>((_resolve, reject) => {
    const timeoutId = setTimeout(() => {
      clearTimeout(timeoutId);
      const error = new Error(`Promise timed out after ${timeoutMs} milliseconds.`);
      reject(error);
    }, timeoutMs);
  });

  return Promise.race([timeoutPromise, promise]);
}

// Get geolocation position information from the browser. Will `catch` if
// permission is denied or if it's unavailable on this particular client.
export function getGeoPosition(): Promise<GeolocationPosition> {
  const timeoutMs = 4000;

  const promise = new Promise<GeolocationPosition>((resolve, reject) => {
    if (!navigator.geolocation) return reject(new Error('Geolocation unavailable on this client'));

    navigator.geolocation.getCurrentPosition(
      (position) => resolve(position),
      (error) => reject(error),
      { timeout: timeoutMs + 1000 }
    );
  });

  // Need to manually time this thing out if `navigator.standalone` is true,
  // because neither the success callback nor the failure callback passed to
  // `Geolocation#getCurrentPosition` will run on iOS if permission is denied at
  // the OS level. Yes, it's weird and frustrating.
  return navigator.standalone ? withTimeout(timeoutMs, promise) : promise;
}

// Safari (this includes other browsers on iOS like Chrome, because they are all
// Safari under the hood on iOS) does not make `navigator.permissions`
// available, so you have to actually request location permissions from the user
// if you want to get the *possibly* existing answer. This will attempt to get
// the existing permissions, or it will `catch` if permissions are unavailable.
export function getGeoPermissionsWithoutAsking(): Promise<PermissionState> {
  return new Promise((resolve, reject) => {
    if (!navigator.permissions) return reject(new Error('Geolocation permissions unavailable on this client'));

    navigator.permissions.query({ name: 'geolocation' }).then(({ state }) => resolve(state));
  });
}

// This will either get an existing granted or denied permission for
// geolocation, or it will prompt the user for permission if they haven't
// previously selected one or the other.
export function getGeoPermissionsByPossiblyAsking(): Promise<PermissionState> {
  return getGeoPosition()
    .then<PermissionState>(() => 'granted')
    .catch<PermissionState>(() => 'denied');
}

// This will try very hard not to ask the user directly for permission, but it
// will still ask if their browser doesn't support a silent query or if the
// prompt is necessary
export function getGeoPermissionsAsPolitelyAsPossible(): Promise<PermissionState> {
  return getGeoPermissionsWithoutAsking()
    .then((permission) => (permission === 'prompt' ? getGeoPermissionsByPossiblyAsking() : permission))
    .catch(() => getGeoPermissionsByPossiblyAsking());
}

export function geocoderResultsFromGeoPosition(position: GeolocationPosition): Promise<google.maps.GeocoderResult[]> {
  return new Promise((resolve, reject) => {
    const { latitude, longitude } = position.coords;
    const location = new google.maps.LatLng(latitude, longitude);

    const geocoding = new google.maps.Geocoder().geocode({ location }, (response, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        resolve(response);
      } else {
        reject(new Error(`Google geocoder error: ${status}`));
      }
    }) as void | Promise<void>; // documentation says it returns a promise...

    if (geocoding && geocoding?.catch) geocoding.catch((error) => reject(error));
  });
}

export function getZipcodeFromGeoPosition(position: GeolocationPosition): Promise<string> {
  return geocoderResultsFromGeoPosition(position).then((results) => {
    const topResult = results[0];
    if (!topResult) throw new Error('No results for position');

    const zipcodeMatch = topResult.formatted_address.match(/\b[0-9]{5}\b/);
    if (!zipcodeMatch) throw new Error('No zipcode found for position');

    return zipcodeMatch[0];
  });
}
