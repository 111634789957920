import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "price" }
const _hoisted_2 = { class: "text-primary" }
const _hoisted_3 = { class: "price-list text-strikethrough font-weight-normal" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "text-primary join-plus-price" }
const _hoisted_6 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasSubscription && _ctx.discountsApplied)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("p", null, [
            _createTextVNode(" Your price: "),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.priceTotal), 1)
          ]),
          (_ctx.discountsApplied)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("p", null, [
                  _createTextVNode(" List price: "),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.listPrice), 1)
                ]),
                _createElementVNode("p", null, "You save: " + _toDisplayString(_ctx.discountsAppliedFormatted), 1)
              ], 64))
            : _createCommentVNode("", true)
        ], 64))
      : (_ctx.discountsApplied)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
            _createElementVNode("span", null, _toDisplayString(_ctx.listPrice), 1),
            false
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode("( "),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.priceTotal), 1),
                  _createTextVNode(" when you "),
                  _createElementVNode("a", {
                    href: "#",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('joinplus::modal::show')), ["prevent"]))
                  }, "join Openbay+"),
                  _createTextVNode(" )")
                ], 64))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_6, [
            _createElementVNode("span", null, _toDisplayString(_ctx.priceTotal), 1)
          ]))
  ]))
}