import ChooseLocation from '@/components/Onramp/ChooseLocation.vue';
import ZeroCoverage from '@/components/Onramp/ZeroCoverage.vue';
import ServiceSelector from '@/components/Onramp/Services/ServiceSelector.vue';
import ServiceInterview from '@/components/Onramp/Services/ServiceInterview.vue';
import ServiceNotes from '@/components/Onramp/Services/ServiceNotes.vue';
import VehicleSelector from '@/components/Onramp/Vehicle/VehicleSelector.vue';
import SignUp from '@/components/Onramp/SignUp.vue';
import OfferSelector from '@/components/Onramp/Offer/OfferSelector.vue';
import AppointmentSelector from '@/components/Onramp/Appointment/AppointmentSelector.vue';
import Confirmation from '@/components/Onramp/Confirmation/Confirmation.vue';
import AppointmentConfirmed from '@/components/Onramp/Confirmation/AppointmentConfirmed.vue';
import LocationSelector from '@/components/Onramp/Location/LocationSelector.vue';

import { RouteMeta, RouteMetaConfig } from './types';

const _defaultMetaProps = {
  showCloseButton: true,
  showBackButton: true
};

const withDefaults = (meta: Partial<RouteMetaConfig>): RouteMetaConfig => {
  return {
    ...meta,
    ..._defaultMetaProps
  };
};

export const onrampComponents = {
  ChooseLocation,
  LocationSelector,
  ZeroCoverage,
  ServiceSelector,
  ServiceInterview,
  ServiceNotes,
  VehicleSelector,
  SignUp,
  OfferSelector,
  AppointmentSelector,
  Confirmation,
  AppointmentConfirmed
};

// FIXME: how do we not repeat ourselves here?
export const onrampMeta: RouteMeta = {
  ChooseLocation: {},
  ZeroCoverage: {},
  LocationSelector: withDefaults({
    onrampStep: 'services'
  }),
  ServiceSelector: withDefaults({
    onrampStep: 'services'
  }),
  ServiceInterview: withDefaults({
    onrampStep: 'services'
  }),
  ServiceNotes: withDefaults({
    onrampStep: 'services'
  }),
  VehicleSelector: withDefaults({
    onrampStep: 'vehicle'
  }),
  SignUp: withDefaults({
    onrampStep: 'sign-up'
  }),
  OfferSelector: withDefaults({
    onrampStep: 'offer'
  }),
  AppointmentSelector: withDefaults({
    onrampStep: 'schedule'
  }),
  Confirmation: withDefaults({
    onrampStep: 'confirm'
  }),
  AppointmentConfirmed: {
    onrampStep: 'confirm',
    showBackButton: false,
    showCloseButton: false
  }
};
