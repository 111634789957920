import { httpClientMicro } from '@/api-microservices';
import store from '@/store/store';
import { ServiceRequest, ServiceRequestParameters } from '@/types/ServiceRequest';
import { snakeKeys } from '@/utilities';
import { handleSuccess } from '../helpers';
import { OwnedVehicle } from '@/types/resources/OwnedVehicles';
import { VehicleRequest } from '@/types/Vehicle';
import { OfferFull } from '@/types/Offer';
import { Subscription } from '@/types/microservices/Subscriptions';

export default class PartnerService {
  routes = {
    serviceRequests: (partnerId: number) => `${this.environmentPath()}/v1/partner-admin/${partnerId}/service-requests`,
    ownedVehicles: (partnerId: number, userId: number) =>
      `${this.environmentPath()}/v1/partner-admin/${partnerId}/owned-vehicles/${userId}`,
    subscriptions: (partnerId: number, userId: number) =>
      `${this.environmentPath()}/v1/partner-admin/${partnerId}/subscriptions/${userId}`
  };

  environmentPath() {
    return `${store.getters['config/getBaseUrl']}/services/partners`;
  }

  public createServiceRequest(partnerId: number, payload: ServiceRequestParameters): Promise<ServiceRequest> {
    return httpClientMicro
      .post(`${this.routes.serviceRequests(partnerId)}`, snakeKeys(payload))
      .then((response) => handleSuccess(response));
  }

  public getOwnedVehicles(partnerId: number, userId: number): Promise<OwnedVehicle[]> {
    return httpClientMicro
      .get(`${this.routes.ownedVehicles(partnerId, userId)}`)
      .then((response) => handleSuccess(response));
  }

  public getSubscriptions(partnerId: number, userId: number): Promise<Subscription[]> {
    return httpClientMicro.get(`${this.routes.subscriptions(partnerId, userId)}`).then((response) => response.data);
  }

  public fetchOffers(partnerId: number, serviceRequestId: number): Promise<OfferFull[]> {
    return httpClientMicro
      .get(`${this.routes.serviceRequests(partnerId)}/${serviceRequestId}/offers`)
      .then((response) => handleSuccess(response));
  }

  public createOwnedVehicle(partnerId: number, userId: number, payload: OwnedVehicle): Promise<OwnedVehicle[]> {
    return httpClientMicro
      .post(`${this.routes.ownedVehicles(partnerId, userId)}`, payload)
      .then((response) => response.data);
  }
}
