
import { PropType, defineComponent } from 'vue';

type VueClassProp = string | Record<string, boolean> | (string | Record<string, boolean>)[];

export default defineComponent({
  name: 'ConfirmationDetailRow',

  props: {
    bottom: {
      type: Boolean,
      default: false
    },

    contentClass: {
      type: [String, Array] as PropType<VueClassProp>,
      default: () => []
    }
  },

  computed: {
    contentClasses(): (string | Record<string, boolean>)[] {
      return Array.isArray(this.contentClass) ? this.contentClass : [this.contentClass];
    }
  }
});
