
import { defineComponent } from 'vue';

import { AppointmentSlot } from '@/types/ServiceRequest';

export default defineComponent({
  name: 'SchedulePane',

  computed: {
    appointment(): AppointmentSlot[] | null {
      return this.$store.getters['onramp/getAppointmentSlot'];
    }
  }
});
