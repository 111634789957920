
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LoadingSpinner',

  props: {
    relative: {
      type: Boolean,
      default: false
    },

    centered: {
      type: Boolean,
      default: false
    },

    text: {
      type: String,
      required: false,
      default: 'Loading...'
    },

    size: {
      type: String,
      required: false,
      default: 'lg'
    },

    hideBackground: {
      type: Boolean,
      required: false,
      default: false
    }
  }
});
