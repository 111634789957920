
import { defineComponent } from 'vue';

//Types
import { OfferFull } from '@/types/Offer';
import { monetize } from '../../../utilities';
import assetMixin from '@/mixins/assetMixin';

export default defineComponent({
  name: 'OfferPane',

  mixins: [assetMixin],

  computed: {
    discountsApplied(): number {
      return this.offer?.totalDiscountCents || 0;
    },

    offer(): OfferFull | undefined {
      return this.$store.getters['onramp/getOffer'];
    },

    priceClasses(): Record<string, boolean> {
      return {
        'font-weight-bolder': true,
        'text-primary': this.discountsApplied > 0 && this.isOpenbayPlusUser,
        'offer-pane-price': true
      };
    },

    totalPriceInDollars(): string {
      if (!this.offer) return '';

      if (!this.isOpenbayPlusUser && this.discountsApplied)
        return monetize(this.offer.totalBeforeTaxWithPlatformDiscounts.cents, 'cents');

      return monetize(this.offer.totalPriceInDollars, 'dollars');
    },

    isOpenbayPlusUser(): boolean {
      return this.$store.getters['user/hasActiveSubscription'];
    }
  },

  methods: {
    showDetailModal(): void {
      this.$store.dispatch('ui/showDialog', {
        title: `Offer Details`,
        contentComponent: {
          name: 'OfferDetails',
          props: {
            offer: this.offer,
            showHeaders: false,
            hideServices: true,
          }
        },
        cancelText: 'Close',
        hideSubmitButton: true,
      });
    }
  }
});
