
import assetMixin from '@/mixins/assetMixin';
import configMixin from '@/mixins/configMixin';
import { defineComponent } from 'vue';
import { kebabCase } from 'lodash';

export default defineComponent({
  name: 'FooterComponent',

  mixins: [assetMixin, configMixin],

  data() {
    return {
      logoError: false
    };
  },

  computed: {
    partnerSlug(): string | undefined {
      if (this.externalConfig.partner)
        return kebabCase(this.externalConfig.partner);
      return undefined;
    }
  }
  
});
