
import { defineComponent, PropType } from 'vue';
import { SubscriberOfferLineItem } from '@/types/Offer';
import LineItemPricing from './LineItemPricing.vue';

export default defineComponent({
  name: 'DetailsLineItem',

  components: { LineItemPricing },

  props: {
    lineItem: {
      type: Object as PropType<SubscriberOfferLineItem>,
      required: true
    }
  }
});
