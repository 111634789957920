
import { defineComponent } from 'vue';
import moment from 'moment';

//Components
import InlineSvg from 'vue-inline-svg';
import OfferSubtotalList from '@/components/Onramp/Offer/OfferSubtotalList.vue';
import OfferLineItemPricingList from '@/components/Onramp/Offer/LineItemPricingList.vue';
import LocationHours from '@/components/Onramp/Location/Hours.vue';

//Types
import { OfferFull } from '@/types/Offer';
import { monetize, isNullish } from '@/utilities';
import { ServiceRequest } from '@/types/ServiceRequest';
import assetMixin from '@/mixins/assetMixin';

export default defineComponent({
  name: 'AppointmentConfirmed',
  mixins: [assetMixin],
  components: {
    InlineSvg,
    OfferSubtotalList,
    OfferLineItemPricingList,
    LocationHours
  },
  props: {
    title: {
      type: String,
      default: 'Your booking is confirmed!'
    },

    subTitle: {
      type: String,
      default: 'What you need to know for your upcoming appointment'
    },

    displayFooter: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    offer(): OfferFull {
      return this.$store.getters['onramp/getOffer'];
    },

    serviceRequest(): ServiceRequest {
      return this.$store.getters['onramp/getServiceRequest'];
    },

    appointmentAt(): string | null {
      return isNullish(this.offer.appointmentAt)
        ? null
        : moment.parseZone(this.offer.appointmentAt).format('dddd MMMM D [at] h:mm a');
    },

    inModal(): boolean {
      return false;
    },

    invoiceStepHeading(): string {
      const record = 'service record';
      return `4. Your ${record} will be available in your Openbay account`;
    },

    isLyftUser(): boolean {
      return this.$store.getters['user/isLyftUser'];
    },

    locationType(): string {
      return this.offer.location.doesHouseCalls ? 'mechanic' : 'shop';
    },

    plusSubscriber(): boolean {
      return this.$store.getters['user/getSubscriptions'].length > 0;
    },

    paymentCodeCopy(): { title: string; description: string } {
      const step = this.isLyftUser ? '4' : '3';
      const marketplaceHeading = `${step}. When service is complete, present your Openbay code`;
      const subscriberHeading = marketplaceHeading + ' and pay the shop directly';
      const description = `Your Openbay code lets us know that you are satisfied with your service. When you provide it to the shop, Openbay considers your service complete and paid in full. If you have issues with your service, please contact <a href="mailto:memberservices@openbay.com">memberservices@openbay.com</a> or <a href="tel:+1-888-601-0399">(888) 601-0399</a>.`;

      return {
        title: subscriberHeading,
        description: description
      };
    },

    stepOne(): string {
      const defaultStepOne = `1. Bring your vehicle to the shop at your scheduled time`;
      const lyftStepOne = `1. Bring your Lyft vehicle to the shop at your scheduled time`;
      const mobileMechanic = `1. The mechanic will arrive at your service address at the scheduled appointment time`;
      const walkIns = `1. Bring your vehicle to the shop`;

      if (this.isLyftUser) return lyftStepOne;
      else if (this.offer.location.doesHouseCalls) return mobileMechanic;
      else if (this.offer.location.firstComeFirstServe) return walkIns;
      else return defaultStepOne;
    },

    totalAfterRewards(): string {
      return monetize(this.offer.totalAfterRewards, 'dollars');
    },
  },

  methods: {
    done(): void {
      ['onramp', 'user'].forEach((module) => {
        this.$store.commit(`${module}/reset`);
      });
      this.$store.dispatch('ui/pushView', {
        name: 'ChooseLocation'
      });
    },
  },

  created(): void {
    this.$store.commit('onramp/resetServices');
    this.$store.commit('onramp/setNotes', undefined);
  }
});
