
import { defineComponent, PropType } from 'vue';

// Libraries
import offerService from '@/services/offerService';

// Components
import LocationPartial from '@/components/Onramp/Location/Partial.vue';
import LocationHours from '@/components/Onramp/Location/Hours.vue';
import Spinner from '@/components/Shared/LoadingSpinner.vue';
import OfferDetails from '@/components/Onramp/Offer/Details.vue';

// Types
import { OfferFull } from '@/types/Offer';
import { LocationFull } from '@/types/ServiceProvider';
import { isNullish } from '@/utilities';

export default defineComponent({
  name: 'OfferFull',

  components: {
    LocationPartial,
    Spinner,
    OfferDetails,
    LocationHours,
  },

  props: {
    id: {
      type: [Number, String],
      required: false,
      default: ''
    },

    offerProp: {
      type: Object as PropType<OfferFull>,
      required: true,
    },

    tabIndex: {
      type: Number,
      default: 0
    },

    showForInvoice: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      offer: null as OfferFull | null,
      loading: true,
      tab: this.tabIndex
    };
  },

  computed: {
    location(): LocationFull | null {
      return !isNullish(this.offer) ? this.offer.location : null;
    },

    warrantyInfo(): string {
      return !isNullish(this.location) ? this.location.warrantyOverview : 'N/A';
    },

    otherServicesInfo(): string {
      return !isNullish(this.location) ? this.location.otherServices : 'N/A';
    },

    certificationInfo(): string {
      const { certifications, otherCertifications } = !isNullish(this.location)
        ? this.location
        : { certifications: [''], otherCertifications: '' };
      let info = `${certifications.join(', ')}`;
      if (otherCertifications) {
        info += `, ${otherCertifications}`;
      }
      return info;
    },

    offerTitle(): string {
      return this.showForInvoice ? 'Invoice' : 'Estimate';
    }
  },

  created(): void {
    if (this.id !== '') this.fetchOffer();
    else {
      this.offer = this.offerProp;
      this.loading = false;
    }
  },

  methods: {
    fetchOffer(): void {
      offerService.fetchOffer(this.id).then((offer: OfferFull) => {
        this.offer = offer;
        this.loading = false;
      });
    }
  }
});
