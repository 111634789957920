import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_required_input = _resolveComponent("required-input")!
  const _component_state_select = _resolveComponent("state-select")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_required_input, {
      modelValue: _ctx.address.addressLine1,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.address.addressLine1) = $event)),
      placeholder: "Address Line 1",
      name: "addressLine1",
      onNameUpdate: _ctx.updateAddress1,
      onNameValidUpdate: _ctx.updateAddress1Validity
    }, null, 8, ["modelValue", "onNameUpdate", "onNameValidUpdate"]),
    _createVNode(_component_required_input, {
      modelValue: _ctx.address.addressLine2,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.address.addressLine2) = $event)),
      placeholder: "Address Line 2",
      name: "address2",
      required: false,
      onNameUpdate: _ctx.updateAddress2
    }, null, 8, ["modelValue", "onNameUpdate"]),
    _createVNode(_component_required_input, {
      modelValue: _ctx.address.addressCity,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.address.addressCity) = $event)),
      placeholder: "City",
      name: "city",
      onNameUpdate: _ctx.updateCity,
      onNameValidUpdate: _ctx.updateCityValidity
    }, null, 8, ["modelValue", "onNameUpdate", "onNameValidUpdate"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_state_select, {
          id: "addressState",
          modelValue: _ctx.address.addressState,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.address.addressState) = $event)),
          name: "state",
          onStateChanged: _ctx.updateState
        }, null, 8, ["modelValue", "onStateChanged"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_required_input, {
          modelValue: _ctx.address.addressZipcode,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.address.addressZipcode) = $event)),
          placeholder: "Zipcode",
          name: "zipcode",
          onNameUpdate: _ctx.updateZipcode,
          onNameValidUpdate: _ctx.updateZipcodeValidity
        }, null, 8, ["modelValue", "onNameUpdate", "onNameValidUpdate"])
      ])
    ])
  ]))
}