import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "service-selector-interview" }
const _hoisted_2 = { class: "mb-4" }
const _hoisted_3 = { class: "list-group list-group-flush interview-answers" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "help-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_InlineSvg = _resolveComponent("InlineSvg")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.currentInterview.question), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentInterview.interviews, (interview, index) => {
        return (_openBlock(), _createElementBlock("button", {
          key: `interview-${index}`,
          ref_for: true,
          ref: "interviewAnswers",
          class: "list-group-item",
          onClick: ($event: any) => (_ctx.selectAnswer(interview))
        }, [
          _createTextVNode(_toDisplayString(interview.answer) + " ", 1),
          _createVNode(_component_icon, {
            icon: ['far', 'chevron-right'],
            size: "lg"
          })
        ], 8, _hoisted_4))
      }), 128))
    ]),
    (_ctx.currentInterview.tooltipLabel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.currentInterview.icon)
            ? (_openBlock(), _createBlock(_component_InlineSvg, {
                key: 0,
                src: _ctx.asset(require(`@/assets/icons/ic-${_ctx.currentInterview.icon}.svg`))
              }, null, 8, ["src"]))
            : _createCommentVNode("", true),
          _createElementVNode("h4", null, _toDisplayString(_ctx.currentInterview.tooltipLabel), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.currentInterview.tooltipBody), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}