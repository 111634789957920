import Vuex, { Store, StoreOptions } from 'vuex';
import VuexPersistence from 'vuex-persist';
import { FullState } from '@/types/store/FullState';
import { RootState } from '@/types/store/RootState';

import ui from './modules/ui';
import services from './modules/services';
import onramp from './modules/onramp';
import alerts from './modules/alerts';
import auth from './modules/auth';
import user from './modules/user';
import config from './modules/config';

const newRootState = (): RootState => ({
  version: '0.0.2'
});

const modules = {
  ui,
  services,
  onramp,
  alerts,
  auth,
  user,
  config
};

const vuexLocal = (): { plugin: (...args: any[]) => any } => {
  return new VuexPersistence({
    key: 'openbay_mini',
    storage: localStorage,
    modules: ['ui', 'services', 'onramp', 'auth', 'user', 'config']
  });
};

const newRootStoreOptions = (): StoreOptions<RootState> => ({
  modules,
  state() {
    return newRootState();
  },
  plugins: process.env.VUE_APP_ENABLE_PERSISTENT_STORAGE === 'true' ? [vuexLocal().plugin] : []
});

export const createStore = (): Store<FullState> => {
  const store = new Vuex.Store(newRootStoreOptions());

  return store as unknown as Store<FullState>;
};

const store = createStore();

export default store;
