
import { defineComponent, PropType } from 'vue';
import { monetize } from '@/utilities';
import { OfferLineItem, SubscriberOfferLineItem } from '@/types/Offer';

export default defineComponent({
  name: 'OfferLineItemPricingList',

  props: {
    offerLineItems: {
      type: Array as PropType<OfferLineItem[] & SubscriberOfferLineItem[]>,
      required: true
    }
  },

  computed: {
    plusSubscriber(): boolean {
      return this.$store.getters['user/hasActiveSubscription'];
    },

    isLyftUser(): boolean {
      return this.$store.getters['user/isLyftUser'];
    }
  },

  methods: {
    discountsApplied(lineItem: OfferLineItem & SubscriberOfferLineItem): number {
      return lineItem.totalDiscountCents || 0;
    },

    formattedReferencePrice(lineItem: OfferLineItem): string {
      return monetize(lineItem.referencePriceCents, 'cents');
    },

    formattedUnitPrice(lineItem: OfferLineItem): string {
      return monetize(lineItem.unitPriceCents, 'cents');
    },

    listPrice(lineItem: OfferLineItem & SubscriberOfferLineItem): string {
      return monetize(lineItem.extendedPricePreDiscountCents, 'cents');
    }
  }
});
