import { vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36508a1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "location-search" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = {
  key: 0,
  class: "input-group"
}
const _hoisted_4 = { class: "input-group-append" }
const _hoisted_5 = {
  key: 2,
  class: "form-text"
}
const _hoisted_6 = {
  key: 3,
  class: "search-results list-group list-group-flush"
}
const _hoisted_7 = {
  key: 4,
  class: "search-results list-group list-group-flush"
}
const _hoisted_8 = {
  key: 5,
  class: "search-results list-group list-group-flush"
}
const _hoisted_9 = ["onClick", "onKeydown"]
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.hasSubmitButton)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
              class: "form-control",
              placeholder: "City, State, or ZIP code",
              type: "search",
              onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.debounceSearch && _ctx.debounceSearch(...args))),
              onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.selectFirst()), ["prevent"]), ["enter"])),
              onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onInputFocus && _ctx.onInputFocus(...args))),
              onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onInputBlur && _ctx.onInputBlur(...args)))
            }, null, 544), [
              [_vModelText, _ctx.searchTerm]
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                class: "btn btn-primary",
                type: "button",
                onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.selectFirst()), ["prevent"]))
              }, _toDisplayString(_ctx.submitText), 1)
            ])
          ]))
        : _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 1,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.searchTerm) = $event)),
            class: "form-control",
            placeholder: "City, State, or ZIP code",
            type: "search",
            onKeyup: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.debounceSearch && _ctx.debounceSearch(...args))),
            onKeydown: _cache[8] || (_cache[8] = _withKeys(_withModifiers(($event: any) => (_ctx.selectFirst()), ["prevent"]), ["enter"]))
          }, null, 544)), [
            [_vModelText, _ctx.searchTerm]
          ]),
      (!_ctx.hideSubText)
        ? (_openBlock(), _createElementBlock("small", _hoisted_5, " Openbay needs a location in order to get you estimates from shops nearby. "))
        : _createCommentVNode("", true),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_spinner)
          ]))
        : (_ctx.suggestUseCurrentLocation)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("button", {
                type: "button",
                class: "list-group-item text-left btn-block",
                onClick: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.useCurrentLocation && _ctx.useCurrentLocation(...args)), ["prevent"])),
                onKeydown: _cache[10] || (_cache[10] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.useCurrentLocation && _ctx.useCurrentLocation(...args)), ["prevent"]), ["enter"]))
              }, [
                _createVNode(_component_icon, { icon: ['fal', 'map-marker-alt'] }),
                _createTextVNode(" Use current location ")
              ], 32)
            ]))
          : (_ctx.searchResults.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResults, (location, index) => {
                  return (_openBlock(), _createElementBlock("button", {
                    key: `${index}-${location.city}`,
                    type: "button",
                    class: "list-group-item text-left btn-block",
                    onClick: ($event: any) => (_ctx.selectArea(location)),
                    onKeydown: _withKeys(($event: any) => (_ctx.selectArea(location)), ["enter"])
                  }, [
                    _createElementVNode("span", {
                      innerHTML: _ctx.formatResult(location)
                    }, null, 8, _hoisted_10)
                  ], 40, _hoisted_9))
                }), 128))
              ]))
            : _createCommentVNode("", true)
    ])
  ]))
}