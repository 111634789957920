import { httpClient } from '@/api';
import { handleError, handleSuccess } from '@/services/helpers';
import { GenericDefinition, ResourceDataDocument } from '@/types/JsonSpec';
import { Area, CityAreas } from '@/types/resources/ZipcodeLocations';

export const STATES = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'Washington, DC': 'DC',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY'
} as { [key: string]: string };

export default {
  /**
   * Fetch states on the Openbay platform.
   *
   * @param withActiveShops - Boolean for whether only states with active shops should be fetched
   * @returns If successful, a promise containing an array of state abbreviations
   *          If not successful, a string error message.
   *
   */
  fetchStates(withActiveShops: boolean | undefined) {
    const params = withActiveShops ? new URLSearchParams([['with_active_shops', withActiveShops.toString()]]) : {};

    return httpClient
      .get<ResourceDataDocument<GenericDefinition<string[]>>>('/v3/areas/states', { params })
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  /**
   * Fetch cities in a provided state
   *
   * @param stateAbbreviation - Two letter abbreviation of state
   * @param withActiveShops - Boolean for whether only cities with active shops should be fetched
   * @returns If successful, a promise containing an array of City name strings.
   *          If not successful, a string error message.
   *
   */
  fetchCitiesInState(stateAbbreviation: string, withActiveShops: boolean | null) {
    const params = withActiveShops ? new URLSearchParams([['with_active_shops', withActiveShops.toString()]]) : {};

    return httpClient
      .get<ResourceDataDocument<GenericDefinition<string[]>>>(`/v3/areas/states/${stateAbbreviation}/cities`, {
        params
      })
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  /**
   * Fetch cities in a provided state
   *
   * @param input - string input of search term, can be zipcode, city name, state, etc.
   * @returns If successful, a promise containing an Areas array.
   *          If not successful, a string error message.
   *
   */
  searchAreas: (input: string): Promise<Area[]> =>
    httpClient
      .get('/v3/zipcode/search', { params: { term: input } })
      .then(({ data }) => data)
      .catch((error) => handleError(error)),

  fetchCityAreas: (state: string, city: string) =>
    httpClient
      .get<ResourceDataDocument<GenericDefinition<CityAreas>>>(`/v3/areas/states/${state}/cities/${city}`)
      .then((response) => handleSuccess(response))
      .catch((error) => handleError(error))
};
