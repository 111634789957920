import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LocationPane = _resolveComponent("LocationPane")!
  const _component_SchedulePane = _resolveComponent("SchedulePane")!
  const _component_OfferPane = _resolveComponent("OfferPane")!
  const _component_ServicesPane = _resolveComponent("ServicesPane")!
  const _component_VehiclePane = _resolveComponent("VehiclePane")!

  return (_openBlock(), _createElementBlock("aside", {
    class: _normalizeClass(["sidebar", {
      'mobile__in-view': _ctx.inView
    }]),
    role: "tablist"
  }, [
    false
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "btn btn-tertiary btn-cancel btn-block",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('show-cancel-modal')))
        }, " Cancel request "))
      : _createCommentVNode("", true),
    (_ctx.serviceRequestRequest.zipcode)
      ? (_openBlock(), _createBlock(_component_LocationPane, {
          key: 1,
          "current-step": _ctx.currentStep
        }, null, 8, ["current-step"]))
      : _createCommentVNode("", true),
    (_ctx.displaySchedulePane)
      ? (_openBlock(), _createBlock(_component_SchedulePane, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.displayOfferPane)
      ? (_openBlock(), _createBlock(_component_OfferPane, { key: 3 }))
      : _createCommentVNode("", true),
    (_ctx.services.length > 0)
      ? (_openBlock(), _createBlock(_component_ServicesPane, {
          key: 4,
          services: _ctx.serviceRequestRequest.services,
          currentStep: _ctx.currentStep
        }, null, 8, ["services", "currentStep"]))
      : _createCommentVNode("", true),
    (_ctx.selectedVehicle?.edmundsStyleId)
      ? (_openBlock(), _createBlock(_component_VehiclePane, {
          key: 5,
          currentStep: _ctx.currentStep
        }, null, 8, ["currentStep"]))
      : _createCommentVNode("", true)
  ], 2))
}