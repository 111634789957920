import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-566d1de3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card sidebar-card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "btn btn-block text-left" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("button", _hoisted_3, [
        _createVNode(_component_icon, {
          icon: ['fal', 'calendar-day'],
          size: "lg"
        }),
        _createTextVNode(" Book "),
        _createVNode(_component_icon, {
          class: "chevron",
          icon: ['fal', 'chevron-up']
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.appointment && _ctx.appointment.length > 0)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.appointment.length > 1)
              ? (_openBlock(), _createElementBlock("h5", _hoisted_5, "Appointment Preferences"))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appointment, (slot) => {
              return (_openBlock(), _createElementBlock("p", {
                key: slot.key,
                class: "time"
              }, _toDisplayString(slot.fullSlotTitle), 1))
            }), 128))
          ], 64))
        : (_openBlock(), _createElementBlock("p", _hoisted_6, "(choose a date and time)"))
    ])
  ]))
}