import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import authService from '@/services/authService';

import { AuthState } from '@/types/store/AuthState';
import { RootState } from '@/types/store/RootState';

const initialState = (): AuthState => ({
  token: '',
  destinationAfterAuth: ''
});

const state = initialState();

const getters: GetterTree<AuthState, RootState> = {
  getToken: (state) => state.token,
  getDestinationAfterAuth: (state) => state.destinationAfterAuth
};

const actions: ActionTree<AuthState, RootState> = {
  refreshToken({ commit }): Promise<void> {
    return authService.refreshToken().then((token) => {
      commit('setToken', token);
    });
  }
};

const mutations: MutationTree<AuthState> = {
  setToken(state, token: string) {
    state.token = token;
  },

  setDestinationAfterAuth(state, url: string) {
    state.destinationAfterAuth = url;
  },

  reset(state) {
    state = Object.assign(state, initialState());
  }
};

const AuthStore: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default AuthStore;
