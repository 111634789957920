
import { defineComponent } from 'vue';
import SearchServices from '@/components/Onramp/SearchServices.vue';
import configMixin from '@/mixins/configMixin';
import { WidgetStrings } from '@/types/PartnerConfig';

export default defineComponent({
  name: 'ChooseLocation',
  mixins: [configMixin],
  computed: {
    strings(): WidgetStrings['chooseLocation'] {
      return this.externalConfig.strings.chooseLocation;
    }
  },
  components: {
    SearchServices
  }
});
