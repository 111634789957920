
import{ defineComponent } from 'vue';

//Types
import { Alert } from '@/types/store/Alert';

export default defineComponent({
  name: 'GlobalAlerts',

  computed: {
    alerts(): Alert[] {
      return this.$store.getters['alerts/getAlerts'];
    }
  },

  methods: {
    removeAlert(alert: Alert): void {
      this.$store.dispatch('alerts/removeAlert', alert);
    },

    icon(type: string): string {
      switch (type) {
        case 'success':
          return 'check-circle';

        case 'danger':
          return 'exclamation-triangle';

        case 'warning':
          return 'exclamation-circle';

        case 'info':
          return 'info-circle';

        default:
          return 'info-circle';
      }
    }
  }
});
