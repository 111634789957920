import { DEFAULT_CONFIG_STRINGS } from '@/constants/strings';
import { ExternalConfig } from '@/types/ExternalConfig';
import { WidgetStrings } from '@/types/PartnerConfig';
import { ConfigState } from '@/types/store/ConfigState';
import { RootState } from '@/types/store/RootState';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

const initialState = (): ConfigState => ({
  baseUrl: '',
  externalConfig: {
    widgetType: 'all'
  } as ExternalConfig
});

const state = initialState();

const getters: GetterTree<ConfigState, RootState> = {
  getBaseUrl: (state) => state.baseUrl,
  getExternalConfig: (state) => state.externalConfig
};

const actions: ActionTree<ConfigState, RootState> = {};

const mutations: MutationTree<ConfigState> = {
  setBaseUrl(state, baseUrl: string) {
    state.baseUrl = baseUrl;
  },
  setExternalConfig(state, config: ExternalConfig) {
    state.externalConfig = config;
    state.externalConfig.strings = Object.assign({}, DEFAULT_CONFIG_STRINGS, config.strings);
  },
  reset(state) {
    state = Object.assign(state, initialState());
  }
};

const ConfigStore: Module<ConfigState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default ConfigStore;
