import axios, { AxiosError, AxiosResponse } from 'axios';
import { uniq } from 'underscore';

import { processResponse } from '@/services/jsonSpecService';
//Types
import { DocumentDefinition, ResourceDataDocument, ResourcesDataDocument } from '@/types/JsonSpec';
import { toParagraph } from '@/utilities';

export const DEFAULT_ERROR_MESSAGE = 'There was an error. Please try again later.';

/**
 * Extract the most useful error message from any thrown error (whether or not
 * the given value is an instance of `Error`), or return the given default
 * error message.
 *
 * @param {any} error The value from which to extract an error message
 * @param {string} defaultMessage The message to return if extraction fails
 * @return {string}
 */
export function messageFromError(error: any, defaultMessage = DEFAULT_ERROR_MESSAGE): string {
  // If it's null, undefined, false, 0, or '', return the default message
  if (!error) return defaultMessage;

  // If it's already a string, return it
  if (typeof error === 'string') return error.trim();

  // If it's an array, call messageFromError on each and concatenate the unique
  // returned messages if there are any; otherwise, return the default message
  if (Array.isArray(error)) {
    const messages = uniq(error.map((item) => messageFromError(item, '')).filter((item) => item));
    if (messages.length === 0) return defaultMessage;
    if (messages.length === 1) return messages[0];
    return toParagraph(messages);
  }

  // If it's not an object (i.e., it's a non-string primitive or something else
  // that's weird) return the default message
  if (typeof error !== 'object') return defaultMessage;

  // If it's an AxiosError (i.e., it's a network error), extract the message
  // from the response body if there is one; otherwise, return the default
  // message
  if (axios.isAxiosError(error)) return messageFromError(error.response?.data, defaultMessage);

  // If the object is empty, return the default message
  if (Object.keys(error).length === 0) return defaultMessage;

  // Check the following keys (in order) to see if the error object carries a
  // relevant error message under one of them
  for (const key of ['message', 'errors', 'error', 'data', 'base']) {
    const message = messageFromError(error[key], '');
    if (message) return message;
  }

  // Couldn't get an error message from the object; return the default message
  return defaultMessage;
}

/**
 * General helper method for handling errors returned by API services.
 * @param {AxiosError} error An error returned by a service HTTP request.
 * @return {never}
 */
export function handleError(error: AxiosError, defaultMessage = DEFAULT_ERROR_MESSAGE): never {
  throw messageFromError(error, defaultMessage);
}

/**
 * General helper method for handling successful responses for API services.
 * @param {AxiosResponse<ResourceDataDocument<DocumentDefinition>} response
 *         A response from a JsonSpec API endpoint.
 * @return {!DataDocument<DocumentDefinition>}
 */
export function handleSuccess<Def extends DocumentDefinition>(
  response: AxiosResponse<ResourceDataDocument<Def>>
): Def['Attributes'];
export function handleSuccess<Def extends DocumentDefinition>(
  response: AxiosResponse<ResourcesDataDocument<Def>>
): Def['Attributes'][];
export function handleSuccess<Def extends DocumentDefinition>(response: AxiosResponse<any>): any;
export function handleSuccess<Def extends DocumentDefinition>(
  response: AxiosResponse<any>
): Def['Attributes'] | Def['Attributes'][] {
  return processResponse(response.data);
}
