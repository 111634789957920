import { httpClientMicro } from '@/api-microservices';
import store from '@/store/store';
import { Program } from '@/types/microservices/Program';
import { ProgramPlanInfo } from '@/types/microservices/ProgramPlanInfo';

export default class CoverageService {
  routes = {
    voCoverage: (programName: string, zipcode: string) =>
      `${this.environmentPath()}/v1/lookup/vo-coverage/${programName}/${zipcode}`,
    voCoverageByProgramPlan: (programPlanId: string, zipcode: string) =>
      `${this.environmentPath()}/v1/lookup/vo-coverage-by-program-plan/${programPlanId}/${zipcode}`,
    validateProgramAndPlan: (programNameSlug: string, planNameSlug: string) =>
      `${this.environmentPath()}/v1/lookup/program-validation/${programNameSlug}/${planNameSlug}`,
    programPlanBySlugs: (programNameSlug: string, planNameSlug: string) =>
      `${this.environmentPath()}/v1/lookup/program-and-plan/${programNameSlug}/${planNameSlug}`,
    myPrograms: () => `${this.environmentPath()}/v1/my-coverage/programs`
  };

  environmentPath() {
    return `${store.getters['config/getBaseUrl']}/services/coverage`;
  }

  // Apparently this endpoint returns data if it's actually covered, and throws
  // an error if it's not. Worth verifying if this is actually the case in the
  // future, but that's how we're using it.
  public isZipcodeCoveredByProgramPlan(programPlanId: string, zipcode: string): Promise<boolean> {
    return httpClientMicro
      .get<{ message: string }>(this.routes.voCoverageByProgramPlan(programPlanId, zipcode))
      .then(() => true)
      .catch(() => false);
  }

  public checkVoCoverage(programName: string, zipcode: string): Promise<{ message: string }> {
    return httpClientMicro
      .get<{ message: string }>(this.routes.voCoverage(programName, zipcode))
      .then(({ data }) => data)
      .catch((error) => {
        throw error;
      });
  }

  public validateProgramAndPlan(programNameSlug: string, planNameSlug: string): Promise<boolean> {
    return httpClientMicro
      .get<{ message: string }>(this.routes.validateProgramAndPlan(programNameSlug, planNameSlug))
      .then(() => true)
      .catch(() => false);
  }

  public getProgramPlan(programNameSlug: string, planNameSlug: string): Promise<ProgramPlanInfo> {
    return httpClientMicro
      .get(this.routes.programPlanBySlugs(programNameSlug, planNameSlug))
      .then(({ data }) => data)
      .catch((error) => {
        throw error;
      });
  }

  public getMyPrograms(): Promise<Program[]> {
    return httpClientMicro
      .get(this.routes.myPrograms())
      .then(({ data }) => data)
      .catch((error) => {
        throw error;
      });
  }
}
