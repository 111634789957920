
import { PropType, defineComponent } from 'vue';
import zipcodeService from '@/services/zipcodeService';

//Types
import { ZipcodeLocation } from '@/types/ZipcodeLocation';
import areaService from '@/services/areaService';
import { RouteMetaConfig } from '@/routes/types';

export default defineComponent({
  name: 'LocationPane',

  components: {},

  props: {
    currentStep: {
      type: String as PropType<RouteMetaConfig['onrampStep']>,
      required: true
    }
  },

  data() {
    return {
      isEditing: false,
      loading: true,
      searchResults: [] as ZipcodeLocation[],
      error: ''
    };
  },

  computed: {
    creatingServiceRequest(): boolean {
      if (!this.currentStep) return true;
      return ['services', 'vehicle', 'contact'].includes(this.currentStep);
    },

    displayEdit(): boolean {
      return this.currentStep !== 'offer' && this.currentStep !== 'schedule' && this.currentStep !== 'confirm';
    },

    locationDisplay(): string | null {
      const location = this.$store.getters['onramp/getLocation'];
      return location.zipcode === '' ? null : `${location.city}, ${location.state} ${location.zipcode}`;
    },

    zipcode(): string {
      return this.$store.getters['onramp/getZipcode'];
    }
  },

  mounted(): void {
    this.searchLocations(this.zipcode).then((locations) => {
      if (locations.length) this.selectArea(locations[0]);
    });
  },

  methods: {
    searchLocations(zipcode: string): Promise<ZipcodeLocation[]> {
      return areaService
        .searchAreas(zipcode)
        .catch((error) => {
          this.error = error;
          return [];
        })
        .finally(() => (this.loading = false));
    },

    editLocation(): void {
      this.isEditing = true;
    },

    stopEditingLocation(): void {
      this.isEditing = false;
    },

    selectArea(location: ZipcodeLocation): void {
      this.searchResults = [];

      if (!this.creatingServiceRequest) {
        this.$store.commit('onramp/setLocation', location);
        return;
      }

      this.checkCoverage(location.zipcode).then(() => {
        this.$store.dispatch('user/setLocation', location);
        this.$store.commit('onramp/setLocation', location);
      });
    },

    checkCoverage(zipcode: string): Promise<void> {
      return zipcodeService
        .isInTargetMarket(zipcode)
        .then((inMarket) => {
          this.$emit('is-in-market', { inMarket, zipcode });
          if (inMarket) this.stopEditingLocation();
        })
        .catch((error) => {
          this.error = error;
        });
    }
  }
});
