import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "list-group list-group-flush booking-service-list" }
const _hoisted_2 = { class: "booking-service-list-pricing-container" }
const _hoisted_3 = {
  key: 0,
  class: "booking-service-list-pricing"
}
const _hoisted_4 = {
  key: 0,
  class: "booking-service-list-pricing"
}
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.offerLineItems, (lineItem) => {
      return (_openBlock(), _createElementBlock("li", {
        key: `line-item-pricing-list-${lineItem.id}`,
        class: "list-group-item d-flex justify-content-between"
      }, [
        _createElementVNode("span", null, _toDisplayString(lineItem.description) + ":", 1),
        _createElementVNode("span", _hoisted_2, [
          (_ctx.isLyftUser)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.discountsApplied(lineItem) > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.formattedReferencePrice(lineItem)) + " x " + _toDisplayString(lineItem.quantity), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("span", {
                  class: _normalizeClass({ 'text-lyft': _ctx.discountsApplied(lineItem) > 0 })
                }, [
                  _createTextVNode(_toDisplayString(_ctx.formattedUnitPrice(lineItem)) + " ", 1),
                  (lineItem.variant !== 'flat rate')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(" x " + _toDisplayString(lineItem.quantity), 1)
                      ], 64))
                    : _createCommentVNode("", true)
                ], 2)
              ], 64))
            : (_ctx.plusSubscriber)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_ctx.discountsApplied(lineItem) > 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                        _createTextVNode(_toDisplayString(_ctx.listPrice(lineItem)) + " ", 1),
                        (lineItem.variant !== 'flat rate')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createTextVNode(" x " + _toDisplayString(lineItem.quantity), 1)
                            ], 64))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", {
                    class: _normalizeClass({ 'text-primary': _ctx.discountsApplied(lineItem) > 0 })
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.formattedUnitPrice(lineItem)) + " ", 1),
                    (lineItem.variant !== 'flat rate')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(" x " + _toDisplayString(lineItem.quantity), 1)
                        ], 64))
                      : _createCommentVNode("", true)
                  ], 2)
                ], 64))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.formattedUnitPrice(lineItem)) + " x " + _toDisplayString(lineItem.quantity), 1))
        ])
      ]))
    }), 128))
  ]))
}