
import { defineComponent } from 'vue';
import SearchList from '@/components/Onramp/SearchList.vue';
import { isNullish } from '@/utilities';
import { validZipcode } from '@/concerns/validator';
import { ServiceSelectionInterview } from '@/types/resources/ServiceSelectionInterview';
import { OwnedVehicle } from '@/types/resources/OwnedVehicles';
import { Service } from '@/types/Services';
import { ServiceSelectionCategory } from '@/types/resources/ServiceSelectionCategories';
import { ZipcodeLocation } from '@/types/ZipcodeLocation';
import zipcodeService from '@/services/zipcodeService';

export default defineComponent({
  name: 'SearchServices',
  components: {
    SearchList,
  },

  props: {
    buttonText: {
      type: String,
      required: false,
      default: 'GO'
    },
  },

  data() {
    return {
      loading: false,
      loadingTargetMarket: false,
      zipcode: this.$store.getters['user/getZipcode'] || '',
      disableTooltip: true,
      serviceForOnRamp: {},
      routeForOnramp: '',
      defaultDataHover: false,
      outOfMarketZipcode: '',
      zipCodeRequired: false,
    };
  },

  computed: {
    homepage(): boolean {
      return true;
    },

    services(): Service[] {
      return this.$store.getters['services/getServices'];
    },

    searchData(): Service[] | OwnedVehicle[] {
      return this.services;
    },

    defaultData(): OwnedVehicle[] | ServiceSelectionInterview[] {
      return this.popularServices.interviews;
    },

    fuseSearchKeys(): any[] {
      const serviceKeys = [
        {
          name: 'name',
          weight: 0.7
        },
        {
          name: 'category_name',
          weight: 0.3
        }
      ];
      return serviceKeys;
    },

    defaultDataLabel(): string {
      return 'Popular Services';
    },

    placeholder(): string {
      return 'Search services'
    },

    popularServices(): ServiceSelectionCategory {
      return this.$store.getters['services/getPopularServiceInterviews'] || {};
    },

    sortedServices(): ServiceSelectionInterview[] {
      let toSort = [...this.popularServices.interviews] || [];
      return toSort.sort((a, b) => a.weight - b.weight);
    },

    validZipcode(): boolean {
      const validZip = this.zipcode.match(/[0-9]{5}/g);
      return this.zipcode !== '' && validZip !== null;
    },

    globalUserZipcode(): string {
      return this.$store.getters['user/getZipcode'] || '';
    },

    ownedVehicles(): OwnedVehicle[] {
      return this.$store.getters['user/getVehicles'];
    }
  },

  watch: {
    globalUserZipcode(newVal: string, oldVal: string): void {
      if (newVal !== oldVal) this.zipcode = newVal;
    },

    popularServices: {
      immediate: true,
      handler: function () {
        if (isNullish(this.popularServices)) {
          this.loading = true;
        } else {
          this.loading = false;
        }
      }
    }
  },

  created(): void {
    if (this.services.length <= 1) {
      this.loading = true;
      this.$store.dispatch('services/fetchServices').then(() => {
        this.loading = false;
      });
    }
  },

  methods: {
    setServiceForOnramp(service: Service): void {
      const serviceId = service.id.toString();
      this.routeForOnramp = 'ServiceNotes';
      this.serviceForOnRamp = { serviceId };
    },

    selectDefaultOption(selectedOption: ServiceSelectionInterview): void {
      this.routeForOnramp = 'ServiceInterview';
      this.serviceForOnRamp = {
        interview: selectedOption
      };
    },

    clearSelectedService(): void {
      this.serviceForOnRamp = {};
      this.routeForOnramp = '';
    },

    goToAllServices(): void {
      if (this.zipcode?.length) {
        if (!validZipcode(this.zipcode)) {
          this.$store.dispatch('ui/pushView', {
            name: 'LocationSelector',
          });

          return;
        }

        this.checkZipcodeCoverage().then(() => {
          this.$store.dispatch('ui/pushView', {
            name: 'ServiceSelector',
            params: { zipcode: this.zipcode }
          });
        });
      } else {
        this.$store.dispatch('ui/pushView', {
          name: 'LocationSelector',
        });
      }
    },

    updateUserLocation(zipcode: string): Promise<void> {
      return this.$store.dispatch('user/fetchLocation', zipcode).then((location: ZipcodeLocation) => {
        this.$store.commit('onramp/setLocation', location);
      });
    },

    verifyZipcodeIsInMarket(zipcode: string): Promise<void> {
      if (!zipcode) return Promise.reject(new Error('Zipcode cannot be blank'));

      return zipcodeService.isInTargetMarket(zipcode).then((inMarket) => {
        if (!inMarket) throw new Error('Zipcode is OOTM');
      });
    },

    checkZipcodeCoverage(): Promise<void> {
      this.loadingTargetMarket = true;
      this.outOfMarketZipcode = '';

      return this.verifyZipcodeIsInMarket(this.zipcode)
        .then(() => this.updateUserLocation(this.zipcode))
        .catch((error) => {
          this.outOfMarketZipcode = this.zipcode;
          this.zipcode = '';
          throw error;
        })
        .finally(() => (this.loadingTargetMarket = false));
    },

    getNextRoute() {
      if (!validZipcode(this.zipcode)) {
        return {
          name: 'LocationSelector',
          params: {
            ...this.serviceForOnRamp,
          }
        };
      } else if (this.routeForOnramp !== '') {
        return {
          name: this.routeForOnramp,
          params: {
            ...this.serviceForOnRamp,
            zipcode: this.zipcode
          }
        }
      }

      return {
        name: 'ServiceSelector',
        params: { zipcode: this.zipcode }
      }
    },

    startOnramp(): void {
      const nextRoute = this.getNextRoute();

      if (!validZipcode(this.zipcode)) {
        this.zipCodeRequired = true;
        return;
      }
      
      this.checkZipcodeCoverage()
        .then(() => {
          this.$store.commit('onramp/setZipcode', this.zipcode);
          this.$store.dispatch('ui/pushView', nextRoute);
        })
        .catch(() => {
          this.$store.commit('onramp/setZipcode', this.outOfMarketZipcode);
          this.$store.dispatch('ui/pushView', 'ZeroCoverage');
        });
    },

    checkGeoLocationPermission(): void {
      if (navigator.permissions) {
        navigator.permissions.query({ name: 'geolocation' }).then(() => {
          // Asking for location regardless of permissions, as it triggers permissions request
          // if the current status is 'pending'
          this.askForLocation();
        });
      }
    },

    askForLocation(): void {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            var lat = position.coords.latitude;
            var long = position.coords.longitude;
            // eslint-disable-next-line
            var point = new google.maps.LatLng(lat, long);
            // eslint-disable-next-line
            new google.maps.Geocoder().geocode({ location: point }, (res, _status) => {
              const zipcode = res[0].formatted_address.match(/[0-9]{5}/g);
              if (zipcode) {
                if (this.zipcode === '') {
                  this.zipcode = zipcode[0];
                  this.$store.dispatch('user/fetchLocation', this.zipcode);
                }
              } else {
                this.zipcode = this.globalUserZipcode;
              }
            });
          },
        );
      }
    }
  }
});
