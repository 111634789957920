export default function (baseUrl: string): void {
  // ugh.
  const css = `
    @font-face {
      font-display: swap;
      font-family: "Whitney";
      font-weight: 700;
      src: url(${baseUrl}/fonts/Whitney/Whitney-Bold.otf) format("opentype");
    }
    
    @font-face {
      font-display: swap;
      font-family: "Whitney";
      font-weight: 600;
      src: url(${baseUrl}/fonts/Whitney/Whitney-Semibold.otf) format("opentype");
    }
    
    @font-face {
      font-display: swap;
      font-family: "Whitney";
      font-weight: 500;
      src: url(${baseUrl}/fonts/Whitney/Whitney-Medium.otf) format("opentype");
    }
    
    @font-face {
      font-display: swap;
      font-family: "Whitney";
      font-weight: 400;
      src: url(${baseUrl}/fonts/Whitney/Whitney-Book.otf) format("opentype");
    }
    
    @font-face {
      font-display: swap;
      font-family: "Whitney";
      font-weight: 300;
      src: url(${baseUrl}/fonts/Whitney/Whitney-Light.otf) format("opentype");
    }
    
    @font-face {
      font-display: swap;
      font-family: "Whitney";
      font-weight: 200;
      src: url(${baseUrl}/fonts/Whitney/Whitney-Light.otf) format("opentype");
    }
    
    @font-face {
      font-display: swap;
      font-family: "Metropolis";
      font-weight: 800;
      src: url(${baseUrl}/fonts/Metropolis/Metropolis-Black.otf) format("opentype");
    }
    
    @font-face {
      font-display: swap;
      font-family: "Metropolis";
      font-weight: 700;
      src: url(${baseUrl}/fonts/Metropolis/Metropolis-Bold.otf) format("opentype");
    }
    
    @font-face {
      font-display: swap;
      font-family: "Metropolis";
      font-weight: 600;
      src: url(${baseUrl}/fonts/Metropolis/Metropolis-SemiBold.otf) format("opentype");
    }
    
    @font-face {
      font-display: swap;
      font-family: "Metropolis";
      font-weight: 500;
      src: url(${baseUrl}/fonts/Metropolis/Metropolis-Medium.otf) format("opentype");
    }
  `;
  const style = document.createElement('style');
  style.textContent = css;
  document.head.appendChild(style);
}
