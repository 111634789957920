
import { PropType, defineComponent } from 'vue';
import { ReviewSummary } from '@/types/ServiceProvider';

export default defineComponent({
  name: 'ReviewSummary',

  props: {
    reviewSummary: {
      type: Object as PropType<ReviewSummary>,
      required: true
    }
  },

  computed: {
    ratingStars(): number {
      return Math.round(this.reviewSummary.rating);
    }
  }
});
