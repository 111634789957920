
import { LocationFull } from '@/types/ServiceProvider';
import moment from 'moment';
import { PropType, defineComponent } from 'vue';
import { toFileName } from '@/utilities';

export default defineComponent({
  name: 'LocationAmenities',

  props: {
    location: {
      type: Object as PropType<LocationFull>,
      required: true
    }
  },

  computed: {
    schedulingText(): string {
      if (this.location.firstComeFirstServe) return 'Vehicles are serviced in the order they arrive.';

      const appointmentBuffer = this.location.appointmentBuffer;
      const num = appointmentBuffer.substring(0, 1);
      const suffix = num === '1' ? '' : 's';

      if (this.isSameDayScheduling) {
        return 'Availability Today';
      } else if (appointmentBuffer === '1d') {
        return 'Availability Tomorrow';
      } else if (appointmentBuffer.endsWith('d')) {
        const nextAvailability = moment().add(num, 'days').format('dddd');
        return `Availability ${nextAvailability}`;
      } else if (appointmentBuffer.endsWith('w')) {
        return `Scheduling ${num} week${suffix} out`;
      } else {
        return `Scheduling ${num} month${suffix} out`;
      }
    },
    amenities(): string[] {
      return this.location.amenities.map((a) => a.toString());
    },
    isSameDayScheduling(): boolean {
      return ['nb', '6h'].includes(this.location.appointmentBuffer);
    }
  },

  methods: {
    setIcon(amenity: string): string[] {
      let amenityFormatted = toFileName(amenity);
      switch (amenityFormatted) {
        case 'early_drop_off':
          return ['fal', 'sun'];

        case 'loaner_car':
          return ['fal', 'car-side'];

        case 'free_wifi':
          return ['fal', 'wifi'];

        case 'shuttle_service':
          return ['fal', 'bus'];

        case 'state_inspection':
          return ['fal', 'clipboard-check'];

        default:
          return ['fal', 'car-side'];
      }
    }
  }
});
