import { AxiosError, AxiosResponse } from 'axios';

import { httpClient } from '@/api';
import { handleError, handleSuccess, messageFromError } from '@/services/helpers';
import { ResourceDataDocument } from '@/types/JsonSpec';
import { CompletePartialUserRequest, CreateUserRequest, EmployeeDefinition, User, UserDefinition } from '@/types/User';

type UpdateUser = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
  contactViaTextMessage: boolean;
};

type DeprecatedWarpResponse = {
  success: string;
  sessionToken: string;
};

type ForgotPasswordResponse = {
  message: 'Email sent with password reset instructions.';
};

type SignOutResponse = {
  status: 'OK';
};

export default {
  createAccount(user: CreateUserRequest) {
    return httpClient
      .post<ResourceDataDocument<UserDefinition>>('/v3/users', {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        password: user.password,
        phoneNumber: user.phoneNumber,
        promoCode: user.promoCode,
        marketingSource: user.marketingSource,
        campaignSource: user.campaignSource,
        acquisitionSource: user.acquisitionSource,
        contactViaTextMessage: user.contactViaTextMessage,
        deliveryDriverCompanies: user.deliveryDriverCompanies,
        isRetailUser: user.isRetailUser
      })
      .then((response) => {
        const user = handleSuccess(response);
        return user;
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  completePartialUser(user: CompletePartialUserRequest) {
    return httpClient
      .put<ResourceDataDocument<UserDefinition>>('/v3/users/profile', {
        password: user.password,
        phoneNumber: user.phoneNumber,
        contactViaTextMessage: user.contactViaTextMessage
      })
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw messageFromError(error);
      });
  },

  updateUser(user: UpdateUser) {
    return httpClient
      .put<ResourceDataDocument<UserDefinition>>('/v3/users/profile', {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        password: user.password,
        phoneNumber: user.phoneNumber,
        contactViaTextMessage: user.contactViaTextMessage
      })
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw messageFromError(error);
      });
  },

  resetPassword(enteredEmail: String) {
    return httpClient
      .post<ForgotPasswordResponse>('/v3/users/forgot_password', {
        email: enteredEmail
      })
      .then((response) => {
        return response.data;
      })
      .catch((error: AxiosError) => {
        throw handleError(error);
      });
  },

  /**
   * Sign in to Openbay.
   *
   * @param email - The email of the user signing in
   * @param password - The password associated with the e-mail/user.
   * @returns If successful, a promise containing the user or service station employee profile information.
   *          If the user is an admin, they are redirect to the admin URL.
   *          If the user is an employee, they are redirect to the SP dashboard.
   *
   *          If not successful, a string error message.
   *
   */
  signIn(email: String, password: String): Promise<User | false> {
    return httpClient
      .post<ResourceDataDocument<UserDefinition> | ResourceDataDocument<EmployeeDefinition>>('/v3/sessions', {
        email: email,
        password: password
      })
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  signOut() {
    return httpClient
      .delete<SignOutResponse>('/v3/sessions')
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  refreshToken(): Promise<string> {
    return httpClient
      .get('/v3/sessions/refresh')
      .then(({ data }) => data.data.attributes.data)
      .catch((error) => {
        throw handleError(error);
      });
  }
};
