
import { OfferFull } from '@/types/Offer';
import { monetize } from '@/utilities';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'OfferSubtotalList',

  props: {
    offer: {
      type: Object as PropType<OfferFull>,
      required: true
    }
  },

  computed: {
    displayLyftDiscount(): boolean {
      return this.isLyftUser && this.offer.voLyftDiscount > 0;
    },

    isLyftUser(): boolean {
      return this.$store.getters['user/isLyftUser'];
    },

    maxRewards(): string {
      return monetize(this.offer.maxRewards, 'dollars');
    },

    partnerDiscount(): string {
      return monetize(this.offer.voLyftDiscount, 'dollars');
    },

    plusSubscriber(): boolean {
      return this.$store.getters['user/hasActiveSubscription'];
    },

    subscriberDiscount(): string {
      return monetize(this.totalDiscountCents, 'cents');
    },

    subTotal(): string {
      return monetize(this.offer.totalBeforeTaxWithPlatformDiscounts.cents, 'cents');
    },

    subTotalLabel(): string {
      return this.totalDiscountCents ? 'List Price' : 'Subtotal';
    },

    totalDiscountCents(): number {
      return this.offer.totalDiscountCents || 0;
    },

    totalTaxes(): string {
      const taxes =
        typeof this.offer.totalTaxPrice === 'number' ? this.offer.totalTaxPrice : this.offer.totalTaxPrice.cents;

      return monetize(taxes, 'cents');
    }
  }
});
