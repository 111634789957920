
import { defineComponent, PropType } from 'vue';
import moment, { Moment } from 'moment';

import { Navigation } from 'swiper/modules';

//components
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { isNullish } from '@/utilities';

//Types
import { AppointmentSlot } from '@/types/ServiceRequest';

import 'swiper/css';


export default defineComponent({
  name: 'SingleTimeSelector',

  components: {
    Swiper,
    SwiperSlide
  },

  props: {
    days: {
      type: Array as PropType<Moment[]>,
      required: true
    },

    slots: {
      type: Array as PropType<AppointmentSlot[]>,
      required: false,
      default: () => []
    },

    totalRepairTimeHours: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      selectedDay: null as null | Moment,
      selectedDayTimes: [] as AppointmentSlot[],
      selectedSlot: null as AppointmentSlot | null,
      swiperOption: {
        slidesPerView: 2,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    };
  },

  computed: {
    pickupTime(): string {
      let repairTime = parseInt(this.totalRepairTimeHours);
      return !isNullish(this.selectedTime) ? this.selectedTime.add(repairTime, 'hours').format('h:mm A') : '';
    },

    selectedTime(): Moment | null {
      return !isNullish(this.selectedSlot) ? moment(this.selectedSlot.proposedTime) : null;
    }
  },

  methods: {
    activeDay(day: Moment): boolean {
      return !isNullish(this.selectedDay) ? this.selectedDay.format('ddd DD-MMM') === day.format('ddd DD-MMM') : false;
    },

    activeTime(dayTime: string): boolean {
      return !isNullish(this.selectedSlot) ? this.selectedSlot.slotTitle === dayTime : false;
    },

    selectDay(day: Moment): void {
      this.selectedDayTimes = [];
      this.selectedDay = day;
      this.createHoursArray(day);
    },

    selectSlot(slot: AppointmentSlot): void {
      this.selectedSlot = slot;
      this.$emit('time-set', [slot]);
    },

    createHoursArray(day: Moment): void {
      this.selectedDayTimes = this.slots.filter((slot) => {
        return slot.day === day.format('YYYY-MM-DD');
      });
    },

    finishAppointmentSelection(): void {
      this.$emit('appt-preference-chosen', this.selectedDay!.toString());
    }
  },

  setup() {
    return {
      modules: [Navigation],
    }
  }
});
