
import { Dialog } from '@/types/store/Dialog';
import { PropType, defineComponent } from 'vue';
import dialogComponents from '@/routes/dialog';

export default defineComponent({
  name: 'DialogPage',

  components: {
    ...dialogComponents
  },

  data() {
    return {};
  },

  props: {
    dialog: {
      type: Object as PropType<Dialog>,
      required: true
    },
  },

  computed: {
    modalClasses(): string[] {
      return ['modal', 'fade', 'show'];
    },

    cancelText(): string {
      return this.dialog?.cancelText || 'Cancel';
    },

    submitText(): string {
      return this.dialog?.submitText || 'Save';
    },
  },

  methods: {
    close(): void {
      if(this.dialog.onClose) this.dialog.onClose();
      this.$store.dispatch('ui/hideDialog');
    },

    ok(): void {
      if(this.dialog.onOk) this.dialog.onOk();
      this.$store.dispatch('ui/hideDialog');
    },
  },

  mounted(): void {

  }
});
