import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, toDisplayString as _toDisplayString, vShow as _vShow, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d579cc0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-group global-search has-icon" }
const _hoisted_2 = { class: "global-search__group" }
const _hoisted_3 = { class: "global-search__search" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "global-search-menu rounded-bottom shadow" }
const _hoisted_6 = { class: "search-results list-group list-group-flush" }
const _hoisted_7 = { style: {"overflow-y":"auto"} }
const _hoisted_8 = ["value", "onClick"]
const _hoisted_9 = {
  ref: "SearchResultsContainer",
  style: {"overflow-y":"auto"}
}
const _hoisted_10 = ["value", "onClick"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "category-display" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["position-relative", {
      active: _ctx.searchTerm.length >= 0,
      'hero-banner-search': _ctx.homepage
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_icon, { icon: ['fal', 'search'] }),
          _withDirectives(_createElementVNode("input", {
            ref: "SearchListInput",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
            type: "search",
            class: "form-control",
            placeholder: _ctx.placeholder,
            onKeyup: [
              _cache[1] || (_cache[1] = ($event: any) => (_ctx.performSearch())),
              _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.selectTopResult()), ["enter"]))
            ],
            onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleActiveState())),
            onFocus: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleDefaultData()))
          }, null, 40, _hoisted_4), [
            [_vModelText, _ctx.searchTerm]
          ])
        ]),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createElementVNode("div", { class: "global-search-menu__title" }, _toDisplayString(_ctx.defaultDataLabel), 513), [
          [_vShow, _ctx.defaultDataLabel !== '' && _ctx.showDefaultData]
        ]),
        _renderSlot(_ctx.$slots, "first-item", {}, undefined, true),
        _withDirectives(_createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchDefaultData, (item, index) => {
            return (_openBlock(), _createElementBlock("button", {
              key: `item-${index}`,
              value: item.id,
              class: "list-group-item list-group-item-action",
              onClick: ($event: any) => (_ctx.selectDefaultData($event, item)),
              onMouseover: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onDefaultData = true)),
              onMouseout: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onDefaultData = false))
            }, _toDisplayString(item.answer), 41, _hoisted_8))
          }), 128))
        ], 512), [
          [_vShow, (_ctx.showDefaultData || _ctx.showInitialData) && !_ctx.showData]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResults, (item, index) => {
            return (_openBlock(), _createElementBlock("button", {
              ref_for: true,
              ref: "searchResults",
              key: `item-${index}`,
              value: item.id,
              class: "list-group-item list-group-item-action",
              onClick: ($event: any) => (_ctx.selectItem(item))
            }, [
              _createElementVNode("span", {
                innerHTML: _ctx.formatSearchResult(item)
              }, null, 8, _hoisted_11),
              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.formatCategoryName(item)), 1)
            ], 8, _hoisted_10))
          }), 128))
        ], 512), [
          [_vShow, _ctx.searchTerm.length >= 2 && _ctx.showItems]
        ]),
        ((_ctx.searchTerm.length >= 1 && _ctx.showItems) || _ctx.showDefaultData)
          ? _renderSlot(_ctx.$slots, "lastItem", { key: 0 }, undefined, true)
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}