
import { defineComponent } from 'vue';

// Component
import OfferPartial from './Partial.vue';
import Placeholder from '@/components/Onramp/Offer/Placeholder.vue';
import Spinner from '@/components/Shared/LoadingSpinner.vue';
import OfferMap from './OfferMap.vue';
import NoOffersAvailable from './NoOffersAvailable.vue';

//Types
import { OfferFull } from '@/types/Offer';
import { ServiceRequest } from '@/types/ServiceRequest';
import { isNullish, camelKeys, bound } from '@/utilities';
import { processResponse } from '@/services/jsonSpecService';
import { OfferResource } from '@/types/Offer';

import { User } from '@/types/User';
import { View } from '@/types/store/View';
import store from '@/store/store';
import configMixin from '@/mixins/configMixin';

const MAX_OFFER_POLLING_INTERVAL_SECONDS = 30;

let offerPollingTimeout = 0;

/** When mounted, this component:
 * 1. Checks if there are service requests in the store. Users typically will
 *    have SRs unless arriving at this URL directly.
 *        - if not, it retrieves the user's service requests
 * 2. If there is an SR in the store that has a matching ID with the ID from
 *    the URL/router and it has associated offers, the component displays those
 *    offers.
 * 3. If there isn't a matching SR, or there is a matching SR but with no offers,
 *    it subscribes to 1 action cable channel for the SR's offers
 * 4. Once it receives any offers, it stops displaying the loading spinner.
 */
export default defineComponent({
  name: 'OfferSelector',

  components: {
    OfferPartial,
    Placeholder,
    OfferMap,
    NoOffersAvailable,
    Spinner,
  },

  mixins: [
    configMixin,
  ],

  data() {
    return {
      isZeroOffer: false,
      timeToReceiveOffer: 0,
      offerTimerIntervalId: 0,
      selectedSort: 'rating',
      showMap: false,
      sortButtons: [
        // Doesn't apply to NuAdmin / Cost Reduction
        // {
        //   text: 'Availability',
        //   value: 'availability'
        // },
        {
          text: 'Rating',
          value: 'rating'
        },
        {
          text: 'Distance',
          value: 'distance'
        },
        {
          text: 'Price',
          value: 'price'
        }
      ]
    };
  },

  computed: {
    serviceRequest(): ServiceRequest {
      return this.$store.getters['onramp/getServiceRequest'];
    },

    currentView(): View {
      return this.$store.getters['ui/getCurrentView'];
    },

    loading(): boolean {
      return this.offers.length === 0;
    },

    serviceRequestId(): number {
      return this.serviceRequest.id;
    },

    serviceRequestUid(): string {
      return this.currentView.params.serviceRequestUid;
    },

    autoquotable(): boolean {
      return this.serviceRequest.autoquotable;
    },

    offers(): OfferFull[] {
      if (!isNullish(this.serviceRequest) && !isNullish(this.serviceRequest.offers)) {
        return this.serviceRequest.offers as OfferFull[]; // expired SRs have an array of null, but don't use this component
      } else {
        return [];
      }
    },

    user(): User {
      return this.$store.getters['user/getUserProfile'];
    },

    offersSorted(): OfferFull[] {
      const sorted = [...this.offers];
      if (sorted.length === 0) return [];

      switch (this.selectedSort) {
        case 'distance':
          return sorted.sort((a, b) => a.distance - b.distance);
        case 'price':
          return sorted.sort((a, b) => a.totalPriceInDollars - b.totalPriceInDollars);
        case 'rating':
          return sorted.sort((a, b) => a.location.reviewSummary.rating - b.location.reviewSummary.rating).reverse();
        case 'availability':
          return sorted.sort(this.availabilitySort);
        default:
          // Default is rating sort
          return sorted.sort((a, b) => a.location.reviewSummary.rating - b.location.reviewSummary.rating).reverse();
      }
    },

    notificationText(): string {
      let notifiers = '';
      if (this.user.contactViaTextMessage) {
        notifiers = `${this.user.phoneNumber} and ${this.user.email}`;
      } else {
        notifiers = this.user.email;
      }
      return notifiers;
    }
  },

  created(): void {
    if (this.offers.length === 0) {
      // We have no offers; we'll schedule polling to start immediately.
      this.pollForOffers();
    } else {
      // We already have offers; we'll schedule polling to start a while from
      // now instead of immediately.
      this.pollForOffers(MAX_OFFER_POLLING_INTERVAL_SECONDS * 1000);
    }

    // If no offers received within 30 seconds of initial creation, do one last API fetch.
    if (this.offers.length === 0) {
      setTimeout(() => {
        this.fetchOffers().then(() => {
          if (this.offers.length === 0 && this.autoquotable === true) this.isZeroOffer = true;
        });
      }, 30000);
    }

    this.initData();
  },

  channels: {
    ServiceRequestChannel: {
      connected() {},
      rejected() {},
      received(data: any) {
        var processed = camelKeys(data);
        var offer = processResponse(processed as OfferResource);
        store.commit('onramp/addOrUpdateOfferOnServiceRequest', offer);
      },
      disconnected() {},
    }
  },

  mounted(): void {
    this.$cable.subscribe({
      channel: "ServiceRequestChannel",
      id: this.currentView.params.serviceRequestUid
    });
  },

  unmounted(): void {
    this.showMap = false;
    this.$emit('show-map');
    clearInterval(this.offerTimerIntervalId);
    this.stopPollingForOffers();
  },

  deactivated(): void {
    this.showMap = false;
    this.$emit('show-map');
    clearInterval(this.offerTimerIntervalId);
    this.stopPollingForOffers();
  },

  methods: {
    trackOfferReceived(): void {
      clearInterval(this.offerTimerIntervalId);
    },

    stopPollingForOffers(): void {
      clearTimeout(offerPollingTimeout);
      this.$cable.unsubscribe("ServiceRequestChannel");
    },

    pollForOffers(msFromNow = 0, startedTimestamp?: number): void {
      clearTimeout(offerPollingTimeout);

      offerPollingTimeout = setTimeout(() => {
        // FIXME: hack to prevent polling after the component is unmounted.
        // for some reason clearing the timeout in unmounted/destroyed isn't working.
        if (!this.serviceRequest?.id) return;

        this.fetchOffers().then(() => {
          // If `startedAtTimestamp` is `undefined`, then it's starting now.
          startedTimestamp ??= new Date().getTime();
          const nowTimestamp = new Date().getTime();
          const msSinceStarted = nowTimestamp - startedTimestamp;

          // If it's been longer than 30 seconds (or whatever the max offer
          // polling interval is if it's not 30 seconds anymore), and no offers
          // have come back yet, and it should be autoquotable, then we'll set
          // `this.isZeroOffer = true` so that we can display a notice and
          // potentially collect a notification phone number from the user.
          const beenLongEnoughWithoutOffers =
            msSinceStarted >= MAX_OFFER_POLLING_INTERVAL_SECONDS * 1000 &&
            this.offers.length === 0 &&
            this.autoquotable === true;

          if (beenLongEnoughWithoutOffers) this.isZeroOffer = true;

          // If we still haven't gotten any offers yet, we'll check for offers
          // again in twice the amount of time we delayed for this one, up to
          // the maximum (30 seconds, at time of writing), and for a minimum of
          // one full second. If we have gotten offers, then we'll just use the
          // maximum, because we're not in a rush (just want to catch updates).
          const maxDurationMs = MAX_OFFER_POLLING_INTERVAL_SECONDS * 1000;
          const minDurationMs = 1000;
          const nextDurationMs = this.offers.length
            ? maxDurationMs
            : bound(msFromNow * 2, minDurationMs, maxDurationMs);

          this.pollForOffers(nextDurationMs, startedTimestamp);
        });
      }, msFromNow);
    },

    toggleMap(value: boolean): void {
      this.showMap = value;
      this.$emit('show-map', this.showMap);
    },

    initData(): void {
      this.fetchOffers();
      // Variable used for tracking time to receive an offer for analytics.
      this.offerTimerIntervalId = window.setInterval(() => {
        this.timeToReceiveOffer++;
      }, 1000);
    },

    showLocationDetails(/* offer: OfferFull */): void {},

    fetchOffers(): Promise<void> {
      return this.$store.dispatch('onramp/fetchOffers', this.serviceRequestId);
    },

    availabilitySort(offerA: OfferFull, offerB: OfferFull): number {
      const ordering: string[] = ['fcfs', 'nb', '6h', '1d', '2d', '3d', '4d', '5d', '6d', '1w', '2w', '1m'];

      const buffers = [offerA.location, offerB.location].map((loc) =>
        loc.firstComeFirstServe ? 'fcfs' : loc.appointmentBuffer
      );

      return ordering.indexOf(buffers[0]) - ordering.indexOf(buffers[1]);
    }
  }
});
