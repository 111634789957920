
import { defineComponent } from 'vue';

//Types
import { ServiceSelectionCategory } from '@/types/resources/ServiceSelectionCategories';
import { ServiceSelectionInterview } from '@/types/resources/ServiceSelectionInterview';
import { isNullish } from '@/utilities';
import assetMixin from '@/mixins/assetMixin';

export default defineComponent({
  name: 'PopularServices',

  mixins: [assetMixin],

  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
  },

  computed: {
    popularServices(): ServiceSelectionCategory {
      return this.$store.getters['services/getPopularServiceInterviews'];
    },

    sortedServices(): ServiceSelectionInterview[] {
      if (isNullish(this.popularServices.interviews)) return [];
      let toSort = [...this.popularServices.interviews];
      return toSort.sort((a, b) => a.weight - b.weight);
    }
  },

  methods: {
    selectServiceCategory(interview: ServiceSelectionInterview): void {
      this.$emit('start-interview', interview);
    }
  }
});
