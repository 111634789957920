
import { defineComponent, PropType } from 'vue';
import VehiclePane from './Vehicle/VehiclePane.vue';
import ServicesPane from './Services/ServicesPane.vue';
import LocationPane from './Location/LocationPane.vue';
import SchedulePane from './Appointment/SchedulePane.vue';
import OfferPane from './Offer/OfferPane.vue';
import { CartService, ServiceRequestParameters } from '@/types/ServiceRequest';
import { RouteMetaConfig } from '@/routes/types';
import { OwnedVehicle } from '@/types/resources/OwnedVehicles';

export default defineComponent({
  name: 'OnrampCart',

  components: {
    LocationPane,
    ServicesPane,
    VehiclePane,
    OfferPane,
    SchedulePane,
  },

  props: {
    currentStep: {
      type: String as PropType<RouteMetaConfig['onrampStep']>,
      required: true
    },

    inView: {
      type: Boolean,
      required: true,
    }
  },

  data() {
    return {};
  },

  computed: {
    displayOfferPane(): boolean {
      return this.currentStep === 'schedule' || this.currentStep === 'confirm';
    },

    displaySchedulePane(): boolean {
      return this.currentStep === 'schedule' || this.currentStep === 'confirm';
    },

    loadingServiceRequest(): boolean {
      return this.$store.getters['onramp/getLoadingServiceRequest'];
    },

    nextStepButtonText(): string {
      return `I'm done adding services`;
    },

    serviceRequestRequest(): ServiceRequestParameters {
      return this.$store.getters['onramp/getServiceRequestRequest'];
    },

    services(): CartService[] {
      return this.serviceRequestRequest?.services || [];
    },

    selectedVehicle(): OwnedVehicle {
      return this.$store.getters['onramp/getVehicleToCreate'] || this.$store.getters['onramp/getOwnedVehicle'];
    },
  },

  methods: {}
});
