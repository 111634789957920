import { httpClient } from '@/api';
import { handleError, handleSuccess } from '@/services/helpers';
import { ResourceDataDocument } from '@/types/JsonSpec';
import { OfferDefinition } from '@/types/Offer';

export default {
  fetchOffer(id: string | number) {
    return httpClient
      .get<ResourceDataDocument<OfferDefinition>>(`v3/users/offers/${id}`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  updateOfferTaxes(id: string | number) {
    return httpClient
      .patch<ResourceDataDocument<OfferDefinition>>(`v3/users/offers/${id}/taxes`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  }
};
