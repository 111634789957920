
import { PropType, defineComponent } from 'vue';

//Types
import { CartService as CartServiceType, IntervalService, MaintenanceInterval } from '@/types/ServiceRequest';
import CartService from '@/components/Onramp/Services/CartService.vue';
import { isNullish } from '@/utilities';
import { View } from '@/types/store/View';
import { RouteMetaConfig } from '@/routes/types';

type Actions = 'delete' | 'details';

export default defineComponent({
  name: 'ServicesPane',

  components: {
    CartService,
  },

  props: {
    currentStep: {
      type: String as PropType<RouteMetaConfig['onrampStep']>,
      required: true
    }
  },

  data() {
    return {
      inFocusType: '' as 'interval' | 'service' | '',
      modalAction: null as null | Actions,
      serviceInFocus: null as null | CartServiceType
    };
  },

  computed: {
    inspectionServices(): IntervalService[] {
      if (isNullish(this.maintenanceInterval)) return [];
      return this.maintenanceInterval.services.filter(
        (service) => service.inspection && !service.serviceName.includes('Miles Inspection Service (Perform)')
      );
    },

    intervalServices(): IntervalService[] {
      if (isNullish(this.maintenanceInterval)) return [];
      return this.maintenanceInterval.services.filter((service) => !service.inspection);
    },

    maintenanceInterval(): MaintenanceInterval | undefined {
      return this.$store.getters['onramp/getServiceRequestRequest'].maintenanceInterval;
    },

    modalSubmitText(): string {
      return this.modalAction === 'delete' ? `Yes, remove this service` : ``;
    },

    modalTitle(): string {
      return this.modalAction === 'delete'
        ? 'Are you sure you want to remove this service?'
        : `${this.maintenanceInterval?.mileage.toLocaleString()} mile services`;
    },

    modalCancelText(): string {
      return this.modalAction === 'delete' ? `No, keep this service` : `Close`;
    },

    services(): CartServiceType[] {
      return this.$store.getters['onramp/getServices'];
    },

    currentView(): View {
      return this.$store.getters['ui/getCurrentView'];
    }
  },

  methods: {
    confirmRemoval(service: CartServiceType): void {
      this.removeService(service);
    },

    openModal(): void {
      if (!this.serviceInFocus) return;

      this.$store.dispatch('ui/showDialog', {
        title: this.modalTitle,
        contentText: `${this.serviceInFocus!.name} will be removed from your estimate`,
        cancelText: this.modalCancelText,
        submitText: this.modalSubmitText,
        onOk: () => this.confirmRemoval(this.serviceInFocus!),
      });
    },

    removeService(service: CartServiceType): void {
      this.$store.commit('onramp/removeService', service.serviceId);
      this.$store.commit('onramp/resetServiceToAdd');
      this.resetFocus();

      if (this.currentView.name !== 'ServiceSelector') this.$store.dispatch('ui/pushView', { name: 'ServiceSelector' });
    },

    removeInterval(): void {
      this.$store.commit('onramp/removeInterval');
      this.resetFocus();

      if (this.currentView.name !== 'ServiceSelector') this.$store.dispatch('ui/pushView', { name: 'ServiceSelector' });
    },

    resetFocus(): void {
      this.modalAction = null;
      this.inFocusType = '';
    },

    createIntervalCartService(interval: MaintenanceInterval): CartServiceType {
      return {
        autoquotable: false,
        serviceId: interval.mileage,
        name: `${interval.mileage.toLocaleString()} mile services`,
        notes: ''
      };
    },

    startRemoveInterval(interval: MaintenanceInterval): void {
      this.modalAction = 'delete';
      this.inFocusType = 'interval';
      this.serviceInFocus = this.createIntervalCartService(interval);

      this.openModal();
    },

    startRemoveService(service: CartServiceType): void {
      this.modalAction = 'delete';
      this.inFocusType = 'service';
      this.serviceInFocus = service;

      this.openModal();
    },

    viewDetails(interval: MaintenanceInterval): void {
      this.modalAction = 'details';
      this.serviceInFocus = this.createIntervalCartService(interval);
      this.openModal();
    }
  }
});
