/* eslint-disable no-param-reassign */
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import { Alert } from '@/types/store/Alert';
import { RootState } from '@/types/store/RootState';
import { AlertsState } from '@/types/store/AlertsState';

const initialState = (): AlertsState => ({
  alerts: []
});

const state = initialState();

const getters: GetterTree<AlertsState, RootState> = {
  getAlerts: (state) => state.alerts
};

const actions: ActionTree<AlertsState, RootState> = {
  addAlert({ commit }: any, alert: Alert) {
    commit('addAlert', alert);
    if (alert.timedDismiss === true) {
      const timeout = alert.dismissCountdown ? alert.dismissCountdown : 5000;
      setTimeout(function () {
        commit('removeAlert', alert);
      }, timeout);
    }
  },
  removeAlert({ commit }: any, alert: Alert) {
    commit('removeAlert', alert);
  },
  reset({ commit }: any) {
    commit('reset');
  }
};

const mutations: MutationTree<AlertsState> = {
  addAlert(state, alert: Alert) {
    state.alerts.push(alert);
  },
  removeAlert(state, alertToRemove: Alert) {
    const index = state.alerts.findIndex((alert) => alert.message === alertToRemove.message);
    state.alerts.splice(index, 1);
  },
  reset(state) {
    Object.assign(state, initialState());
  }
};

const AlertsStore: Module<AlertsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default AlertsStore;
