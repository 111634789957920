
import adminMixin from '@/mixins/adminMixin';
import configMixin from '@/mixins/configMixin';
import serviceMixin from '@/mixins/serviceMixin';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'ServiceNotes',

  props: {
    viewParams: {
      type: Object as PropType<{serviceId: number}>,
      required: false,
      default: () => null
    },
  },

  data() {
    return {
      additionalNote: '',
      loading: false,
    };
  },

  mixins: [
    serviceMixin,
    configMixin,
    adminMixin,
  ],

  mounted() {
    if (this.viewParams?.serviceId) {
      let cartService = this.createCartService(this.viewParams?.serviceId, []);
      this.$store.commit('onramp/setServiceToAdd', cartService);
      this.$store.commit('onramp/addOrUpdateService', cartService);
    }
  },

  methods: {
    goToServicesStep(): void {
      this.addAdditionalNotes();
      this.$store.dispatch('ui/pushView', {
        name: 'ServiceSelector'
      });
    },

    addAdditionalNotes(): void {
      if (this.additionalNote !== '') {
        this.setServiceRequestNote();
        this.additionalNote = '';
      }
      this.$store.commit('onramp/resetServiceToAdd');
    },

    setServiceRequestNote(): void {
      this.$store.commit('onramp/setNotes', this.additionalNote);
    },

    goToNextStep(): void {
      this.addAdditionalNotes();

      if (this.isAdminFlow && this.isVehicleProvided) {
        this.loading = true;
        this.createAdminServiceRequest().then(() => (this.loading = false));
      } else {
        this.$store.dispatch('ui/pushView', {
          name: 'VehicleSelector'
        });
      }
    }
  }
})
