import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "form-label-group form-group" }
const _hoisted_2 = {
  key: 0,
  for: "email"
}
const _hoisted_3 = ["id", "disabled"]
const _hoisted_4 = {
  key: 1,
  for: "email"
}
const _hoisted_5 = { class: "error-style" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.floatingLabels)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, "Email Address"))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      id: _ctx.id,
      ref: "email-input",
      class: "form-control",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
      type: "email",
      autocomplete: "email",
      placeholder: "Email Address",
      required: "",
      disabled: _ctx.disabled,
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.validateEmail && _ctx.validateEmail(...args))),
      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.emailUpdated && _ctx.emailUpdated(...args)))
    }, null, 40, _hoisted_3), [
      [_vModelText, _ctx.email]
    ]),
    (_ctx.floatingLabels)
      ? (_openBlock(), _createElementBlock("label", _hoisted_4, "Email Address"))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("p", _hoisted_5, "Email is invalid.", 512), [
      [_vShow, _ctx.showEmailError]
    ])
  ]))
}