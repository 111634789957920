import { AxiosError } from 'axios';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import authService from '@/services/authService';
import CoverageService from '@/services/microservices/coverageService';
import SubscriptionService from '@/services/microservices/subscriptionService';
import ownedVehicleService from '@/services/ownedVehicleService';
import userService from '@/services/userService';
import zipcodeService from '@/services/zipcodeService';
import { Program } from '@/types/microservices/Program';
import { Subscription } from '@/types/microservices/Subscriptions';
import { SubscriptionStatus } from '@/types/microservices/SubscriptionStatus';
import { OwnedVehicle } from '@/types/resources/OwnedVehicles';
import { PartialVehicle } from '@/types/resources/PartialVehicle';
import { CompletePartialUserRequest, CreateUserRequest, User } from '@/types/User';
import { VehicleRequest } from '@/types/Vehicle';
import { ZipcodeLocation } from '@/types/ZipcodeLocation';
import { isNullish } from '@/utilities';

import { UserState } from '@/types/store/UserState';
import { RootState } from '@/types/store/RootState';

const initialState = (): UserState => ({
  hasServiceRequests: false,
  userProfile: {
    createdAt: '',
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    contactViaTextMessage: false,
    status: '',
    openbayId: '',
    uid: '',
    unreadCount: 0,
    usedRewards: '0.00',
    rewards: '0.00',
    earnedRewards: '0.00',
    isLyftDriver: false,
    hasPassword: false
  } as User,
  ownedVehicles: [] as OwnedVehicle[],
  partialVehicles: [] as PartialVehicle[],
  loggedIn: false,
  location: {
    city: '',
    state: '',
    zipcode: '',
    term: ''
  } as ZipcodeLocation,
  subscriptions: [],
  programs: [],
  subscriptionsLoaded: false,
  activeSubscription: undefined
});

const state = initialState();

const getters: GetterTree<UserState, RootState> = {
  getUserProfile: (state) => state.userProfile,

  getEmail: (state) => state.userProfile.email,

  getDisplayName: (state) => {
    let firstName = state.userProfile.firstName;
    let lastName = state.userProfile.lastName;
    const hasVehicle = state.ownedVehicles.length > 0;
    const emptyFirstName =
      isNullish(firstName) ||
      firstName === '';
    const emptyLastName = isNullish(lastName) || lastName === '';
    const vehicleName = hasVehicle ? state.ownedVehicles[0].make : 'Vehicle';
    firstName = emptyFirstName ? vehicleName : firstName;
    lastName = emptyLastName ? 'Owner' : lastName;

    return `${firstName} ${lastName}`.trim();
  },

  getHasServiceRequests: (state) => state.hasServiceRequests,

  getOwnedVehicle: (state) => (id: number) => {
    const vehicle = state.ownedVehicles.find((vehicle) => vehicle.id === id);
    return vehicle;
  },

  getOwnedVehicles: (state) => state.ownedVehicles,

  getPartialVehicles: (state) => state.partialVehicles,

  getSubscriptions: (state) => state.subscriptions,

  getPrograms: (state) => state.programs,

  getSubscriptionsLoaded: (state) => state.subscriptionsLoaded,

  getActiveSubscription: (state) => state.activeSubscription,

  getVehicleName: (state) => (id: number) => {
    const vehicle = state.ownedVehicles.find((vehicle) => vehicle.id === id);
    return !isNullish(vehicle) ? `${vehicle.year} ${vehicle.make} ${vehicle.model}` : '';
  },

  hasContactMethod: (state) =>
    (state.userProfile.email !== '') ||
    (state.userProfile.phoneNumber !== '' && state.userProfile.contactViaTextMessage),

  hasSubscription: (state) => state.subscriptions.length > 0,

  hasActiveSubscription: (state) => {
    if (state.subscriptions.length == 0) return false;

    return state.subscriptions.some((subscription) => {
      const subIsActive = [SubscriptionStatus.active, SubscriptionStatus.canceled].includes(subscription.status);
      return subIsActive;
    });
  },

  isLoggedIn: (state) => state.loggedIn,

  // MPC-2515 openbay plus trumps lyft
  isLyftUser: (state) => state.userProfile.isLyftDriver && !state.subscriptions.length,

  getAvailableRewards: (state) => state.userProfile.rewards,

  getUsedRewards: (state) => state.userProfile.usedRewards,

  getVehicles: (state) => state.ownedVehicles,

  getVehicle: (state) => (id: number) => {
    return state.ownedVehicles.find((ownedVehicle) => {
      return ownedVehicle.id == id;
    });
  },

  getNotificationMethod: (state) => {
    if (
      state.userProfile.contactViaTextMessage === true &&
      state.userProfile.phoneNumber !== '' &&
      state.userProfile.email !== ''
    ) {
      return 'both';
    } else if (
      state.userProfile.contactViaTextMessage === false &&
      state.userProfile.email !== ''
    ) {
      return 'email';
    } else {
      return 'none'; // This can happen if they are a Facebook user who has rejected sharing their e-mail. There is no way to contact them outside of messaging.
    }
  },

  getZipcode: (state) => state.location.zipcode,

  getLocation: (state) => state.location
};

const actions: ActionTree<UserState, RootState> = {
  setUserProfile({ commit }, userProfile: User): void {
    commit('setUserProfile', userProfile);
  },

  resetUnreadCount({ commit }): void {
    commit('resetUnreadCount');
  },

  setUnreadCount({ commit }, unreadCount: number): void {
    commit('setUnreadCount', unreadCount);
  },

  createUser({ commit }, userRequest: CreateUserRequest): Promise<string> {
    return authService
      .createAccount(userRequest)
      .then((userProfile: User) => {
        commit('setUserProfile', userProfile);
        return 'Success';
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  },

  completePartialUser({ commit }, partialUser: CompletePartialUserRequest): Promise<string> {
    return authService
      .completePartialUser(partialUser)
      .then((userProfile: User) => {
        commit('setUserProfile', userProfile);
        return 'Success';
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  },

  createOwnedVehicle({ commit }, vehicleRequest: VehicleRequest): Promise<string> {
    return userService
      .addOwnedVehicle(vehicleRequest)
      .then((ownedVehicle: OwnedVehicle) => {
        commit('addOwnedVehicle', ownedVehicle);
        return 'Successfully added vehicle.';
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  },

  setOffersOnServiceRequest({ commit }, payload): void {
    commit('setOffersOnServiceRequest', payload);
  },

  updateOwnedVehicle({ commit }, ownedVehicle: Partial<OwnedVehicle>) {
    return ownedVehicleService
      .updateOwnedVehicle(ownedVehicle)
      .then((ownedVehicle: OwnedVehicle) => {
        commit('updateOwnedVehicle', ownedVehicle);
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  },

  updateProfile({ commit }, userProfile): Promise<User> {
    return userService
      .updateUserProfile(userProfile)
      .then((user) => {
        commit('setUserProfile', user);
        return user;
      })
      .catch((error) => {
        commit('resetUser');
        throw error;
      });
  },

  fetchProfile({ commit }): Promise<User> {
    return userService
      .retrieveUserProfile()
      .then((response: User) => {
        commit('setUserProfile', response);
        return response;
      })
      .catch((error: string) => {
        commit('resetUser');
        throw error;
      });
  },

  fetchSubscriptions({ commit }): Promise<Subscription[]> {
    return new SubscriptionService().getVOSubscriptions().then((subscriptions: Subscription[]) => {
      commit('setSubscriptions', subscriptions);
      return subscriptions;
    });
  },
  fetchPrograms({ commit }): Promise<Program[]> {
    return new CoverageService().getMyPrograms().then((programs) => {
      commit('setPrograms', programs);
      return programs;
    });
  },
  fetchPartialVehicles({ commit }): Promise<string> {
    return new SubscriptionService()
      .getPartialVehicles()
      .then((partialVehicles: PartialVehicle[]) => {
        commit('setPartialVehicles', partialVehicles);
        return 'Successfully fetched partial vehicles';
      })
      .catch((error: AxiosError) => {
        throw error.message;
      });
  },
  fetchVehicles({ commit }): Promise<string> {
    return userService
      .fetchVehicles()
      .then((ownedVehiclesResponse) => {
        commit('setOwnedVehicles', ownedVehiclesResponse);
        return 'Successfully fetched owned vehicles';
      })
      .catch((error: AxiosError) => {
        throw error.message;
      });
  },

  logout({ commit }) {
    commit('resetUser');
  },

  setZipcode({ commit }, zipcode: string): void {
    commit('setZipcode', zipcode);
  },

  fetchLocation({ commit }, zipcode: string): Promise<ZipcodeLocation> {
    return zipcodeService
      .searchByZipcode(zipcode)
      .then((locations) => {
        commit('setLocation', locations[0]);
        return locations[0];
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  },

  setLocation({ commit }, location: ZipcodeLocation): void {
    commit('setLocation', location);
  },
};

const mutations: MutationTree<UserState> = {
  resetUnreadCount(state) {
    state.userProfile.unreadCount = 0;
  },

  setHasServiceRequest(state, value: boolean) {
    state.hasServiceRequests = value;
  },

  setUnreadCount(state, unreadCount) {
    state.userProfile.unreadCount = unreadCount;
  },

  addOwnedVehicle(state, ownedVehicle: OwnedVehicle) {
    state.ownedVehicles.push(ownedVehicle);
  },

  setUserProfile(state, userProfile: User) {
    state.userProfile = userProfile;
    state.loggedIn = true;
  },

  setOwnedVehicles(state, ownedVehicles: OwnedVehicle[]) {
    state.ownedVehicles = ownedVehicles;
  },

  setPartialVehicles(state, partialVehicles: PartialVehicle[]) {
    state.partialVehicles = partialVehicles;
  },

  resetUser(state) {
    Object.assign(state, initialState());
  },

  setSubscriptions(state, subscriptions: Subscription[]) {
    state.subscriptionsLoaded = true;
    state.subscriptions = subscriptions;
  },

  setPrograms(state, programs: Program[]) {
    state.programs = programs;
  },

  setSubscriptionsLoaded(state, value: boolean) {
    state.subscriptionsLoaded = value;
  },

  setActiveSubscription(state, subscription: Subscription) {
    state.activeSubscription = subscription;
  },

  setZipcode(state, zipcode: string) {
    state.location.zipcode = zipcode;
  },

  setLocation(state, location: ZipcodeLocation) {
    state.location = location;
  },

  updateOwnedVehicle(state, ownedVehicle: OwnedVehicle) {
    const vehicleIndex = state.ownedVehicles.findIndex((vehicle) => vehicle.id === ownedVehicle.id);

    if (vehicleIndex > -1) {
      state.ownedVehicles[vehicleIndex] = ownedVehicle;
    } else {
      state.ownedVehicles.push(ownedVehicle);
    }
  },

  reset(state) {
    state = Object.assign(state, initialState());
  }
};

const UserStore: Module<UserState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default UserStore;
