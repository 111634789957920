
import { defineComponent } from 'vue';

const UsaStates = require('usa-states').UsaStates;

export default defineComponent({
  name: 'StateSelect',

  props: {
    default: {
      type: String,
      default: ''
    },

    id: {
      type: String,
      default: 'addressState'
    },

    options: {
      type: Object,
      default: () => ({})
    },

    required: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selected: ''
    };
  },

  computed: {
    statesArray(): Array<string> {
      var usStates = new UsaStates();
      return usStates.arrayOf('abbreviations');
    }
  },

  watch: {
    default: function () {
      this.selected = this.default;
    }
  },

  mounted(): void {
    if (this.default) {
      this.selected = this.default;
    }
  },

  methods: {
    stateChanged(state: any) {
      this.$emit('state-changed', state);
    }
  }
});
