
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AlertPane',

  props: {
    variant: {
      type: String,
      default: 'success',
    },

    show: {
      type: Boolean,
      default: false,
    },
  },
});
