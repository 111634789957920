export enum WidgetType {
  Admin = 'admin',
  Onramp = 'onramp',
  AppointmentOnly = 'appointment_only',
  All = 'all'
}

export interface ColorPalette {
  primary: string;
  secondary: string;
  tertiary: string;
  danger: string;
}

export interface WidgetStrings {
  findRepairFacility: {
    heading: string;
    subheading: string;
    button: string;
  };
  chooseLocation: {
    heading: string;
    subheading: string;
    button: string;
  };
  createAccount: {
    heading: string;
    subheading: string;
    button: string;
  };
}

export interface PartnerConfig {
  partner: string;
  colorPalette: ColorPalette;
  strings: WidgetStrings;
  widgetType: WidgetType;
}
