
import { defineComponent } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { uniqueId } from 'underscore';

library.add(faEye);
library.add(faEyeSlash);

export default defineComponent({
  name: 'PasswordInput',

  components: {
    'font-awesome-icon': FontAwesomeIcon,
  },

  model: {
    prop: 'password',
    event: 'input'
  },

  props: {
    initialPassword: {
      type: String,
      default: ''
    },

    autocomplete: {
      type: String,
      default: 'on'
    },

    placeholder: {
      type: String,
      default: 'Password'
    },

    hasFocus: {
      type: Boolean,
      default: false
    },

    validateImmediately: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showPassword: false,
      password: this.initialPassword,
      passwordValid: false,
      passwordValidated: false
    };
  },

  computed: {
    eyeIcon(): string {
      if (this.showPassword) {
        return 'eye';
      }
      return 'eye-slash';
    },

    id(): string {
      return uniqueId('password');
    },

    passwordType(): string {
      if (this.showPassword) {
        return 'text';
      }
      return 'password';
    },

    showPasswordError(): boolean {
      return this.password.length > 0 && this.passwordValidated === true && this.passwordValid === false;
    }
  },

  mounted(): void {
    if (this.hasFocus) {
      (this.$refs['password-input'] as HTMLElement).focus();
    }

    if (this.validateImmediately) {
      if (this.password) {
        this.validatePassword();
      } else {
        this.passwordValid = false;
        this.$emit('password-valid-update', false);
      }
    }
  },

  methods: {
    passwordToggle() {
      this.showPassword = !this.showPassword;
    },

    passwordUpdated() {
      this.$emit('password-update', this.password);
      this.validatePassword();
    },

    validatePassword() {
      this.passwordValid = this.password.length >= 6 ? true : false;
      this.passwordValidated = true;
      this.$emit('password-valid-update', this.passwordValid);
    }
  }
});
