import { ServiceRequest, ServiceRequestParameters } from '@/types/ServiceRequest';
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    serviceRequestRequest(): ServiceRequestParameters {
      return this.$store.getters['onramp/getServiceRequestRequest'];
    },

    isVehicleProvided(): boolean {
      return !!this.serviceRequestRequest.ownedVehicle?.id;
    }
  },

  methods: {
    createAdminServiceRequest(): Promise<void> {
      return this.$store
        .dispatch('onramp/createAdminServiceRequest', this.serviceRequestRequest)
        .then((serviceRequest: ServiceRequest) => {
          const serviceRequestUid = serviceRequest.openbayId;
          this.$store.dispatch('ui/pushView', {
            name: 'OfferSelector',
            params: { serviceRequestUid }
          });
        });
    }
  }
});
