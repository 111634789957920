
import { defineComponent } from 'vue';
import passwordInput from '@/components/Shared/PasswordInput.vue';
import emailInput from '@/components/Shared/EmailInput.vue';
import nameInput from '@/components/Shared/RequiredInput.vue';
import { CreateUserRequest } from '@/types/User';

export default defineComponent({
  name: 'SignUpForm',

  components: {
    'password-input': passwordInput,
    'email-input': emailInput,
    'name-input': nameInput,
  },

  props: {
    initialEmail: {
      type: String,
      default: ''
    },

    initialPhone: {
      type: String,
      default: ''
    },

    initialPassword: {
      type: String,
      default: ''
    },

    initialSubmitText: {
      type: String,
      required: false,
      default: 'Create my free Openbay account'
    },

    promoCode: {
      type: String,
      required: false,
      default: ''
    },

    initialFirstName: {
      type: String,
      default: ''
    },

    initialLastName: {
      type: String,
      default: ''
    },

    isJoinPlus: {
      type: Boolean,
      default: false
    },

    isDeliveryDriver: {
      type: Boolean,
      default: false
    }
  },

  data() {
    const email = this.initialEmail;
    return {
      newUser: {
        firstName: this.initialFirstName,
        lastName: this.initialLastName,
        password: this.initialPassword,
        email,
        phoneNumber: this.initialPhone,
        contactViaTextMessage: false,
        isRetailUser: !this.isJoinPlus,
        smsEnabled: false,
      } as CreateUserRequest,
      error: '',
      submitText: this.initialSubmitText,
      emailValid: !!email.match(/@/),
      firstNameValid: !!this.initialFirstName,
      lastNameValid: !!this.initialFirstName,
      passwordValid: this.initialPassword.length > 6,
      loading: false
    };
  },

  computed: {
    allFieldsValid(): boolean {
      return this.firstNameValid && this.lastNameValid && this.passwordValid && this.emailValid;
    },

    phoneNumberExists(): boolean {
      return this.newUser.phoneNumber.length > 0;
    },
  },

  mounted(): void {
    this.submitText = this.initialSubmitText;
    //If promo code is pass in as prop, add to request params.
    if (this.promoCode !== '') this.newUser.promoCode = this.promoCode;
  },

  methods: {
    updateFirstName(newFirstName: string) {
      this.newUser.firstName = newFirstName;
      this.$emit('update:firstName', newFirstName);
      this.$emit('update:initialFirstName', newFirstName);
    },

    updateFirstNameValidity(isValid: boolean) {
      this.firstNameValid = isValid;
    },

    updateLastName(newLastName: string) {
      this.newUser.lastName = newLastName;
      this.$emit('update:lastName', newLastName);
      this.$emit('update:initialLastName', newLastName);
    },

    updateLastNameValidity(isValid: boolean) {
      this.lastNameValid = isValid;
    },

    updatePassword(newPwd: string) {
      this.newUser.password = newPwd;
      this.$emit('update:password', newPwd);
      this.$emit('update:initialPassword', newPwd);
    },

    updatePasswordValidity(isValid: boolean) {
      this.passwordValid = isValid;
    },

    updateEmail(newEmail: string) {
      this.newUser.email = newEmail;
      this.$emit('update:email', newEmail);
      this.$emit('update:initialEmail', newEmail);
    },

    updateEmailValidity(isValid: boolean) {
      this.emailValid = isValid;
    },

    setSMS(): void {
      if (this.newUser.phoneNumber != '') {
        this.newUser.contactViaTextMessage = true;
      }
    },

    signUpUser(): Promise<void> {
      this.loading = true;
      this.submitText = 'Loading...';
      return this.signUpNewUser();
    },

    onSuccessfulSignUp(): Promise<void> {
      this.$store.commit('user/setSubscriptionsLoaded', true);
      return this.$store.dispatch('auth/refreshToken').then(() => {
        this.$emit('auth-success');
      });
    },

    signUpNewUser(): Promise<void> {
      return this.$store
        .dispatch('user/createUser', this.newUser)
        .then(() => this.onSuccessfulSignUp())
        .catch((response: any) => {
          this.$emit('auth-fail', response);
          this.error = response;
          this.submitText = this.initialSubmitText;
        })
        .finally(() => (this.loading = false));
    },
  }
});
