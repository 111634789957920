import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "card sidebar-card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "btn btn-block text-left" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = {
  key: 0,
  class: "list-group list-group-flush selected-services"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_CartService = _resolveComponent("CartService")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("button", _hoisted_3, [
        _createVNode(_component_icon, {
          icon: ['fal', 'wrench'],
          size: "lg"
        }),
        _createTextVNode(" Services ")
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.services.length > 0 || _ctx.maintenanceInterval)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.services, (service) => {
              return (_openBlock(), _createElementBlock("li", {
                key: service.serviceId,
                class: "list-group-item selected-service hover-actions"
              }, [
                _createVNode(_component_CartService, { cartService: service }, {
                  default: _withCtx(() => [
                    (_ctx.currentStep !== 'offer')
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "btn btn-sm float-right",
                          type: "button",
                          onClick: ($event: any) => (_ctx.startRemoveService(service))
                        }, [
                          _createVNode(_component_icon, { icon: ['fal', 'trash-alt'] })
                        ], 8, _hoisted_6))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["cartService"])
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_7, "No services currently selected."))
    ])
  ]))
}