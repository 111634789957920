import { RootState } from '@/types/store/RootState';
import { UiState } from '@/types/store/UiState';
import { View } from '@/types/store/View';
import { RouteName } from '@/routes/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { routeMeta } from '@/routes/routes';
import { Dialog } from '@/types/store/Dialog';

const initialState = (): UiState => ({
  initialView: {
    name: 'ChooseLocation',
    params: {}
  },
  viewStack: [],
  lastViewOperation: null,
  showSidebar: true,
  dialog: null
});

const state = initialState();

const buildView = (name: RouteName, params: Record<string, any> = {}): View => {
  return {
    name,
    params
  };
};

const getters: GetterTree<UiState, RootState> = {
  getCurrentView: (state) => {
    let v: View | null = null;

    if (state.viewStack.length === 0) {
      v = state.initialView;
    } else {
      v = state.viewStack[state.viewStack.length - 1];
    }

    // merge in the current views meta properties
    const meta = routeMeta[v.name];
    v.meta = { ...meta };
    return v;
  },

  getLastViewOperation: (state) => {
    return state.lastViewOperation;
  },

  showSidebar: (state) => {
    return state.showSidebar;
  },

  showDialog: (state) => {
    return !!state.dialog;
  },

  getDialog: (state) => {
    return state.dialog;
  }
};

const actions: ActionTree<UiState, RootState> = {
  pushView({ commit }, view: RouteName | View) {
    commit('pushView', view);
  },

  popView({ commit }) {
    commit('popView');
  },

  replaceView({ commit }, view: RouteName | View) {
    commit('replaceCurrentView', view);
  },

  showDialog({ commit }, dialog: Dialog) {
    commit('setDialog', dialog);
  },

  hideDialog({ commit }) {
    commit('setDialog', null);
  }
};

const mutations: MutationTree<UiState> = {
  replaceCurrentView(state, view: RouteName | View) {
    if (typeof view === 'string') view = buildView(view);

    view.params ??= {};

    if (state.viewStack.length === 0) {
      state.viewStack.push(view);
      return;
    }

    state.viewStack[state.viewStack.length - 1] = view;
    state.lastViewOperation = 'replace';
  },

  pushView(state, view: RouteName | View) {
    if (typeof view === 'string') view = buildView(view);

    view.params ??= {};

    state.viewStack.push(view);
    state.lastViewOperation = 'push';
  },

  popView(state) {
    state.viewStack.pop();
    state.lastViewOperation = 'pop';
  },

  setShowSidebar(state, show: boolean) {
    state.showSidebar = show;
  },

  setDialog(state, dialog) {
    state.dialog = dialog;
  },

  reset(state) {
    state = Object.assign(state, initialState());
  }
};

const UiStore: Module<UiState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default UiStore;
