
import { defineComponent } from 'vue';

import { View } from '@/types/store/View';

import { appointmentComponents } from '@/routes/appointment';
import configMixin from '@/mixins/configMixin';

export default defineComponent({
  name: 'OnrampView',
  
  mixins: [configMixin],

  components: {
    ...appointmentComponents
  },

  computed: {
    currentView(): View {
      return this.$store.getters['ui/getCurrentView']
    },
  },
});
