import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08bb239b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "amenities" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "text-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.amenities, (amenity) => {
      return (_openBlock(), _createElementBlock("li", {
        key: amenity.replace(/\s+/g, '-').toLowerCase()
      }, [
        _createVNode(_component_icon, {
          icon: _ctx.setIcon(amenity)
        }, null, 8, ["icon"]),
        _createTextVNode(" " + _toDisplayString(amenity), 1)
      ]))
    }), 128)),
    (_ctx.location.appointmentBuffer)
      ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
          _createVNode(_component_icon, { icon: ['fal', 'calendar-edit'] }),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.schedulingText), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}