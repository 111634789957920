import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, TransitionGroup as _TransitionGroup, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return _withDirectives((_openBlock(), _createBlock(_TransitionGroup, {
    name: "alert",
    tag: "ul",
    class: "global-alert"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alerts, (alert, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: `${index}-alert`,
          class: _normalizeClass([`bg-${alert.type} text-${alert.type}`, "shadow"])
        }, [
          _createVNode(_component_icon, {
            class: "type-icon",
            icon: ['fas', _ctx.icon(alert.type)]
          }, null, 8, ["icon"]),
          _createTextVNode(" " + _toDisplayString(alert.message) + " ", 1),
          _createElementVNode("button", {
            class: _normalizeClass(["btn btn-sm", `text-${alert.type}`]),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.removeAlert && _ctx.removeAlert(...args)))
          }, [
            _createVNode(_component_icon, { icon: ['far', 'times'] })
          ], 2)
        ], 2))
      }), 128))
    ]),
    _: 1
  }, 512)), [
    [_vShow, _ctx.alerts.length > 0]
  ])
}