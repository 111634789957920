import { AxiosError, AxiosResponse } from 'axios';

import { httpClient } from '@/api';
import { handleError, handleSuccess } from '@/services/helpers';
import store from '@/store/store';
import { ResourcesDataDocument } from '@/types/JsonSpec';
import { BetaContact, BetaContactDocument } from '@/types/resources/BetaContact';
import { OwnedVehicleDocument, OwnedVehiclesDocument } from '@/types/resources/OwnedVehicles';
import {
  PartialServiceRequestsDocument,
  ServiceRequestDocument,
  ServiceRequestsDocument
} from '@/types/ServiceRequest';
import { EmployeeDocument, User, UserDocument, UserProfileAPI, UserTypeInfo } from '@/types/User';
import { UserReviewDocument, UserReviewsDocument } from '@/types/UserReview';
// Types
import { VehicleRequest } from '@/types/Vehicle';

export interface UserReviewCreationParams {
  rating: number;
  review: string;
  locationId: number;
  serviceRequestId: number;
}

const userId = () => store.getters['user/getUserProfile'].id;

export default {
  retrieveUserProfile(): Promise<User> {
    return httpClient
      .get<UserDocument | EmployeeDocument>(`v3/users/profile`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error: AxiosError | string) => {
        if (typeof error !== 'string') throw handleError(error);
        else throw error;
      });
  },
  fetchServiceRequest(id: number | string) {
    return httpClient
      .get<ServiceRequestDocument>(`v3/users/service_requests/${id}`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  fetchServiceRequests(
    scope: 'settled' | 'withdrawn' | 'expired' | 'pending' | 'accepted' | '' = '',
    page: number = 1,
    perPage: number = 5,
    all: boolean = false
  ) {
    return httpClient
      .get<ServiceRequestsDocument | PartialServiceRequestsDocument>(`v3/users/${userId()}/service_requests/${scope}`, {
        params: { page: page, per_page: perPage, all: all }
      })
      .then((response) => {
        const serviceRequestResponse = {
          serviceRequests: handleSuccess(response),
          count: response.data.meta!.count
        };
        return serviceRequestResponse;
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  fetchVehicles() {
    return httpClient
      .get<OwnedVehiclesDocument>('v3/users/vehicles')
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  addOwnedVehicle(vehicle: VehicleRequest) {
    return httpClient
      .post<OwnedVehicleDocument>('v3/owned_vehicles', vehicle)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error: AxiosError) => {
        throw handleError(error);
      });
  },

  updateUserProfile(accountInfo: UserProfileAPI) {
    return httpClient
      .put<UserDocument>('v3/users/profile', accountInfo)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((err) => {
        let error = err.response.data.error;
        error =
          error === 'Email has already been taken and Email is in use already' ? 'Email is in use already.' : error;

        throw error;
      });
  },
  updateBetaContacts(payload: BetaContact) {
    return httpClient
      .post<BetaContactDocument>('/v3/users/beta_contacts', payload)
      .then((response) => handleSuccess(response))
      .catch((error) => handleError(error));
  },
  createReviewForLocation(payload: UserReviewCreationParams) {
    return httpClient
      .post<UserReviewDocument>(`/v3/users/reviews/${payload.locationId}`, payload)
      .then((response) => handleSuccess(response))
      .catch((error) => handleError(error));
  },
  fetchReviews() {
    return httpClient
      .get<UserReviewsDocument>('/v3/users/reviews')
      .then((response) => handleSuccess(response))
      .catch((error) => handleError(error));
  },
  retrieveEmailType(email: string) {
    return httpClient
      .get<UserTypeInfo>(`/v3/users/type?email=${encodeURIComponent(email)}`)
      .then((response) => {
        return response.data;
      })
      .catch((error: AxiosError | string) => {
        if (typeof error !== 'string') throw handleError(error);
        else throw error;
      });
  },
  verifyEmail(email: string, landingPage: string) {
    return httpClient
      .put('/v3/users/email_verification', { email, landing_page: landingPage })
      .then((response) => {
        return response;
      })
      .catch((error: AxiosError | string) => {
        throw error;
      });
  },
  verifyPasswordResetToken(token: string) {
    return httpClient
      .post('/v3/users/password_reset/token', { password_reset_token: token })
      .then((response) => {
        return response;
      })
      .catch((error: AxiosError | string) => {
        throw error;
      });
  }
};
