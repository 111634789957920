import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import store from '@/store/store';
import { camelKeys, isNullish, snakeKeys } from '@/utilities';

// HTTP client config for making requests to the Rails stack using JSON:API specification
const config = {
  withCredentials: true,
  headers: {
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json'
  }
} as AxiosRequestConfig;

const httpClient = axios.create(config);

// Add global request interceptors
httpClient.interceptors.request.use(
  (config) => {
    config.baseURL = `${store.getters['config/getBaseUrl']}/api`;
    config.data = snakeKeys(config.data);
    config.withCredentials = true;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/** Adding global response interceptors */
httpClient.interceptors.response.use(
  (response: AxiosResponse) => {
    // If this is a successful response formatted as JsonSpec, process it with some assumptions.
    if (!isNullish(response.data?.data)) {
      response.data = camelKeys(response.data);
    }

    return response;
  },
  (error) => {
    const { response } = error;
    if (!response) return Promise.reject(error);

    if (response.status === 401) {
      store.dispatch('user/logout');
    } else if (response.status === 429) {
      throw error;
    }

    return Promise.reject(error);
  }
);

export { httpClient };
