
import { defineComponent, PropType } from 'vue';

//Libraries
import { isNullish } from '@/utilities';

//Components
import LocationAmenities from './LocationAmenities.vue';
import ReviewSummary from './ReviewSummary.vue';

//Types
import { LocationFull } from '@/types/ServiceProvider';
import assetMixin from '@/mixins/assetMixin';
import configMixin from '@/mixins/configMixin';

export default defineComponent({
  name: 'LocationPartial',

  components: {
    LocationAmenities,
    ReviewSummary
  },

  mixins: [
    assetMixin,
    configMixin,
  ],

  props: {
    distanceFrom: {
      type: Number,
      default: undefined
    },

    displayLogo: {
      type: Boolean,
      default: false
    },

    location: {
      required: true,
      type: Object as PropType<LocationFull>
    },

    // Initial use is for external links, hence string type instead of Vue Route object.
    headerRoute: {
      required: false,
      type: String,
      default: null
    },

    showActiveOnPlatofrm: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    logo(location: LocationFull): string | undefined {
      return !isNullish(location.logoImage) ? location.logoImage.image.url : undefined;
    }
  }
});
