import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sr-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass([{ 'bg-white': _ctx.hideBackground }, 'spinner-container', { 'position-relative': _ctx.relative, 'text-center': _ctx.centered }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["spinner-border", `spinner-border-${_ctx.size}`]),
      role: "status"
    }, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1)
    ], 2)
  ], 2))
}