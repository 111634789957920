import { AxiosError } from 'axios';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

/* eslint-disable no-param-reassign */
import { handleError } from '@/services/helpers';
import serviceRequestService from '@/services/serviceRequestService';
import { OfferFull } from '@/types/Offer';
import { DiscountServicePrice } from '@/types/resources/DiscountServicePrices';
import { OwnedVehicle } from '@/types/resources/OwnedVehicles';
import {
  BillingAddress,
  CartService,
  ServiceAddress,
  ServiceRequest,
  ServiceRequestParameters
} from '@/types/ServiceRequest';
import { VehicleMaintenanceService } from '@/types/Vehicle';
import { ZipcodeLocation } from '@/types/ZipcodeLocation';
import { isNullish } from '@/utilities';

import { OnrampState } from '@/types/store/OnrampState';
import { RootState } from '@/types/store/RootState';
import { AdminConfig } from '@/types/AdminConfig';
import PartnerService from '@/services/microservices/partnerService';
import { Subscription } from '@/types/microservices/Subscriptions';

const initialState = (): OnrampState => ({
  appointmentSlot: null,
  loadingServiceRequest: true,
  serviceRequestRequest: {
    maintenanceInterval: undefined,
    notes: undefined,
    ownedVehicle: null,
    services: [],
    zipcode: '',
    source: 'Web'
  },
  offer: null,
  preselectedVin: '',
  vehicleToCreate: null,
  serviceToAdd: null,
  location: {
    city: '',
    state: '',
    zipcode: '',
    term: ''
  },
  billingAddress: {
    addressLine1: '',
    addressLine2: '',
    addressCity: '',
    addressState: '',
    addressZipcode: '',
    addressCountry: ''
  },
  serviceAddress: {
    addressLine1: '',
    addressLine2: '',
    addressCity: '',
    addressState: '',
    addressZipcode: '',
    addressCountry: ''
  },
  serviceRequest: null,
  vehicles: [],
  subscriptions: []
});

const state = initialState();

const getters: GetterTree<OnrampState, RootState> = {
  getAppointmentSlot: (state) => state.appointmentSlot,

  getLoadingServiceRequest: (state) => state.loadingServiceRequest,

  getServices: (state) => state.serviceRequestRequest.services,

  getZipcode: (state) => state.serviceRequestRequest.zipcode,

  getOffer: (state) => state.offer,

  getOffers: (state) => state.serviceRequest?.offers ?? [],

  getOwnedVehicle: (state) => state.serviceRequestRequest.ownedVehicle,

  getServiceRequestRequest: (state) => state.serviceRequestRequest,

  getServiceToAdd: (state) => state.serviceToAdd,

  getVehicleToCreate: (state) => state.vehicleToCreate,

  getLocation: (state) => state.location,

  getBillingAddress: (state) => state.billingAddress,

  getServiceAddress: (state) => state.serviceAddress,

  getPreselectedVin: (state) => state.preselectedVin,

  getPartnerId: (state) => state.partnerId,

  getVehicles: (state) => state.vehicles,

  getSubscriptions: (state) => state.subscriptions,

  getFormattedServiceAddress: (state) => {
    const addressLine2 = !isNullish(state.serviceAddress.addressLine2) ? state.serviceAddress.addressLine2 : '';
    const addressLine = `${state.serviceAddress.addressLine1} ${addressLine2}`.trim();
    return `${addressLine} ${state.serviceAddress.addressCity}, ${state.serviceAddress.addressState} ${state.serviceAddress.addressZipcode}`;
  },

  getServiceRequest: (state) => state.serviceRequest,

  // Billing Address must have at least: Address Line 1, City, State, Zipcode
  getValidityOfBillingAddress: (state) => {
    const validZip = state.billingAddress.addressZipcode.match(/[0-9]{5}/g) || false;
    return state.billingAddress.addressLine1.length > 0 && validZip && state.billingAddress.addressCity.length > 0;
  },

  isAutoquotable: (state) => {
    if (isNullish(state.serviceRequestRequest.services)) return false;

    return state.serviceRequestRequest.services.every((service) => service.autoquotable === true);
  }
};

const actions: ActionTree<OnrampState, RootState> = {
  acceptOffer(
    { commit },
    payload: {
      offer: OfferFull;
      cardId: number | null;
      address: ServiceAddress | null;
      appointmentTime: string;
      serviceRequest: ServiceRequest;
    }
  ): Promise<ServiceRequest> {
    return serviceRequestService
      .acceptOffer(payload.offer, payload.cardId, payload.address, payload.appointmentTime)
      .then((updatedServiceRequest: ServiceRequest) => {
        //prettier-ignore
        commit('updateServiceRequest', updatedServiceRequest);
        return updatedServiceRequest;
      })
      .catch((error: AxiosError) => {
        throw handleError(error);
      });
  },

  createServiceRequest({ commit }, serviceRequest: ServiceRequestParameters): Promise<ServiceRequest | string> {
    return serviceRequestService
      .create(serviceRequest)
      .then((serviceRequestResponse: ServiceRequest) => {
        commit('setServiceRequest', serviceRequestResponse);
        return serviceRequestResponse;
      })
      .catch((error: AxiosError) => {
        throw handleError(error);
      });
  },

  createAdminServiceRequest(
    { state, commit },
    serviceRequest: ServiceRequestParameters
  ): Promise<ServiceRequest | string> {
    return new PartnerService()
      .createServiceRequest(state.partnerId!, serviceRequest)
      .then((serviceRequestResponse: ServiceRequest) => {
        commit('setServiceRequest', serviceRequestResponse);
        return serviceRequestResponse;
      })
      .catch((error: AxiosError) => {
        throw handleError(error);
      });
  },

  fetchOffers({ commit }, serviceRequestId: number) {
    if (state.partnerId) {
      return new PartnerService()
        .fetchOffers(state.partnerId, serviceRequestId)
        .then((offers: OfferFull[]) => {
          if (offers.length > 0) {
            commit('setOffersOnServiceRequest', offers);
            return offers;
          }
        })
        .catch((error: string) => {
          throw error;
        });
    }

    return serviceRequestService
      .fetchOffers(serviceRequestId)
      .then((offers: OfferFull[]) => {
        if (offers.length > 0) {
          commit('setOffersOnServiceRequest', offers);
          return offers;
        }
      })
      .catch((error: string) => {
        throw error;
      });
  },

  initializeFromConfig({ commit }, config: AdminConfig) {
    commit('setZipcode', config.serviceRequest.zipCode);
    commit('setPartnerId', config.serviceRequest.partnerId);
    commit('setUser', { userId: config.serviceRequest.userId });

    const partnerService = new PartnerService();

    return Promise.all([
      partnerService.getSubscriptions(config.serviceRequest.partnerId, config.serviceRequest.tsUserId),
      partnerService.getOwnedVehicles(config.serviceRequest.partnerId, config.serviceRequest.userId)
    ])
      .then(([subscriptions, vehicles]) => {
        if (config.serviceRequest.ownedVehicleId) {
          const vehicle = vehicles.find((vehicle) => vehicle.id == config.serviceRequest.ownedVehicleId);
          commit('setVehicleToCreate', vehicle);
          commit('setOwnedVehicle', vehicle);
        } else {
          commit('setVehicles', vehicles);
        }
        commit('setSubscriptions', subscriptions);
      })
      .catch((error: AxiosError) => {
        throw handleError(error);
      });
  }
};

const mutations: MutationTree<OnrampState> = {
  clearOwnedVehicle(state) {
    state.serviceRequestRequest.ownedVehicle = null;
  },

  setAppointmentSlot(state, appointmentSlot) {
    state.appointmentSlot = appointmentSlot;
  },

  setLocation(state, location: ZipcodeLocation) {
    state.location = location;
  },

  setNotes(state, notes: string) {
    state.serviceRequestRequest.notes = notes;
  },

  setVehicles(state, vehicles: OwnedVehicle[]) {
    state.vehicles = vehicles;
  },

  setMaintenanceInterval(state, interval: [string, VehicleMaintenanceService[]]) {
    const mileage = interval[0];
    const services = interval[1];
    state.serviceRequestRequest.maintenanceInterval = {
      mileage: parseInt(mileage, 10),
      services: services
    };
  },

  setOffer(state, offer: OfferFull) {
    state.offer = offer;
  },

  setOwnedVehicle(state, ownedVehicle) {
    state.serviceRequestRequest.ownedVehicle = ownedVehicle;
  },

  setServiceRequest(state, serviceRequest: ServiceRequest) {
    state.serviceRequest = serviceRequest;
  },

  addService(state, service: CartService) {
    state.serviceRequestRequest.services.push(service);
  },

  setServices(state, services: CartService[]) {
    state.serviceRequestRequest.services = services;
  },

  setSource(state, source: string) {
    state.serviceRequestRequest.source = source;
  },

  setZipcode(state, zipcode) {
    state.serviceRequestRequest.zipcode = zipcode;
  },

  updateService(state, service: CartService) {
    const serviceIndex = state.serviceRequestRequest.services.findIndex(
      (existingService) => existingService.serviceId === service.serviceId
    );

    state.serviceRequestRequest.services[serviceIndex] = service;
  },

  updateServiceRequest(state, serviceRequest: ServiceRequest) {
    state.serviceRequest = serviceRequest;
  },

  addOrUpdateService(state, service: CartService) {
    const existingServiceIndex = state.serviceRequestRequest.services.findIndex(
      (existingService) => existingService.serviceId === service.serviceId
    );

    if (existingServiceIndex >= 0) {
      state.serviceRequestRequest.services[existingServiceIndex] = service;
    } else {
      state.serviceRequestRequest.services.push(service);
    }
  },

  addOrUpdatePackage(state, discountPackage: DiscountServicePrice) {
    const existingServiceIndex = state.serviceRequestRequest.services.findIndex(
      (existingService) => existingService.serviceId === discountPackage.serviceId
    );
  },

  addOrUpdateOfferOnServiceRequest(state, offer: OfferFull) {
    if (isNullish(state.serviceRequest)) return;

    const offerIndex = state.serviceRequest!.offers.findIndex((srOffer: null | OfferFull) => {
      if (isNullish(srOffer)) return;
      return srOffer.id === offer.id;
    });

    if (offerIndex > -1) {
      state.serviceRequest!.offers[offerIndex] = offer;
    } else {
      //@ts-ignore
      state.serviceRequest!.offers.push(offer); // TODO expired SRs have an array of null, but don't use this method
    }
  },

  removeService(state, id: number) {
    const serviceIndex = state.serviceRequestRequest.services.findIndex((service) => service.serviceId === id);
    state.serviceRequestRequest.services.splice(serviceIndex, 1);
  },

  setOffersOnServiceRequest(state, offers: OfferFull[]) {
    state.serviceRequest = {
      ...state.serviceRequest!,
      offers
    };
  },

  setServiceToAdd(state, service: CartService) {
    state.serviceToAdd = service;
  },

  setVehicleToCreate(state, vehicleRequest: Partial<OwnedVehicle>) {
    state.vehicleToCreate = vehicleRequest;
  },

  clearVehicleToCreate(state) {
    state.vehicleToCreate = null;
  },

  setBillingAddress(state, billingAddress: BillingAddress) {
    state.billingAddress = billingAddress;
  },

  setLoadingServiceRequest(state, loadingState: boolean) {
    state.loadingServiceRequest = loadingState;
  },

  setServiceAddress(state, serviceAddress: ServiceAddress) {
    state.serviceAddress = serviceAddress;
  },

  setPreselectedVin(state, preselectedVin: string) {
    state.preselectedVin = preselectedVin;
  },

  clearPreselectedVin(state) {
    state.preselectedVin = '';
  },

  setUser(state, user: { userId: number }) {
    state.serviceRequestRequest.user = user;
  },

  removeInterval(state) {
    state.serviceRequestRequest.maintenanceInterval = undefined;
  },

  resetServiceToAdd(state) {
    state.serviceToAdd = null;
  },

  resetServices(state) {
    state.serviceRequestRequest.services = [];
  },

  reset(state) {
    state = Object.assign(state, initialState());
  },

  setPartnerId(state, partnerId: number) {
    state.partnerId = partnerId;
  },

  setSubscriptions(state, subscriptions: Subscription[]) {
    state.subscriptions = subscriptions;
  }
};

const OnrampCartStore: Module<OnrampState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default OnrampCartStore;
