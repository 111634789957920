
import { defineComponent, PropType } from 'vue';
import moment, { Moment } from 'moment';
import { Navigation } from 'swiper/modules';

//components
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { isNullish } from '../../../utilities';
import { AppointmentSlot } from '@/types/ServiceRequest';


export default defineComponent({
  name: 'TimePreference',

  components: {
    Swiper,
    SwiperSlide
  },

  props: {
    active: {
      type: Boolean,
      required: true
    },

    title: {
      type: String,
      required: false,
      default: 'Appointment preference'
    },

    slots: {
      type: Array as PropType<AppointmentSlot[]>,
      required: false,
      default: () => []
    },

    days: {
      type: Array as PropType<Moment[]>,
      required: true
    },

    dayTimePreference: {
      type: Object as PropType<AppointmentSlot | null>,
      required: false,
      default: null
    }
  },

  data() {
    return {
      selectedDay: null as null | Moment,
      selectedDayTimes: [] as AppointmentSlot[],
      selectedSlot: null as AppointmentSlot | null,
      availableTimes: [] as string[],
      swiperOption: {
        slidesPerView: document.documentElement.clientWidth > 768 ? 4 : 2,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    };
  },

  computed: {
    formattedTimePreference(): string {
      const date = moment(this.dayTimePreference?.proposedTime).format('dddd, MMMM D');
      return `${date} at ${this.dayTimePreference?.slotTitle}`;
    }
  },

  methods: {
    isNullish: isNullish,

    moment: moment,

    activeDay(day: Moment): boolean {
      return !isNullish(this.selectedDay) ? this.selectedDay.format('ddd DD-MMM') === day.format('ddd DD-MMM') : false;
    },

    activeTime(dayTime: string): boolean {
      return !isNullish(this.selectedDay)
        ? this.selectedDay.format('ddd DD-MMM') === moment(dayTime, 'hh:mm a').format('ddd DD-MMM')
        : false;
    },

    selectDay(day: Moment): void {
      this.selectedDayTimes = [];
      this.selectedDay = day;
      this.createHoursArray(day);
    },

    selectSlot(slot: AppointmentSlot): void {
      this.selectedSlot = slot;
      this.$emit('update:preference', slot);
    },

    createHoursArray(day: Moment): void {
      this.selectedDayTimes = this.slots.filter((slot) => {
        return slot.day === day.format('YYYY-MM-DD');
      });
    },

    resetPreference(): void {
      this.selectedDay = null;
      this.$emit('update:preference', null);
    }
  },

  setup() {
    return {
      modules: [Navigation]
    };
  },
});
