
import { defineComponent } from 'vue';

import OnrampNavigation from '@/components/Onramp/OnrampNavigation.vue';
import OnrampCart from '@/components/Onramp/OnrampCart.vue';
import DialogPage from '@/components/Shared/DialogPage.vue';

import { View } from '@/types/store/View';

import { onrampComponents } from '@/routes/onramp';
import { RouteMetaConfig } from '@/routes/types';
import { Dialog } from '@/types/store/Dialog';
import configMixin from '@/mixins/configMixin';

export default defineComponent({
  name: 'OnrampView',
  
  mixins: [configMixin],

  components: {
    OnrampNavigation,
    OnrampCart,
    DialogPage,
    ...onrampComponents
  },

  computed: {
    currentView(): View {
      return this.$store.getters['ui/getCurrentView']
    },

    showOnrampNavigation(): boolean {
      // We might want a better way of determining whether we're in the onramp flow.
      return !['ChooseLocation', 'ZeroCoverage', 'AppointmentConfirmed'].includes(this.currentView.name);
    },

    currentStep(): RouteMetaConfig['onrampStep'] {
      return this.currentView.meta?.onrampStep;
    },

    showOnrampCart(): boolean {
      return this.showOnrampNavigation 
        && this.currentStep !== 'confirm' 
        && !this.dialogInView;
    },
    
    transitionName(): string {
      switch (this.$store.getters['ui/getLastViewOperation']) {
        case 'push': return 'slide-left'
        case 'pop': return 'slide-right'
        default: return 'fade';
      }
    },

    dialog(): Dialog {
      return this.$store.getters['ui/getDialog'];
    },

    mobileSidebarInView(): boolean {
      return this.$store.getters['ui/showSidebar'];
    },

    dialogInView(): boolean {
      return this.$store.getters['ui/showDialog'];
    },

    onrampInView(): boolean {
      return !this.dialogInView;
    }
  },
});
