import { ExternalConfig } from '@/types/ExternalConfig';
import { WidgetType } from '@/types/PartnerConfig';
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    externalConfig(): ExternalConfig {
      return this.$store.getters['config/getExternalConfig'];
    },

    widgetType(): WidgetType {
      return this.externalConfig.widgetType;
    },

    isAdminFlow(): boolean {
      return this.widgetType === WidgetType.Admin;
    }
  }
});
