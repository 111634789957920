import store from '@/store/store';
import { ExternalConfig } from '@/types/ExternalConfig';
import { WidgetType } from '@/types/PartnerConfig';

import { authenticateLocalAdmin } from './authenticateLocalAdmin';
import initFonts from './initFonts';
import registerFontAwesome from './registerFontAwesome';

declare global {
  interface Window {
    OB_CONFIG: ExternalConfig;
  }
}

function inject(src: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.src = `${src}`;
    scriptTag.onload = () => resolve();
    scriptTag.onerror = () => reject();
    document.body.appendChild(scriptTag);
  });
}

function loadGoogleMaps(): Promise<void> {
  return inject(`https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_API_KEY}`);
}

async function loadConfigObject(): Promise<void> {
  const config = window.OB_CONFIG || {};
  store.commit('config/setExternalConfig', config);

  if (process.env?.VUE_APP_ENVIRONMENT === 'local' && config.widgetType === WidgetType.Admin) {
    await authenticateLocalAdmin();
  }
}

function fetchServices(): Promise<unknown[]> {
  return Promise.allSettled([
    store.dispatch('services/fetchPopularServices'),
    store.dispatch('services/fetchDescribeProblem'),
    store.dispatch('services/fetchServiceCatalog'),
    store.dispatch('services/fetchServices')
  ]);
}

function setBaseUrl(): string {
  // If we're loaded via script (we better be), set the base URL for API calls
  // and assets to be that of the script. Otherwise just use the window? I guess?
  const currentScript = document.currentScript as HTMLScriptElement;
  const baseUrl = currentScript ? new URL(currentScript.src).origin : window.location.origin;
  const baseUrlWithServiceName =
    process.env.NODE_ENV === 'production' ? `${baseUrl}/${process.env.VUE_APP_SERVICE_NAME}`
      : `/openbay-mini`;
  store.commit('config/setBaseUrl', baseUrlWithServiceName);
  return baseUrlWithServiceName;
}

export function setup(): Promise<unknown[]> {
  const baseUrl = setBaseUrl();
  initFonts(baseUrl);
  registerFontAwesome();
  return Promise.allSettled([loadConfigObject(), loadGoogleMaps(), fetchServices()]);
}
