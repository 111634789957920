
import { RouteMetaConfig } from '@/routes/types';
import { View } from '@/types/store/View';
import { defineComponent } from 'vue';
import { routeMeta } from '@/routes/routes';


export default defineComponent({
  name: 'OnrampNavigation',

  computed: {
    currentStepLabel(): string {
      if (this.currentStep === 'offer') {
        return 'estimates';
      } else if (this.currentStep === 'schedule') {
        return 'book';
      } else if (this.currentStep === 'sign-up') {
        return 'register';
      } else {
        return this.currentStep;
      }
    },

    currentStep(): string {
      return this.viewMeta.onrampStep || 'services';
    },

    chevronDirection(): 'up' | 'down' {
      return this.showSidebar === true ? 'up' : 'down';
    },

    showSidebar(): boolean {
      return this.$store.getters['ui/showSidebar'];
    },

    showSidebarToggler(): boolean {
      return this.$store.getters['ui/getDialog'] === null;
    },

    isVehicleFirstFlow(): boolean {
      return this.$store.getters['user/isLyftUser'];
    },

    plusSubscriber(): boolean {
      return this.$store.getters['user/getSubscriptions'].length > 0;
    },

    currentView(): View {
      return this.$store.getters['ui/getCurrentView'];
    },

    viewMeta(): RouteMetaConfig {
      return this.currentView.meta!;
    },

    showCloseButton(): boolean {
      return !!routeMeta[this.currentView.name].showCloseButton && !this.dialogInView;
    },

    showBackButton(): boolean {
      return !!routeMeta[this.currentView.name].showBackButton && !this.dialogInView;
    },

    dialogInView(): boolean {
      return this.$store.getters['ui/showDialog'];
    },
  },

  methods: {
    toggleSidebar(): void {
      this.$store.commit('ui/setShowSidebar', !this.showSidebar);
    },

    isCompleted(step: string): boolean {
      switch (step) {
        case 'vehicle':
          if (
            this.currentStep === 'vehicle' ||
            this.currentStep === 'confirm' ||
            this.currentStep === 'offer' ||
            this.currentStep === 'schedule' ||
            this.currentStep === 'sign-up'
          ) {
            return true;
          }
          return false;
          case 'sign-up':
          if (
            this.currentStep === 'confirm' ||
            this.currentStep === 'offer' ||
            this.currentStep === 'schedule' ||
            this.currentStep === 'sign-up'
          ) {
            return true;
          }
          return false;
        case 'register':
          if (
            this.currentStep === 'confirm' ||
            this.currentStep === 'offer' ||
            this.currentStep === 'schedule' ||
            this.currentStep === 'sign-up'
          ) {
            return true;
          }
          return false;
        case 'estimate':
          if (
            this.currentStep === 'offer' ||
            this.currentStep === 'confirm' ||
            this.currentStep === 'schedule'
          ) {
            return true;
          }
          return false;
        case 'book':
          if (this.currentStep === 'confirm' || this.currentStep === 'schedule') {
            return true;
          }
          return false;
        case 'confirm':
          if (this.currentStep === 'confirm') {
            return true;
          }
          return false;
        default:
          return false;
      }
    },

    goBack(): void {
      this.$store.dispatch('ui/popView');
    },

    close(): void {
      ['onramp', 'user'].forEach((module) => {
        this.$store.commit(`${module}/reset`);
      });
      this.$store.dispatch('ui/hideDialog');
      this.$store.dispatch('ui/pushView', {
        name: 'ChooseLocation'
      });
    },
  }
});
