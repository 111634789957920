
import { View } from '@/types/store/View';
import { defineComponent } from 'vue';
import { routeMeta } from '@/routes/routes';
import assetMixin from '@/mixins/assetMixin';


export default defineComponent({
  name: 'HeaderComponent',
  mixins: [assetMixin],
  computed: {
    currentView(): View {
      return this.$store.getters['ui/getCurrentView'];
    },

    showCloseButton(): boolean {
      return !!routeMeta[this.currentView.name].showCloseButton && !this.dialogInView;
    },

    showBackButton(): boolean {
      return !!routeMeta[this.currentView.name].showBackButton && !this.dialogInView;
    },

    dialogInView(): boolean {
      return this.$store.getters['ui/showDialog'];
    },
  },

  methods: {
    goBack(): void {
      this.$store.dispatch('ui/popView');
    },

    close(): void {
      ['onramp', 'user'].forEach((module) => {
        this.$store.commit(`${module}/reset`);
      });
      this.$store.dispatch('ui/hideDialog');
      this.$store.dispatch('ui/pushView', {
        name: 'ChooseLocation'
      });
    },
  },
});
