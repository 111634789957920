
import { defineComponent, PropType } from 'vue';

//Types
import { OfferFull } from '@/types/Offer';
import { isNullish } from '@/utilities';

declare var google: any;

export default defineComponent({
  name: 'OfferMap',
  props: {
    offers: {
      type: Array as PropType<OfferFull[] | null[]>,
      required: false,
      default: () => [] as OfferFull[]
    },

    draggable: {
      type: Boolean,
      required: false,
      default: true
    },

    // Lat and lon of the service request. Used to represent lat and lon of service address for mobile
    // mechanics or of the service station location.
    lat: {
      type: Number,
      required: true
    },

    lon: {
      type: Number,
      required: true
    }
  },

  computed: {
    isSingleOffer(): boolean {
      return this.offers.length === 1;
    }
  },

  mounted(): void {
    this.initMap();
  },

  methods: {
    initMap(): void {
      var markers = [] as any;
      const zoomLevel = !this.isSingleOffer ? 11 : 15;
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      });

      const map = new google.maps.Map(this.$refs.map as HTMLElement, {
        zoom: zoomLevel,
        center: new google.maps.LatLng(this.lat, this.lon),
        disableDefaultUI: true,
        draggable: this.draggable
      });

      this.offers.forEach((offer: OfferFull | null) => {
        // TODO: resolve expired service requests that have an array of null offers
        if (isNullish(offer)) return;
        const self = this;
        const totalPrice = formatter.format(offer.totalPriceInDollars);
        const { lat, lon } = this.isSingleOffer
          ? { lat: this.lat, lon: this.lon }
          : { lat: offer.location.lat, lon: offer.location.lon };

        let marker = new google.maps.Marker({
          position: new google.maps.LatLng(lat, lon),
          label: {
            text: `${totalPrice}`,
            color: 'white',
            fontWeight: '600'
          },
          icon: {
            url: '/map-marker-price-bg.svg',
            labelOrigin: new google.maps.Point(38, 18)
          },
          animation: google.maps.Animation.DROP,
          map: map
        });

        markers.push(marker);

        google.maps.event.addListener(
          marker,
          'click',
          (function () {
            return function () {
              self.$emit('show-location-details', offer);
              self.$emit('show-map', false);
            };
          })()
        );
      });
    }
  }
});
