import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "row justify-content-end" }
const _hoisted_5 = { class: "col-md-4 col-sm-12" }
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_time_preference = _resolveComponent("time-preference")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.title !== '')
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_time_preference, {
        active: true,
        days: _ctx.days,
        slots: _ctx.slots,
        title: "First appointment preference",
        "day-time-preference": _ctx.firstPreference,
        preference: _ctx.firstPreference,
        "onUpdate:preference": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstPreference) = $event))
      }, null, 8, ["days", "slots", "day-time-preference", "preference"]),
      _createVNode(_component_time_preference, {
        active: !_ctx.isNullish(_ctx.firstPreference) || !_ctx.isNullish(_ctx.secondPreference),
        days: _ctx.days,
        slots: _ctx.slots,
        title: "Second appointment preference",
        "day-time-preference": _ctx.secondPreference,
        preference: _ctx.secondPreference,
        "onUpdate:preference": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.secondPreference) = $event))
      }, null, 8, ["active", "days", "slots", "day-time-preference", "preference"]),
      _createVNode(_component_time_preference, {
        active: !_ctx.isNullish(_ctx.secondPreference) || !_ctx.isNullish(_ctx.thirdPreference),
        days: _ctx.days,
        slots: _ctx.slots,
        title: "Third appointment preference",
        "day-time-preference": _ctx.thirdPreference,
        preference: _ctx.thirdPreference,
        "onUpdate:preference": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.thirdPreference) = $event))
      }, null, 8, ["active", "days", "slots", "day-time-preference", "preference"]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            disabled: _ctx.inProgress,
            class: "btn btn-primary btn-lg float-right mb-5 btn-block",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.finishAppointmentSelection && _ctx.finishAppointmentSelection(...args)))
          }, " Continue ", 8, _hoisted_6)
        ])
      ])
    ])
  ]))
}