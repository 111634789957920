import FindRepairFacility from '@/components/Appointment/FindRepairFacility.vue';

import { RouteMeta, RouteMetaConfig } from './types';

const _defaultMetaProps = {};

const withDefaults = (meta: Partial<RouteMetaConfig>): RouteMetaConfig => {
  return {
    ...meta,
    ..._defaultMetaProps
  };
};

export const appointmentComponents = {
  FindRepairFacility
};

export const appointmentMeta: RouteMeta = {
  FindRepairFacility: {}
};
