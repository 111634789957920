
import { PropType, defineComponent } from 'vue';
import { Moment } from 'moment';
//components
import TimePreference from './TimePreference.vue';

// Types
import { isNullish } from '../../../utilities';
import { AppointmentSlot } from '@/types/ServiceRequest';

export default defineComponent({
  name: 'MultiTimeSelector',

  components: {
    TimePreference
  },

  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },

    slots: {
      type: Array as PropType<AppointmentSlot[]>,
      required: false,
      default: () => []
    },

    days: {
      type: Array as PropType<Moment[]>,
      required: true
    },

    totalRepairTimeHours: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      firstPreference: null as null | AppointmentSlot,
      secondPreference: null as null | AppointmentSlot,
      thirdPreference: null as null | AppointmentSlot
    };
  },

  computed: {
    inProgress(): boolean {
      return isNullish(this.firstPreference) || isNullish(this.secondPreference) || isNullish(this.thirdPreference);
    }
  },

  methods: {
    isNullish: isNullish,

    finishAppointmentSelection(): void {
      this.$emit(
        'appt-preference-chosen',
        `${this.firstPreference!.key}, ${this.secondPreference!.key}, ${this.thirdPreference!.key}`
      );
      const preferences = [this.firstPreference, this.secondPreference, this.thirdPreference];
      this.$emit('time-set', preferences);
    }
  }
});
