import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import store from '@/store/store';

import { RetriableAxiosRequestConfig } from './types/microservices/api';
import { camelKeys, isNullish } from './utilities';

const config = (): AxiosRequestConfig => ({});

const httpClientMicro = axios.create(config());

httpClientMicro.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${store.state.auth.token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const maybeRetry = (config: RetriableAxiosRequestConfig): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    if (config.retried) {
      reject();
    } else {
      store
        .dispatch('auth/refreshToken')
        .then(() => {
          config.retried = true;
          if (config.headers) config.headers.Authorization = `Bearer ${store.state.auth.token}`;
          resolve(httpClientMicro.request(config));
        })
        .catch((error) => reject(error));
    }
  });
};

/** Adding global response interceptors */
httpClientMicro.interceptors.response.use(
  (response: AxiosResponse) => {
    if (!isNullish(response.data?.data)) {
      response.data = camelKeys(response.data);
    }

    return response;
  },
  (error: AxiosError) => {
    const { response } = error;
    if (!response) return Promise.reject(error);

    if (response.status === 401) {
      return maybeRetry(error.config as any).catch(() => {
        store.dispatch('user/logout');
      });
    }

    return Promise.reject(error);
  }
);

export { httpClientMicro };
