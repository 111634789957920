import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GlobalAlert = _resolveComponent("GlobalAlert")!
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_AppointmentView = _resolveComponent("AppointmentView")!
  const _component_OnrampView = _resolveComponent("OnrampView")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasAlerts)
      ? (_openBlock(), _createBlock(_component_GlobalAlert, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_HeaderComponent),
    _createElementVNode("main", null, [
      (_ctx.isAppointmentFlow)
        ? (_openBlock(), _createBlock(_component_AppointmentView, { key: 0 }))
        : (_openBlock(), _createBlock(_component_OnrampView, { key: 1 }))
    ]),
    (_ctx.showFooter)
      ? (_openBlock(), _createBlock(_component_FooterComponent, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}