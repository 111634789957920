import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c2cbc7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "add-owned-vehicle" }
const _hoisted_2 = { class: "error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchList = _resolveComponent("SearchList")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.error), 1),
    _createElementVNode("h5", null, _toDisplayString(_ctx.vehicleQuestion), 1),
    _createVNode(_component_SearchList, {
      loading: _ctx.loading,
      "search-data": _ctx.searchData,
      "search-type": _ctx.currentStep.type,
      placeholder: _ctx.currentStep.placeholder,
      "default-data": _ctx.searchData,
      "focus-on-display": true,
      onItemSelected: _ctx.itemSelected,
      onTrimSelected: _ctx.trimSelected
    }, null, 8, ["loading", "search-data", "search-type", "placeholder", "default-data", "onItemSelected", "onTrimSelected"])
  ]))
}