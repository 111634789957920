
// Libraries
import { defineComponent } from 'vue';

// Components
import SearchList from '@/components/Onramp/SearchList.vue';
import PopularServices from './PopularServices.vue';

// Types
import { Service } from '@/types/Services';
import { ServiceSelectionCategory } from '@/types/resources/ServiceSelectionCategories';
import { ServiceSelectionInterview } from '@/types/resources/ServiceSelectionInterview';
import { CartService, ServiceRequestParameters } from '@/types/ServiceRequest';
import serviceMixin from '@/mixins/serviceMixin';


export default defineComponent({
  name: 'ServiceSelector',

  mixins: [
    serviceMixin,
  ],

  components: {
    SearchList,
    PopularServices,
  },

  props: {
    serviceRequestFailed: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      loading: false,
      error: '',
      popularServices: {} as ServiceSelectionCategory,
      height: 300,
      searchActive: false,
    };
  },

  computed: {
    services(): CartService[] {
      return this.$store.getters['services/getServices'];
    },

    serviceToAdd(): CartService {
      return this.$store.getters['onramp/getServiceToAdd'];
    },

    serviceCatalog(): ServiceSelectionCategory {
      return this.$store.getters['services/getServiceCatalogInterviews'];
    },

    onrampZipcode(): string {
      return this.$store.getters['onramp/getZipcode'];
    },

    placeholder(): string {
      return 'Search services';
    },

    serviceRequestRequest(): ServiceRequestParameters {
      return this.$store.getters['onramp/getServiceRequestRequest'];
    },

    cartServices(): CartService[] {
      return this.serviceRequestRequest?.services || [];
    },
  },


  methods: {
    startInterview(serviceSelectionInterview: ServiceSelectionInterview): void {
      this.$store.dispatch('ui/pushView', {
        name: 'ServiceInterview',
        params: {
          interview: serviceSelectionInterview
        }
      });
    },

    selectService(service: Service): void {
      let cartService = this.createCartService(service.id, []);
      this.$store.commit('onramp/setServiceToAdd', cartService);
      this.$store.commit('onramp/addOrUpdateService', cartService);
      this.goToNotesStep();
    },

    goToNotesStep(): void {
      this.$store.dispatch('ui/pushView', {
        name: 'ServiceNotes'
      });
    },

    handleActiveSearch(/*state: boolean*/): void {
      // TODO: do we wanna hide stuff while searching?
      // this.searchActive = state;
    },
  }
});
