import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e906338e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "location-hours" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", null, [
      _createTextVNode(" Mon: "),
      (_ctx.locationHours.monday && _ctx.locationHours.monday.open && _ctx.locationHours.monday.close)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.moment(_ctx.locationHours.monday.open, 'HH:mm').format('h:mm a')) + " - " + _toDisplayString(_ctx.moment(_ctx.locationHours.monday.close, 'HH:mm').format('h:mm a')), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, "Closed"))
    ]),
    _createElementVNode("li", null, [
      _createTextVNode(" Tue: "),
      (_ctx.locationHours.tuesday && _ctx.locationHours.tuesday.open && _ctx.locationHours.tuesday.close)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.moment(_ctx.locationHours.tuesday.open, 'HH:mm').format('h:mm a')) + " - " + _toDisplayString(_ctx.moment(_ctx.locationHours.tuesday.close, 'HH:mm').format('h:mm a')), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_5, "Closed"))
    ]),
    _createElementVNode("li", null, [
      _createTextVNode(" Wed: "),
      (_ctx.locationHours.wednesday && _ctx.locationHours.wednesday.open && _ctx.locationHours.wednesday.close)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.moment(_ctx.locationHours.wednesday.open, 'HH:mm').format('h:mm a')) + " - " + _toDisplayString(_ctx.moment(_ctx.locationHours.wednesday.close, 'HH:mm').format('h:mm a')), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_7, "Closed"))
    ]),
    _createElementVNode("li", null, [
      _createTextVNode(" Thu: "),
      (_ctx.locationHours.thursday && _ctx.locationHours.thursday.open && _ctx.locationHours.thursday.close)
        ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.moment(_ctx.locationHours.thursday.open, 'HH:mm').format('h:mm a')) + " - " + _toDisplayString(_ctx.moment(_ctx.locationHours.thursday.close, 'HH:mm').format('h:mm a')), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_9, "Closed"))
    ]),
    _createElementVNode("li", null, [
      _createTextVNode(" Fri: "),
      (_ctx.locationHours.friday && _ctx.locationHours.friday.open && _ctx.locationHours.friday.close)
        ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.moment(_ctx.locationHours.friday.open, 'HH:mm').format('h:mm a')) + " - " + _toDisplayString(_ctx.moment(_ctx.locationHours.friday.close, 'HH:mm').format('h:mm a')), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_11, "Closed"))
    ]),
    _createElementVNode("li", null, [
      _createTextVNode(" Sat: "),
      (_ctx.locationHours.saturday && _ctx.locationHours.saturday.open && _ctx.locationHours.saturday.close)
        ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.moment(_ctx.locationHours.saturday.open, 'HH:mm').format('h:mm a')) + " - " + _toDisplayString(_ctx.moment(_ctx.locationHours.saturday.close, 'HH:mm').format('h:mm a')), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_13, "Closed"))
    ]),
    _createElementVNode("li", null, [
      _createTextVNode(" Sun: "),
      (_ctx.locationHours.sunday && _ctx.locationHours.sunday.open && _ctx.locationHours.sunday.close)
        ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.moment(_ctx.locationHours.sunday.open, 'HH:mm').format('h:mm a')) + " - " + _toDisplayString(_ctx.moment(_ctx.locationHours.sunday.close, 'HH:mm').format('h:mm a')), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_15, "Closed"))
    ])
  ]))
}