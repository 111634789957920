import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "appointment-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentView.name), {
      viewParams: _ctx.currentView.params
    }, null, 8, ["viewParams"]))
  ]))
}