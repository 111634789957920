import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "sidebar-card card offer-pane" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "btn btn-block text-left" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 1,
  class: "location-mobile"
}
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("button", _hoisted_3, [
        _createVNode(_component_icon, {
          icon: ['fal', 'comment-dollar'],
          size: "lg"
        }),
        _createTextVNode(" Estimates "),
        _createVNode(_component_icon, {
          class: "chevron",
          icon: ['fal', 'chevron-up']
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (!_ctx.offer)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(" Select an estimate to view more details. ")
          ], 64))
        : (_openBlock(), _createElementBlock("ul", _hoisted_5, [
            _createElementVNode("li", null, [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.priceClasses)
              }, _toDisplayString(_ctx.totalPriceInDollars), 3),
              _createElementVNode("a", {
                href: "#",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDetailModal()))
              }, "View estimate details")
            ]),
            _createElementVNode("li", null, _toDisplayString(_ctx.offer.location.name), 1),
            (!_ctx.offer.location.doesHouseCalls)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("li", null, _toDisplayString(_ctx.offer.location.address), 1),
                  _createElementVNode("li", null, _toDisplayString(_ctx.offer.location.phoneNumber), 1)
                ], 64))
              : (_openBlock(), _createElementBlock("li", _hoisted_6, [
                  _createElementVNode("img", {
                    src: _ctx.asset(require('@/assets/images/shipping-fast.svg'))
                  }, null, 8, _hoisted_7),
                  _createTextVNode(" Mobile mechanic. We come to you! ")
                ]))
          ]))
    ])
  ]))
}