import { CartService, Interview } from '@/types/ServiceRequest';
import { defineComponent } from 'vue';

export default defineComponent({
  methods: {
    createCartService(serviceId: number, answers: Interview[]): CartService {
      const service = this.$store.getters['services/getService'](serviceId);
      return {
        autoquotable: service.autoquotable,
        serviceId: service.id,
        name: service.name,
        notes: '',
        interview: answers,
        lyft: service.lyft
      };
    }
  }
});
