
import { defineComponent } from 'vue';
import LocationSearch from '@/components/Shared/LocationSearch.vue';

import zipcodeService from '@/services/zipcodeService';
import { ZipcodeLocation } from '@/types/ZipcodeLocation';
import areaService from '@/services/areaService';
import { Area } from '@/types/resources/ZipcodeLocations';

export default defineComponent({
  name: 'LocationSelector',

  components: {
    LocationSearch
  },

  data() {
    return {
      zipcodeToSearch: '',
      searchResults: [] as Area[]
    };
  },

  computed: {
    validUserZIP(): boolean {
      return this.$store.getters['user/getLocation'].zipcode !== '';
    },
  },

  mounted() {},

  methods: {
    zeroCoverage(): void {
      this.$store.dispatch('ui/pushView', 'ZeroCoverage');
    },

    checkCoverage(location: ZipcodeLocation): Promise<void> {
      return zipcodeService
        .isInTargetMarket(location.zipcode)
        .then((inMarket) => {
          if (inMarket) {
            this.$store.commit('onramp/setZipcode', location.zipcode);
            this.$store.dispatch('ui/pushView', {
              name: 'ServiceSelector',
              params: { zipcode: location.zipcode }
            });
          } else {
            this.zeroCoverage();
          }
        })
        .catch(() => {
          this.zeroCoverage();
        });
    },
    searchZipcode(searchTerm: string): Promise<void> {
      return areaService
        .searchAreas(searchTerm)
        .then((locations) => {
          this.searchResults = locations;
        })
        .catch(() => {
          this.$store.dispatch('ui/pushView', 'ZeroCoverage');
        });
    }
  }
});
