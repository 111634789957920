import { WidgetStrings } from '@/types/PartnerConfig';

export const DEFAULT_CONFIG_STRINGS: Partial<WidgetStrings> = {
  findRepairFacility: {
    heading: 'Find a Repair Facility',
    subheading: 'Find an in-network automotive repair facility near you.',
    button: 'Go'
  },
  chooseLocation: {
    heading: 'Servicing your vehicle, <br /> made easy.',
    subheading:
      'Compare service professionals near you. Get multiple price estimates upfront. Schedule your service.<br /> Save upwards of 25% with Openbay+',
    button: 'Go'
  }
};
