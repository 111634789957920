import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("section", {
    class: "rating",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('ratings-clicked')))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ratingStars, (star, index) => {
      return (_openBlock(), _createBlock(_component_icon, {
        key: `star-${index}`,
        icon: ['fas', 'star']
      }))
    }), 128)),
    _createTextVNode(" (" + _toDisplayString(_ctx.reviewSummary.totalReviews) + ") ", 1)
  ]))
}