
import { defineComponent, PropType } from 'vue';
import { LocationFull } from '@/types/ServiceProvider';
import moment from 'moment';

export default defineComponent({
  name: 'LocationHours',

  props: {
    locationHours: {
      type: Object as PropType<LocationFull['hours']>,
      required: false,
      default: () => ({} as LocationFull['hours'])
    }
  },

  methods: {
    moment: moment
  }
});
