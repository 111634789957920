import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "onramp-view"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OnrampNavigation = _resolveComponent("OnrampNavigation")!
  const _component_OnrampCart = _resolveComponent("OnrampCart")!
  const _component_DialogPage = _resolveComponent("DialogPage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showOnrampNavigation && !_ctx.isAdminFlow)
      ? (_openBlock(), _createBlock(_component_OnrampNavigation, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.onrampInView)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentView.name), {
            viewParams: _ctx.currentView.params
          }, null, 8, ["viewParams"])),
          (_ctx.showOnrampCart)
            ? (_openBlock(), _createBlock(_component_OnrampCart, {
                key: 0,
                currentStep: _ctx.currentStep,
                inView: _ctx.showOnrampCart
              }, null, 8, ["currentStep", "inView"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.dialogInView)
      ? (_openBlock(), _createBlock(_component_DialogPage, {
          key: 2,
          dialog: _ctx.dialog
        }, null, 8, ["dialog"]))
      : _createCommentVNode("", true)
  ], 64))
}