
import { defineComponent, PropType } from 'vue';
import { monetize } from '@/utilities';

//Components
import LocationPartial from '@/components/Onramp/Location/Partial.vue';
import OfferPricing from '@/components/Onramp/Offer/Pricing.vue';

//Types
import { OfferFull as OfferFullType } from '@/types/Offer';
import { Service } from '@/types/Services';
import configMixin from '@/mixins/configMixin';

export default defineComponent({
  name: 'OfferPartial',

  components: {
    LocationPartial,
    OfferPricing,
  },

  mixins: [
    configMixin,
  ],

  props: {
    offer: {
      required: true,
      type: Object as PropType<OfferFullType>
    },

    services: {
      required: false,
      type: Array as PropType<Service[]>,
      default: () => []
    }
  },

  data() {
    return {
      showReviewTab: false,
      sendLoading: false
    };
  },

  computed: {
    tabIndex(): number {
      return this.showReviewTab ? 1 : 0;
    },

    serviceRequestUid(): string {
      return this.$store.getters['onramp/getServiceRequest'].openbayId;
    },

    discountsApplied(): number {
      return this.offer.totalDiscountCents || 0;
    },

    hasSubscription(): boolean {
      return this.$store.getters['user/hasActiveSubscription'];
    }
  },

  methods: {
    showQuickView(): void {
      this.$store.dispatch('ui/showDialog', {
        title: `Offer Details`,
        contentComponent: {
          name: 'OfferFull',
          props: {
            offerProp: this.offer,
          }
        },
        cancelText: 'Close',
        submitText: 'Book Appointment',
        hideSubmitButton: this.isAdminFlow,
        onOk: () => this.chooseTime(),
      });
    },

    hideQuickView(): void {
      // TODO
    },

    chooseTime(): void {
      this.$store.commit('onramp/setOffer', this.offer);
      this.$store.dispatch('ui/pushView', {
        name: 'AppointmentSelector',
        params: {
          id: this.serviceRequestUid,
          offerId: this.offer.openbayId
        }
      });
    },

    monetize: monetize
  }
});
