import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hero-banner" }
const _hoisted_2 = { class: "hero-banner__container" }
const _hoisted_3 = { class: "hero-banner__content" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "hero-banner__search" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LocationSearch = _resolveComponent("LocationSearch")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", {
          class: "hero-banner__title",
          innerHTML: _ctx.strings.heading
        }, null, 8, _hoisted_4),
        _createElementVNode("h2", {
          class: "hero-banner__subtitle",
          innerHTML: _ctx.strings.subheading
        }, null, 8, _hoisted_5),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_LocationSearch, {
            class: "location-search",
            "hide-sub-text": "",
            "has-submit-button": "",
            "submit-text": _ctx.strings.button,
            "zipcodes-highlight-entire-location": "",
            onSelectLocation: _ctx.onAreaSelected
          }, null, 8, ["submit-text", "onSelectLocation"])
        ])
      ])
    ])
  ]))
}