
import { defineComponent } from 'vue';

// Components
import { OwnedVehicle } from '@/types/resources/OwnedVehicles';
import { Subscription } from '@/types/microservices/Subscriptions';
import AddOwnedVehicle from '@/components/Onramp/Vehicle/AddOwnedVehicle.vue';
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';
import adminMixin from '@/mixins/adminMixin';
import configMixin from '@/mixins/configMixin';
import PartnerService from '@/services/microservices/partnerService';
import { ServiceRequestParameters } from '@/types/ServiceRequest';

export default defineComponent({
  name: 'VehicleSelector',

  components: {
    AddOwnedVehicle,
    LoadingSpinner
  },

  props: {
    serviceRequestFailed: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  mixins: [
    adminMixin,
    configMixin,
  ],

  data() {
    return {
      creatingVehicle: false,
      loading: true,
      error: '',
      scheduledMaintenanceMode: false
    };
  },

  computed: {
    autoquotable(): boolean {
      return this.$store.getters['onramp/isAutoquotable'];
    },

    zipcode(): string {
      return this.$store.getters['onramp/getZipcode'];
    },

    hasContactMethod(): boolean {
      return this.$store.getters['user/hasContactMethod'];
    },

    isLoggedIn(): boolean {
      return this.$store.getters['user/isLoggedIn'];
    },

    isVehicleFirstFlow(): boolean {
      return this.$store.getters['user/isLyftUser'];
    },

    ownedVehicles(): OwnedVehicle[] {
      if (this.isAdminFlow)
        return this.$store.getters['onramp/getVehicles'];
      return this.$store.getters['user/getVehicles'];
    },

    subscriptions(): Subscription[] {
      return this.$store.getters['user/getSubscriptions'];
    },

    vehicleToCreate(): OwnedVehicle {
      return this.$store.getters['onramp/getVehicleToCreate'];
    },

    vehicleIsCreated(): boolean {
      return this.vehicleToCreate?.edmundsStyleId !== undefined;
    },

    displayVehicleSelector(): boolean {
      return this.isAdminFlow && !this.isVehicleProvided;
    },


    serviceRequest(): ServiceRequestParameters {
      return this.$store.getters['onramp/getServiceRequestRequest'];
    },

    partnerId(): number {
      return this.$store.getters['onramp/getPartnerId'];
    },
  },

  watch: {
    serviceRequestFailed: {
      handler: function (to) {
        if (to === true) {
          this.fetchOwnedVehicles();
          this.creatingVehicle = false;
        }
      }
    }
  },

  mounted(): void {
    if (!this.isLoggedIn) {
      this.loading = false;
      this.creatingVehicle = true;
    } else {
      this.fetchOwnedVehicles();
    }
  },

  methods: {
    getLogoPath(ownedVehicle: OwnedVehicle): string {
      try {
        return require(`@/assets/images/logos-cars/${ownedVehicle.make.toLowerCase()}.svg`);
      } catch {
        return '';
      }
    },

    fetchOwnedVehicles(): void {
      this.$store
        .dispatch('user/fetchVehicles')
        .then(() => {
          this.loading = false;
          const vehicleToCreate: Partial<OwnedVehicle> | undefined =
            this.$store.getters['onramp/getVehicleToCreate'];
          const { edmundsStyleId, vin } = vehicleToCreate ?? {};

          if (this.ownedVehicles.length === 0) {
            this.creatingVehicle = true;
          } else if (vin || edmundsStyleId) {
            // If there's a vehicle we're trying to create already, and there's
            // an owned vehicle that matches the VIN and/or EdmundsStyle ID of
            // the vehicle we're trying to create, then let's just pick that one
            const matchingOwnedVehicle = this.ownedVehicles.find((ownedVehicle) => {
              return (
                (vin && vin === ownedVehicle.vin) || (edmundsStyleId && edmundsStyleId === ownedVehicle.edmundsStyleId)
              );
            });

            if (matchingOwnedVehicle) this.exitVehicleCreation(matchingOwnedVehicle);
          }
        })
        .catch(() => {
          this.creatingVehicle = true;
          this.loading = false;
        });
    },

    resetVehicleSelector(): void {
      this.creatingVehicle = false;
    },

    startVehicleCreation(): void {
      this.creatingVehicle = true;
      this.$store.commit('onramp/clearVehicleToCreate');
    },

    exitVehicleCreation(ownedVehicle: OwnedVehicle): void {
      this.loading = true;
      this.$store.commit('onramp/setOwnedVehicle', ownedVehicle);
      if (this.isAdminFlow && this.serviceRequest.user?.userId) {
        new PartnerService()
          .createOwnedVehicle(
            this.partnerId,
            this.serviceRequest.user.userId,
            ownedVehicle,
          ).then((vehicle) => {
            this.$store.commit('onramp/setOwnedVehicle', vehicle);
            this.createAdminServiceRequest();
          })
      } else {
        this.$store.commit('onramp/setOwnedVehicle', ownedVehicle);
        const route = this.isLoggedIn ? 'OfferSelector' : 'SignUp';
        this.$store.dispatch('ui/pushView', {
          name: route
        });
      }
    },

    vehicleCreationFailed(): void {
      this.$store.dispatch('alerts/addAlert', {
        message: 'Hmm, something went wrong. Sorry about that.',
        type: 'danger',
        timedDismiss: true
      });
    },
  }
});
