
import { defineComponent } from 'vue';

import HeaderComponent from '@/components/HeaderComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import GlobalAlert from '@/components/GlobalAlert.vue';
import OnrampView from '@/views/OnrampView.vue';
import AppointmentView from '@/views/AppointmentView.vue';
import configMixin from './mixins/configMixin';
import { WidgetType } from './types/PartnerConfig';
import { WIDGET_ID } from './constants';
import zipcodeService from './services/zipcodeService';

export default defineComponent({
  name: 'App',

  components: {
    HeaderComponent,
    FooterComponent,
    OnrampView,
    AppointmentView,
    GlobalAlert,
  },

  mixins: [configMixin],

  computed: {
    hasAlerts(): boolean {
      return this.$store.getters['alerts/getAlerts'].length > 0;
    },

    showFooter(): boolean {
      return !this.isAdminFlow && !this.isAppointmentFlow;
    },

    isAppointmentFlow(): boolean {
      return this.widgetType === WidgetType.AppointmentOnly;
    }
  },

  methods: {
    initAdminFlow(): void {
      ['onramp', 'user', 'auth', 'ui'].forEach((module) => {
        this.$store.commit(`${module}/reset`);
      });
      this.$store.dispatch('ui/pushView', {
        name: 'ServiceSelector',
      });
      this.checkCoverage(this.externalConfig.serviceRequest.zipCode).then(async (inMarket: boolean) => {
        if (inMarket) {
          await this.$store.dispatch('auth/refreshToken');
          await this.$store.dispatch('onramp/initializeFromConfig', this.externalConfig);
        } else {
          this.$store.dispatch('ui/pushView', 'ZeroCoverage');
        }
      }).catch(() => {
        this.$store.dispatch('ui/pushView', 'ZeroCoverage');
      });
    },

    initAppointmentFlow(): void {
      this.$store.dispatch('ui/pushView', {
        name: 'FindRepairFacility',
      });
    },

    initOnrampFlow(): void {
      // TODO: implement
    },

    checkCoverage(zipcode: string): Promise<boolean> {
      return zipcodeService
        .isInTargetMarket(zipcode)
        .catch(() => {
          return false;
        });
    },
  },

  mounted(): void {
    document.getElementById(WIDGET_ID)!.dispatchEvent(
      new CustomEvent('openbay:ready')
    );

    switch (this.widgetType) {
      case WidgetType.Admin:
        this.initAdminFlow();
        break;
      case WidgetType.AppointmentOnly:
        this.initAppointmentFlow();
        break;
      case WidgetType.All:
      case WidgetType.Onramp:
      default:
        this.initOnrampFlow();
        break;
    }
  },
});
