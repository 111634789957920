
import { defineComponent } from 'vue';
import SignUpForm from '@/components/Shared/SignUpForm.vue';
import { OwnedVehicle } from '@/types/resources/OwnedVehicles';
import userService from '@/services/userService';
import { ServiceRequest, ServiceRequestParameters } from '@/types/ServiceRequest';
import { messageFromError } from '@/services/helpers';
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';

export default defineComponent({
  name: 'SignUp',
  components: {
    SignUpForm,
    LoadingSpinner,
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ownedVehicles(): OwnedVehicle[] {
      return this.$store.getters['user/getVehicles'] || [];
    },

    serviceRequestRequest(): ServiceRequestParameters {
      return this.$store.getters['onramp/getServiceRequestRequest'];
    },
  },

  methods: {
    authSuccess(): void {
      const vehicleToCreate = this.$store.getters['onramp/getVehicleToCreate'];
      this.loading = true;
      userService
        .addOwnedVehicle({ vehicleId: vehicleToCreate.edmundsStyleId, ...vehicleToCreate })
        .then((vehicle) => this.setVehicleAndRedirect(vehicle))
        .catch(() => (this.loading = false));
    },

    authFail(message: string): void {
      this.$store.commit('onramp/clearVehicleToCreate');
      return this.$store.dispatch('alerts/addAlert', {
        message,
        type: 'danger',
        timedDismiss: true
      });
    },

    setVehicleAndRedirect(vehicle: Partial<OwnedVehicle>) {
      this.$store.commit('user/addOwnedVehicle', vehicle);
      this.$store.commit('onramp/setOwnedVehicle', vehicle);
      this.$store.commit('onramp/clearVehicleToCreate');

      this.$store
        .dispatch('onramp/createServiceRequest', this.serviceRequestRequest)
        .then((serviceRequest: ServiceRequest) => {
          const serviceRequestUid = serviceRequest.openbayId;
          this.$store.dispatch('ui/pushView', {
            name: 'OfferSelector',
            params: { serviceRequestUid }
          });
        })
        .catch((error: any) => {
          const message = messageFromError(error);
          this.$store.dispatch('alerts/addAlert', {
            message,
            type: 'danger',
            timedDismiss: true
          });
        })
        .finally(() => (this.loading = false));
    },
  },
});
