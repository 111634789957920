import { httpClientMicro } from '@/api-microservices';
import { ApiListResponse, Pagination, Sort } from '@/types/microservices/api';
import { CreateSubscriptionRequest } from '@/types/microservices/CreateSubscriptionRequest';
import { DetailedSubscription } from '@/types/microservices/DetailedSubscription';
import { PaymentMethod } from '@/types/microservices/PaymentMethod';
import { ProcessorPayment } from '@/types/microservices/ProcessorPayment';
import { ProgramPlanFaq } from '@/types/microservices/ProgramPlanFaq';
import { SubscriptionOption } from '@/types/microservices/SubscriptionOption';
import { Subscription } from '@/types/microservices/Subscriptions';
import { UserSubscriptionResponse } from '@/types/microservices/UserSubscriptionResponse';
import { PartialVehicle } from '@/types/resources/PartialVehicle';

import { handleError } from '@/services/helpers';
import { formatSortParameter } from '@/utilities';
import store from '@/store/store';

export default class SubscriptionService {
  routes = {
    mySubscriptions: () => `${this.environmentPath()}/v1/my-subscriptions`,
    partialVehicles: () => `${this.environmentPath()}/v1/partial-vehicles`,
    partialVehicleAssociate: (partialVehicleId: number) =>
      `${this.environmentPath()}/v1/partial-vehicles/${partialVehicleId}`,
    mySubscriptionOptions: (programPlanId?: number) =>
      `${this.routes.mySubscriptions()}/subscription-options/${programPlanId ?? ''}`,
    subscribeWithOption: () => `${this.routes.mySubscriptions()}/subscribe`,
    cancelSubscription: (subscriptionId: number) => `${this.routes.mySubscriptions()}/${subscriptionId}/cancel`,
    subscriptionDetails: (programPlanId: number) =>
      `${this.routes.mySubscriptions()}/detailed?programPlanId=${programPlanId}`,
    paymentHistory: (processorSubscriptionId: string) =>
      `${this.routes.mySubscriptions()}/${processorSubscriptionId}/payments`,
    paymentIntent: () => `${this.routes.mySubscriptions()}/stripe/payment-intent`,
    paymentMethods: () => `${this.routes.mySubscriptions()}/payment-methods`,
    setupIntent: () => `${this.routes.mySubscriptions()}/stripe/setup-intent`,
    updatePaymentMethod: (processorSubscriptionId: string) =>
      `${this.routes.mySubscriptions()}/${processorSubscriptionId}`,
    subscriptionFaqs: () => `${this.environmentPath()}/v1/my-subscriptions/faq`
  };
  environmentPath() {
    return `${store.getters['config/getBaseUrl']}/services/subscriptions`;
  }

  public subscribeVOToPlan(
    programNameSlug: string,
    planNameSlug: string,
    zipCode: string
  ): Promise<{ message: string }> {
    return httpClientMicro.post(this.routes.mySubscriptions(), {
      programName: programNameSlug,
      programPlanName: planNameSlug,
      zipCode
    });
  }

  public getVOSubscriptions(): Promise<Subscription[]> {
    return httpClientMicro.get(this.routes.mySubscriptions()).then(({ data }: any) => data.data);
  }

  public getPartialVehicles(): Promise<PartialVehicle[]> {
    return httpClientMicro.get(this.routes.partialVehicles()).then(({ data }: any) => data);
  }

  public getSubscriptionOptions(programPlanId?: number): Promise<SubscriptionOption[]> {
    return httpClientMicro.get(this.routes.mySubscriptionOptions(programPlanId)).then(({ data }: any) => data);
  }

  public cancelSubscription(subscriptionId: number): Promise<{}> {
    return httpClientMicro.delete(this.routes.cancelSubscription(subscriptionId));
  }

  public getSubscriptionDetails(programPlanId: number): Promise<DetailedSubscription[]> {
    return httpClientMicro.get(this.routes.subscriptionDetails(programPlanId)).then(({ data }: any) => data?.data);
  }

  public getPaymentIntent(subscriptionOptionId: number): Promise<{ clientSecret: string }> {
    return httpClientMicro
      .get(this.routes.paymentIntent(), {
        params: {
          subscriptionOptionId: subscriptionOptionId
        }
      })
      .then(({ data }) => data);
  }

  public getPaymentHistory(
    processorSubscriptionId: string,
    sort: Sort<keyof ProcessorPayment>,
    paginationOptions: Pagination = { skip: 0, take: 10, total: 0 }
  ): Promise<ApiListResponse<ProcessorPayment>> {
    return httpClientMicro
      .get(this.routes.paymentHistory(processorSubscriptionId), {
        params: { ...paginationOptions, ...formatSortParameter(sort) }
      })
      .then(({ data }) => data);
  }

  public getPaymentMethods(): Promise<PaymentMethod[]> {
    return httpClientMicro.get(this.routes.paymentMethods()).then(({ data }) => data);
  }

  public setupIntent(customerId?: string, paymentMethod?: string): Promise<{ secret: string; customerId: string }> {
    return httpClientMicro.post(this.routes.setupIntent(), { customerId, paymentMethod }).then(({ data }) => data.data);
  }

  public linkPartialVehicletoVehicle(partialVehicleId: number, vehicleId: number): Promise<{ message: string }> {
    return httpClientMicro.put(this.routes.partialVehicleAssociate(partialVehicleId), {
      railsVehicleId: vehicleId
    });
  }

  public updatePaymentMethod(processorSubscriptionId: string, paymentMethodId: string): Promise<{ message: string }> {
    return httpClientMicro.put(this.routes.updatePaymentMethod(processorSubscriptionId), {
      paymentMethodId
    });
  }

  public subscribeVOToPlanWithOption(request: CreateSubscriptionRequest): Promise<UserSubscriptionResponse> {
    return httpClientMicro
      .post(this.routes.subscribeWithOption(), request)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw handleError(error);
      });
  }

  public getSubscriptionFaqs(
    sort: Sort<keyof ProgramPlanFaq>,
    paginationOptions: Pagination = { skip: 0, take: 100, total: 0 }
  ): Promise<ProgramPlanFaq[]> {
    return httpClientMicro
      .get(this.routes.subscriptionFaqs(), {
        params: { ...paginationOptions, ...formatSortParameter(sort) }
      })
      .then(({ data }) => data)
      .catch((error) => {
        throw error;
      });
  }
}
