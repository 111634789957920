
import { defineComponent, PropType } from 'vue';
import { CartService } from '@/types/ServiceRequest';

export default defineComponent({
  name: 'CartService',

  props: {
    cartService: {
      type: Object as PropType<CartService>,
      required: true
    },

    isIntervalService: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    serviceName(): string {
      return this.cartService.name;
    }
  }
});
